import React, { memo, useState } from 'react';
import { Loading } from 'modules/common/vendors/Loading';
import { IUser } from 'models/User';
import { NavigationBar } from 'modules/common/components/NavigationBar';
import { Body } from 'modules/common/vendors/Wrapper';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { ResetPasswordModal } from './parts/ResetPasswordModal';
import { UserInformation } from './parts/UserInformation';

interface UserDetailsProps {
  loading: boolean;
  user?: IUser;
  onUpdate: (user: IUser) => void;
  onChangePassword: (password: string) => void;
}

export const UserDetailsComponent = (props: UserDetailsProps) => {
  const [showModalChangePass, setShowModalChangePass] = useState(false);

  return (
    <BaseComponent>
      <>
        <NavigationBar header={'usersSearch.header'} path={'/cms/users'} background={'#EDEDED'} />
        <Body>
          <Loading loading={props.loading} />
          <UserInformation
            user={props.user}
            onChangePassword={() => {
              setShowModalChangePass(true);
            }}
            onSave={props.onUpdate}
          />
          <ResetPasswordModal
            loading={props.loading}
            show={showModalChangePass}
            onClose={() => {
              setShowModalChangePass(false);
            }}
            onChange={(password) => {
              setShowModalChangePass(false);
              props.onChangePassword(password);
            }}
          />
        </Body>
      </>
    </BaseComponent>
  );
};

export default memo(UserDetailsComponent);
