import React from 'react';
import Table from 'modules/common/components/Table';
import { Confirm } from 'modules/common/components/Modals/ConfirmModal';
import { IUser } from 'models/User';
import { useTranslationText } from 'locale';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AppColors } from 'helpers';
import { Div, HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { Button } from 'modules/common/vendors/Button';
import { Text } from 'modules/common/vendors/Text';
import { Image } from 'modules/common/vendors/Image';
import { SVGIcon } from 'modules/common/vendors/Icon';
import { useSession } from 'stores/user.store';

export function columns(
  auth: IUser,
  translation: (path: string) => string,
  onDelete: (id: string) => void,
  onDetails: (id: string) => void,
) {
  const tableColumns: Array<ColumnDescription<IUser>> = [
    {
      dataField: 'avatar',
      text: translation('usersSearch.avatar'),
      headerStyle: () => {
        return { width: '10%', textAlign: 'center', whiteSpace: 'unset', padding: '10px' };
      },
      formatter: (_, row: IUser) => {
        return (
          <HorizontalView fullWidth alignContent={'center'} padding={'10px'}>
            <Image
              width={'60px'}
              height={'60px'}
              borderRadius={'30px'}
              objectFit={'cover'}
              src={row.avatar || '/images/avatar.png'}
            />
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: () => {
        return { width: '25%', textAlign: 'left', whiteSpace: 'unset', padding: '10px' };
      },
      formatter: (_, row: IUser) => {
        return (
          <a
            href={`/cms/users/${row.id}`}
            color={AppColors.PRIMARY}
            onClick={(event) => {
              event.preventDefault();
              onDetails(row.id);
            }}
          >
            <HorizontalView fullWidth alignContent={'centerBetween'} padding={'10px'} flexWrap={'nowrap'}>
              {row.id}
              <Div width={'30px'}>
                <SVGIcon size={'20px'} name={'arrow-right'} color={AppColors.PRIMARY} />
              </Div>
            </HorizontalView>
          </a>
        );
      },
    },

    {
      dataField: 'name',
      text: translation('usersSearch.name'),
      headerStyle: () => {
        return { fontSize: '13px', width: '40%', textAlign: 'left', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IUser) => {
        return (
          <HorizontalView fullWidth alignContent={'centerLeft'} padding={'5px'}>
            <Text color={AppColors.BLACK}> {row.name} </Text>
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'email',
      text: translation('usersSearch.email'),
      headerStyle: () => {
        return { fontSize: '13px', width: '30%', textAlign: 'left', padding: '10px' };
      },
    },
    {
      dataField: 'active',
      text: translation('usersSearch.status'),
      headerStyle: () => {
        return { fontSize: '13px', width: '20%', textAlign: 'left', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IUser) => {
        if (row.isLocked) {
          return (
            <Text fontWeight={500} color={AppColors.RED}>
              {translation('accountStatus.locked')}
            </Text>
          );
        } else if (!row.isVerified) {
          return (
            <Text fontWeight={500} color={AppColors.ORANGE}>
              {translation('accountStatus.inactive')}
            </Text>
          );
        } else {
          return (
            <Text fontWeight={500} color={AppColors.GRASSY_GREEN}>
              {translation('accountStatus.active')}
            </Text>
          );
        }
      },
    },
    {
      dataField: 'menu',
      csvExport: false,
      text: '',
      headerStyle: () => {
        return { fontSize: '13px', width: '100px', textAlign: 'right', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IUser) => {
        return (
          <HorizontalView width={'100px'} alignContent={'center'} padding={'5px'}>
            <Confirm
              disabled={row.id === auth.id}
              title={'userList.alertDelete.message'}
              leftText={'userList.alertDelete.close'}
              rightText={'userList.alertDelete.delete'}
              onConfirm={() => {
                onDelete(row.id);
              }}
            >
              <Button
                width={'35px'}
                height={'35px'}
                preixIcon={'trash'}
                borderRadius={'4px'}
                color={AppColors.WHITE}
                background={AppColors.RED}
              />
            </Confirm>
          </HorizontalView>
        );
      },
    },
  ];

  return tableColumns;
}

interface TableProps {
  loading: boolean;
  users: IUser[];
  onCreate?: () => void;
  onDelete: (id: string) => void;
  onDetails: (id: string) => void;
}

export const WebTableView = (props: TableProps) => {
  const { translation } = useTranslationText();
  const { session } = useSession();
  return (
    <VerticalView fullWidth minWidth={'1200px'}>
      <Table
        loading={props.loading}
        keyField={'id'}
        data={props.users ? props.users : []}
        bordered={false}
        striped={true}
        hover={true}
        condensed={true}
        columns={columns(session.user, translation, props.onDelete, props.onDetails)}
      />
    </VerticalView>
  );
};
