import React from 'react';
import { Routes, Route } from 'react-router';
import CMSMediaListContainer from '../containers';
import { NotFoundContainer } from '../../../user-interface/not-found/containers/index';

export const MediaRouter = () => {
  return (
    <Routes>
      <Route index element={<CMSMediaListContainer />} />
      <Route path="*" element={<NotFoundContainer />} />
    </Routes>
  );
};
