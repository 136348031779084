import React from 'react';
import { Routes, Route } from 'react-router';
import { CmsArticleDetailsPage } from 'modules/cms-interface/article/containers/ArticleDetailsPage';
import { CmsArticleSearchPage } from 'modules/cms-interface/article/containers/ArticleSearchPage';

export const ArticleRouter = () => {
  return (
    <Routes>
      <Route index element={<CmsArticleSearchPage />} />
      <Route path="/:articleId" element={<CmsArticleDetailsPage />} />
    </Routes>
  );
};
