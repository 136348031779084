import React from 'react';
import { Div } from 'modules/common/vendors/Wrapper';
import { AppColors } from 'helpers';
import { Text } from 'modules/common/vendors/Text';
import { SVGIcon } from 'modules/common/vendors/Icon';

const css = `
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s; /* Safari */
    :hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
`;

export const Tag = (props: { value: string; label: string; isSelect?: boolean; onClick?: () => void }) => {
  let color = AppColors.WHITE;
  let bg = AppColors.GRASSY_GREEN;

  switch (props.value.toUpperCase()) {
    case 'NEW':
      color = AppColors.WHITE;
      bg = AppColors.PRIMARY;
      break;
    case 'INACTIVE':
    case 'NOTIFICATION':
      color = AppColors.WHITE;
      bg = AppColors.ORANGE;
      break;
    case 'PUBLIC':
    case 'ACTIVE':
      color = AppColors.WHITE;
      bg = AppColors.GRASSY_GREEN;
      break;
    case 'REJECT':
    case 'LOCKED':
      color = AppColors.WHITE;
      bg = AppColors.RED2;
      break;
    case 'NEWS':
      bg = AppColors.PRIMARY;
      break;
    case 'USER':
      bg = AppColors.sidebar.member;
      break;
    case 'ADMIN':
      bg = AppColors.sidebar.admin;
      break;
    default:
      color = AppColors.WHITE;
      bg = AppColors.DARK;
      break;
  }
  return (
    <Div
      paddingLeft={'15px'}
      paddingRight={'15px'}
      background={bg}
      borderRadius={'15px'}
      height={'30px'}
      marginRight={'10px'}
      onClick={props.onClick}
      flexWrap={'nowrap'}
      css={props.onClick ? css : undefined}
    >
      {props.isSelect && (
        <Div marginRight={'2px'}>
          <SVGIcon name={'check-circle'} size={'10px'} color={color} />
        </Div>
      )}
      <Text color={color} fontWeight={400} fontSize={'13px'}>
        {props.label}
      </Text>
    </Div>
  );
};
