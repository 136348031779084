import React from 'react';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { AppColors } from 'helpers';
import { useTranslationText } from 'locale';
import { AnimationView } from 'modules/common/vendors/Animation';
import { ArticleEditorPreview } from 'modules/common/vendors/ArticleEditor';
import { QHCWrapper } from 'modules/common/vendors/CommonParts';
import { VerticalView, HorizontalView } from 'modules/common/vendors/CommonWrappers';
import { ContentLoading } from 'modules/common/vendors/Loading';
import { BannerPreview } from 'modules/common/components/Banner';

type PrivacyPros = {
  isLoading: boolean;
  content: string;
};

export const PrivacyComponent = (props: PrivacyPros) => {
  const { translation } = useTranslationText();

  return (
    <BaseComponent contentLoading={props.isLoading}>
      <>
        <BannerPreview banner={'/images/term_bg.jpg'} title={translation('public.privacy.title')} description={''} />
        <QHCWrapper>
          <AnimationView animation={'fadeIn'}>
            <VerticalView width="100%" alignContent="topCenter" marginTop={'20px'}>
              {!props.isLoading && props.content && <ArticleEditorPreview setContents={props.content} />}

              {props.isLoading && (
                <HorizontalView fullWidthHeight position={'relative'}>
                  <ContentLoading color={AppColors.GRAY_LIGHT} loading />
                </HorizontalView>
              )}
            </VerticalView>
          </AnimationView>
        </QHCWrapper>
      </>
    </BaseComponent>
  );
};
