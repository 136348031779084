export enum IRole {
  User = 'USER',
  Admin = 'ADMIN',
}

export enum ISocialProvider {
  Mail = 'MAIL',
}

export enum ISortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}
