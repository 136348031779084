import { Regex } from 'helpers/const';
import * as yup from 'yup';

export const createUserValidatorSchema = yup.object().shape({
  email: yup.string().matches(new RegExp(Regex.EMAIL), 'validator.emailInvalid').required('validator.requiredEmail'),
  password: yup
    .string()
    .min(8, 'validator.passwordMin')
    .max(20, 'validator.passwordMax')
    .matches(new RegExp(Regex.PASSWORD), 'validator.passwordInvalid')
    .required('validator.requiredPassword'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'validator.passwordNotMatch')
    .required('validator.requirePasswordConfirm'),
});

export const resetPasswordValidatorSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'validator.passwordMin')
    .max(20, 'validator.passwordMax')
    .matches(new RegExp(Regex.PASSWORD), 'validator.passwordInvalid')
    .required('validator.requiredPassword'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'validator.passwordNotMatch')
    .required('validator.requirePasswordConfirm'),
});

export const updateUserValidatorSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, 'validator.nameMinMax')
    .max(16, 'validator.nameMinMax')
    .required('validator.requiredName')
    .nullable(),
});
