import React from 'react';
import { Formik } from 'formik';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { Div, HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { useTranslationText } from 'locale';
import { TextAreaInput } from 'modules/common/vendors/TextAreaInput';
import { CardWrapper } from 'modules/common/vendors/CommonWrappers';
import { useAppSetting } from 'helpers/useAppSetting';
import { Button } from 'modules/common/vendors/Button';
import { AppColors } from 'helpers';
import { Text } from 'modules/common/vendors/Text';
import { SchedulePicker } from 'modules/common/components/SchedulePicker';
import { ContactSuccessMessage } from './part/ContactSuccessMessage';
import { Checkbox } from 'modules/common/vendors/CheckBox';
import { TextLink } from 'modules/common/vendors/TextLink';
import { RadioSelect } from 'modules/common/vendors/Radio';
import { AnimationView } from 'modules/common/vendors/Animation';
import { ScheduleEvent } from 'modules/common/components/SchedulePicker';
import { Input } from 'modules/common/vendors/Input';
import { ISendContactInput } from 'models/Contact';
import { contactValidatorSchema } from '../validator/idnex';
import { SelectDropdown, SelectItem } from 'modules/common/vendors/Select';

type ContactComponentProps = {
  success: boolean;
  isServicePage: boolean;
  loading?: boolean;
  sendLoading?: boolean;
  scheduleList: ScheduleEvent[];
  onSend: (value: ISendContactInput) => void;
};

export const ContactComponent = (props: ContactComponentProps) => {
  const { getTypes, translation } = useTranslationText();
  const { appSize, appLayout } = useAppSetting();
  const contactsTypes = getTypes<SelectItem[]>('contactList');
  return (
    <BaseComponent scrollToTop contentLoading={props.loading}>
      <HorizontalView fullWidthHeight marginTop={'20px'} alignContent={'center'}>
        <HorizontalView fullWidth maxWidth={'900px'} marginBottom={'20px'}>
          <VerticalView fullWidth display={props.success ? 'flex' : 'none'} overflow={'hidden'} padding={'10px'}>
            <CardWrapper width={appLayout.isMobile ? '300px' : '500px'} background={'white'}>
              <ContactSuccessMessage />
            </CardWrapper>
          </VerticalView>

          <VerticalView fullWidth display={!props.success ? 'flex' : 'none'} overflow={'hidden'}>
            <Formik
              initialValues={{
                name: '',
                email: '',
                phone: '',
                subject: '',
                content: '',
                isDirect: !props.isServicePage,
                datetime: '',
                isAgree: false,
              }}
              validationSchema={contactValidatorSchema}
              onSubmit={(values) => {
                if (values.isDirect) {
                  props.onSend({
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    subject: values.subject,
                    content: values.content,
                    datetime: values.datetime,
                  });
                } else {
                  props.onSend({
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    subject: values.subject,
                    content: values.content,
                  });
                }
              }}
              render={(formikProps) => {
                const { submitForm, setFieldValue, submitCount, values, errors, handleChange } = formikProps;

                return (
                  <VerticalView
                    fullWidth
                    alignContent={'center'}
                    padding={appLayout.isMobile ? '20px' : '20px'}
                    paddingTop={'30px'}
                    marginBottom={'40px'}
                    background={AppColors.BODY_BACKGROUND}
                  >
                    <Div fullWidth padding={'10px'} marginBottom={'30px'}>
                      {props.isServicePage && (
                        <VerticalView fullWidth alignContent="topCenter">
                          <Text fontSize={appSize.h3} color={AppColors.RED} textAlign={'center'}>
                            {translation('contactMessage5')}
                          </Text>
                          <Text fontSize={appSize.h4} color={AppColors.BLACK} textAlign={'center'} marginTop={'5px'}>
                            {translation(!values.isDirect ? 'contactMessage4' : 'contactMessage3')}
                          </Text>
                        </VerticalView>
                      )}
                      {!props.isServicePage && (
                        <VerticalView fullWidth alignContent={'center'}>
                          <h1>{translation('contactHeader')}</h1>
                          {values.isDirect && (
                            <Text fontSize={appSize.h4} color={AppColors.RED} textAlign={'center'}>
                              {translation('contactMessage1')}
                            </Text>
                          )}

                          <Text fontSize={appSize.h4} color={AppColors.RED} textAlign={'center'}>
                            {translation('contactMessage2')}
                          </Text>
                          <Text fontSize={appSize.h4} color={AppColors.BLACK} textAlign={'center'} marginTop={'5px'}>
                            {translation(!values.isDirect ? 'contactMessage4' : 'contactMessage3')}
                          </Text>
                        </VerticalView>
                      )}
                      {/* </CardWrapper> */}
                    </Div>

                    {values.isDirect && (
                      <Div fullWidth marginBottom={'10px'} alignContent={'centerLeft'}>
                        <AnimationView width={'100%'}>
                          <SchedulePicker
                            events={props.scheduleList}
                            onPicker={(datetime) => setFieldValue('datetime', datetime)}
                          />
                        </AnimationView>
                        {submitCount > 0 && errors.datetime && (
                          <Text marginTop={'9px'} marginLeft={'5px'} fontSize={appSize.xs} color={AppColors.RED}>
                            {translation(errors.datetime)}
                          </Text>
                        )}
                      </Div>
                    )}

                    <Div fullWidth marginTop={'20px'} alignContent={'centerLeft'}>
                      <RadioSelect
                        fontSize={appSize.h3}
                        defaultValue={{ value: values.isDirect }}
                        label={'contactDirect'}
                        options={getTypes('yesNoTypes')}
                        onChange={(option) => setFieldValue('isDirect', option?.value)}
                      />
                    </Div>
                    <Div fullWidth marginTop={'20px'}>
                      <SelectDropdown
                        required
                        width={'100%'}
                        height="40px"
                        size={appSize.h3}
                        direction={'column'}
                        label={'contactSubject'}
                        value={values.subject || ''}
                        options={contactsTypes}
                        onChange={(e) => setFieldValue('subject', e)}
                      />
                    </Div>
                    <Div fullWidth marginTop={'20px'}>
                      <Input
                        required={true}
                        disabled={props.sendLoading}
                        label={'contactName'}
                        name={'name'}
                        type={'text'}
                        fontSize={appSize.h3}
                        value={values.name}
                        onChange={handleChange}
                        // background={AppColors.BODY_BACKGROUND}
                        error={submitCount ? errors.name : undefined}
                      />
                    </Div>
                    <Div fullWidth marginTop={'20px'}>
                      <Input
                        required={true}
                        disabled={props.sendLoading}
                        label={'contactEmail'}
                        name={'email'}
                        type={'text'}
                        fontSize={appSize.h3}
                        value={values.email}
                        onChange={handleChange}
                        // background={AppColors.BODY_BACKGROUND}
                        error={submitCount ? errors.email : undefined}
                      />
                    </Div>
                    <Div fullWidth marginTop={'20px'}>
                      <Input
                        disabled={props.sendLoading}
                        required={true}
                        label={'contactPhone'}
                        name={'phone'}
                        type={'text'}
                        fontSize={appSize.h3}
                        value={values.phone}
                        onChange={handleChange}
                        // background={AppColors.BODY_BACKGROUND}
                        error={submitCount ? errors.phone : undefined}
                      />
                    </Div>
                    {/* <Div fullWidth marginTop={'20px'}>
                      <Input
                        disabled={props.sendLoading}
                        required={true}
                        label={'contactSubject'}
                        name={'subject'}
                        type={'text'}
                        value={values.subject}
                        fontSize={appSize.h3}
                        onChange={handleChange}
                        background={AppColors.BODY_BACKGROUND}
                        error={submitCount ? errors.subject : undefined}
                      />
                    </Div> */}

                    <Div fullWidth marginTop={'20px'} marginBottom={'30px'}>
                      <TextAreaInput
                        name={'content'}
                        disabled={props.sendLoading}
                        required={true}
                        size={appSize.h3}
                        label={'contactContent'}
                        rows={10}
                        height={'250px'}
                        value={values.content}
                        // background={AppColors.BODY_BACKGROUND}
                        onChange={handleChange}
                        error={submitCount ? errors.content : undefined}
                      />
                    </Div>

                    <VerticalView>
                      <HorizontalView marginTop={'20px'} flexWrap={'nowrap'}>
                        <Checkbox checked={values.isAgree} onChange={() => setFieldValue('isAgree', !values.isAgree)} />
                        <Text marginLeft={'5px'} fontSize={appSize.h3} fontWeight={600}>
                          {translation('contactPolicyMessage')}
                        </Text>
                      </HorizontalView>
                      {submitCount > 0 && errors.isAgree && (
                        <Text marginTop={'10px'} marginLeft={'5px'} fontSize={'10px'} color={AppColors.RED}>
                          {translation(errors.isAgree)}
                        </Text>
                      )}

                      <Div marginTop={'20px'}>
                        <TextLink href={'/privacy'}>{translation('privacyPolicy')}</TextLink>
                      </Div>
                    </VerticalView>

                    <Button
                      loading={props.sendLoading}
                      disabled={props.sendLoading}
                      marginTop={'20px'}
                      width={'250px'}
                      height={'50px'}
                      borderRadius={'5px'}
                      color={AppColors.WHITE}
                      background={AppColors.BLUE}
                      title={'sendContact'}
                      onClick={() => {
                        submitForm();
                      }}
                    />

                    {submitCount > 0 && Object.keys(errors).length > 0 && (
                      <Text
                        marginTop={'10px'}
                        marginLeft={'5px'}
                        fontSize={appSize.xs}
                        fontWeight={600}
                        color={AppColors.RED}
                      >
                        {translation('contactInvalidContent')}
                      </Text>
                    )}
                  </VerticalView>
                );
              }}
            />
          </VerticalView>
        </HorizontalView>
      </HorizontalView>
    </BaseComponent>
  );
};
