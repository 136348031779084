import { useState, useEffect } from 'react';
import config from 'config';
import axios from 'axios';
import { SessionState } from 'stores/user.store';

export const useMediaUpload = (session: SessionState) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState('0');
  const [openCropView, setOpenCropView] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [reloadAfterUpload, setReloadAfterUpload] = useState(false);

  useEffect(() => {
    if (reloadAfterUpload) {
      setReloadAfterUpload(false);
    }
  }, [reloadAfterUpload]);

  const onRestApiUpload = async (file: File, ratio = '') => {
    try {
      setLoading(true);

      const fileData = new FormData();
      fileData.set('fileUpload', file, `${file.name}`);
      fileData.set('ratio', ratio);

      await axios({
        method: 'POST',
        url: config.API_HOST_URL + '/api/v1/file/upload',
        data: fileData,
        headers: { 'Content-Type': 'multipart/form-data', authorization: session.token },
        onUploadProgress: function (progressEvent: any) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted.toFixed(2));
        },
      });

      setLoading(false);
      setReloadAfterUpload(true);
      setFiles([]);
      setProgress('');
    } catch (error) {
      setLoading(false);
      setFiles([]);
      setProgress('');
    }
  };

  const onSelectFiles = (files: FileList, ratio = '') => {
    const uploadedFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
      if (files.item(i)) {
        uploadedFiles.push(files.item(i) as File);
      }
    }

    if (uploadedFiles.length > 0) {
      const fileType = uploadedFiles[0].type;
      setFiles(uploadedFiles);
      if (fileType.includes('image/')) {
        setOpenCropView(true);
      } else if (fileType.includes('video/') || fileType.includes('audio/')) {
        onRestApiUpload(uploadedFiles[0], ratio);
      }
    }
  };

  const onCrop = async (file: File | null, ratio: string) => {
    setOpenCropView(false);
    if (!file) {
      return;
    }
    onRestApiUpload(file, ratio);
    setFiles([]);
  };

  return {
    loading,
    progress,
    files,
    openCropView,
    reloadAfterUpload,
    setFiles,
    setOpenCropView,
    onRestApiUpload,
    onCrop,
    onSelectFiles,
  };
};
