import { AppColors } from 'helpers';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSetting } from 'helpers/useAppSetting';
import { Text } from 'modules/common/vendors/Text';
import { HorizontalView, VerticalView, Div } from 'modules/common/vendors/Wrapper';
import { IArticle } from 'models/Article';
import { useTranslationText } from 'locale';
import { ServiceTab } from 'modules/user-interface/service/components/list/parts/ServiceTab';

type ServicesProps = {
  isLoading: boolean;
  serviceList: IArticle[];
};

export const HomeServicesPreview = (props: ServicesProps) => {
  const navigate = useNavigate();
  const { appLayout, appSize } = useAppSetting();
  const isMobile = appLayout.type === 'mobile';
  const [hoverOpacity, setHover] = useState(1);
  const { translation } = useTranslationText();

  return (
    <Div fullWidth background={AppColors.GRAY_LIGHT_F8} alignContent={'center'}>
      <VerticalView
        fullWidth
        maxWidth="1200px"
        minHeight="500px"
        alignContent="topCenter"
        paddingTop="50px"
        paddingBottom="50px"
      >
        <VerticalView marginBottom={isMobile ? '30px' : '50px'}>
          <Text fontSize={appSize.h1}>{translation('public.home.service.header')}</Text>
          <Text fontSize={appSize.h4} marginBottom="20px">
            {translation('public.home.service.subHeader')}
          </Text>
          <Text fontSize={appSize.h4} textAlign="center" whiteSpace="pre-line">
            {`当社は、日本とベトナムの架け橋となって健康食品の貿易、${
              isMobile ? '\n' : ''
            }ビジネス支援、広告代行などのサービスを提供します。`}
          </Text>
        </VerticalView>
        <HorizontalView fullWidth alignContent="center">
          {props.serviceList?.map((data, index) => {
            return (
              <ServiceTab
                key={index}
                title={data.title || ''}
                image={data.thumbnail || ''}
                onClick={() => {
                  // navigate(`/services/${data.id}`);
                  if (data.id === '1656307785562rbstmiqj') {
                    navigate(`/tokuma-service`);
                  }
                  if (data.id === '1639445582728auelx2gz') {
                    navigate(`/business-matching`);
                  }
                  if (data.id === '16394003880464a7tqbo2') {
                    navigate(`/services/${data.id}`);
                  }
                }}
              />
            );
          })}
        </HorizontalView>
        <Div
          marginTop="50px"
          minWidth={isMobile ? '180px' : '240px'}
          height={isMobile ? '45px' : '60px'}
          background={AppColors.RED_PRIMARY}
          alignContent={'center'}
          opacity={hoverOpacity}
          transition="opacity 0.5s"
          onMouseEnter={() => setHover(0.8)}
          onMouseLeave={() => setHover(1)}
          onClick={() => {
            navigate('/services');
          }}
        >
          <Text fontSize={appSize.md} color={AppColors.WHITE} fontWeight="bold">
            {translation('public.home.service.seeMore')}
          </Text>
        </Div>
      </VerticalView>
    </Div>
  );
};
