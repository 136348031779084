import { useSearchServices } from 'api/article/useArticleApi';
import React from 'react';
import { ServiceComponent } from '../../components/list';

export const ServiceContainer = () => {
  const { loading, onSearchArticles, articles } = useSearchServices();

  React.useEffect(() => {
    onSearchArticles;
  }, []);

  return <ServiceComponent isLoading={loading} serviceList={articles} />;
};

export default ServiceContainer;
