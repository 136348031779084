import 'antd/dist/antd.css';
import React from 'react';
import styled from 'styled-components';

import { Radio } from 'antd';
import { Text } from 'modules/common/vendors/Text';
import { HorizontalView } from 'modules/common/vendors/Wrapper';
import { useTranslationText } from 'locale';

const InputWrapper = styled.div`
  min-width: 280px;
`;

const InputText = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const MustLabel = styled.span`
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 3px;
  color: red;
  margin-left: 2px;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 10px;
`;

const InputContent = styled.div``;

export type RadioItem = {
  label?: string;
  value: any;
};

type SimpleRadioButtonProps = {
  isPreview?: boolean;
  options: RadioItem[];
  defaultValue?: RadioItem;
  name?: string;
  label?: string;
  fontSize?: string;
  error?: string;
  hidden?: boolean;
  disabled?: boolean;
  required?: boolean;
  invalid?: boolean;
  style?: React.CSSProperties;
  onChange?: (option?: RadioItem) => void;
};

export const RadioSelect = (props: SimpleRadioButtonProps) => {
  const { translation } = useTranslationText();
  if (props.hidden || !props.options) {
    return <></>;
  }

  return (
    <InputWrapper key={props.name}>
      {props.label && (
        <InputText>
          <Text fontWeight={500} fontSize={props.fontSize || '13px'}>
            {translation(props.label)} {props.required && <MustLabel>{'(*)'}</MustLabel>}
          </Text>
        </InputText>
      )}
      <InputContent style={props.style}>
        {props.isPreview && (
          <Text fontSize={props.fontSize || '13px'}>
            {props.options.find((item) => item.value === props.defaultValue?.value)?.label}
          </Text>
        )}
        {!props.isPreview && (
          <Radio.Group
            name={props.name}
            value={props.defaultValue?.value}
            onChange={(e) => {
              const foundItem = props.options.find((item) => `${item.value}` === `${e.target.value}`);
              if (foundItem) {
                props.onChange && props.onChange(foundItem);
              }
            }}
          >
            <HorizontalView fullWidth alignContent={'centerLeft'}>
              {props.options &&
                props.options.map((item, index) => {
                  return (
                    <Radio
                      disabled={props.disabled}
                      key={index}
                      name={item.label}
                      value={item.value}
                      style={{
                        fontSize: props.fontSize || '13px',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      {item.label}
                    </Radio>
                  );
                })}
            </HorizontalView>
          </Radio.Group>
        )}

        {props.error && <ErrorMessage>{`※ ${translation(props.error)}`}</ErrorMessage>}
      </InputContent>
    </InputWrapper>
  );
};
