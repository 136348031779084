export const global = {
  header: {
    login: 'ログイン',
  },
  export: 'エクスポート',
  type: '種類',
  all: '全',
  item: '件',
  status: 'スターテス',
  statusCollection: {
    all: 'すべて',
    new: '新しい',
    public: '公開',
    unpublic: '非公開',
  },
  accountStatus: {
    active: 'アクティブ',
    locked: 'ブロック',
    inactive: '非アクティブ',
  },
  business: {
    title: 'ビジネスマッチング',
    title2: 'スポットコンサルサービス',
    button: 'まずはお問合わせへ',
    feature: {
      title: '市場調査でこんな課題はありませんか？',
      feature1: '社内の人では必要とする情報源にたどり毛ない',
      feature2: '書箱や文献から市場に関する情報が入手できない',
      feature3: '現場から見た市場動向やトレンドを知りたい',
    },
    dokodemo: 'DOKODEMOのサービス内容',
    service: 'サービスの流れ',
    services: {
      1: {
        title: '1. お問合わせ',
        des: 'まずは、ベトナム進出について、検討されている内容についてお問合わせ\n下さい。',
      },
      2: {
        title: '2. 打ち合わせ',
        des:
          '・ベトナム事業展開に関するお客様の課題や背景、目的について\nヒアリングさせて頂きます。\n・ベトナム進出コンサルタントとより具体的な内容の設計、実地費用、\nスケジュールに関してすり合わせを実地致します。',
      },
      3: {
        title: '3. 実地提案書・見積書の作成',
        des:
          'お打ち合わせの内容やお問合わせの\n内容に基づいて、実地提案書と見積書を作成します。\nお客様に合意頂きましたら、調査開始来ましたら、調査開始となります。',
      },
      4: {
        title: '4. 調査状況の確認',
        des:
          '探索の実地中には、定例報告会・中間報告会を設定する事\nにより、探索の進捗状況や途中成果物をご報告致します。\n・定期的な報告会を実地する事により、探索の方向性や品質に問題がないか確認する事ができます。',
      },
      5: {
        title: '5. 最終報告・\n今後のアクションプラン',
        des:
          '・最終報告会にて面談内容のまとめを報告書作成の上、ご納品します。報告会では自由に質問をする事が可能です。\n・調査結果ではなく、お客様の課題解決に繋がる戦略立案、具合的な\nアクションプランまでご支援します。',
      },
    },
    service1: {
      title: 'ベトナム市場調査サポート',
      des:
        '宿泊先、交通手段のアレンジ　\n現地での通訳、アテンド、商談の通訳\n視察先のリストアップ、アポ取り\nなど、黄社のベトナムビジネス成功のためにサポートいたします。',
    },
    service2: {
      title: '現地パートナーの探索',
      des:
        '輸出先・取引先となり得るベトナム現地の企業を探索・\n基礎情報の取得をします。\nご要望に応してオンラインミーテイングのアポイントを取得します。',
    },
    service3: {
      title: 'ビジネスマッチングイベントの開催',

      des:
        'より手広く取引先・輸出先のベトナム企業を探索したい場合は、\nビジネスマッチングイベントを開催することも可能です。\n通訳・進行はDOKODEMOが実施します。',
    },
  },
  tokuma: {
    downloadFile: '資料ダウンロード',
    dokodemo: 'TOKUMAのサービス内容',
    title: 'TOKUMA',
    title2: 'プラットフォーム',
    title3: '運営',
    feature: {
      title1: 'TOKU = 特定技能',
      title2: 'MA = マッチング',
      title3: 'TOKUMA = 特定技能マッチング',
    },
    usedCompany: 'TOKUMAを活用している会社',
    service: 'TOKUMAのサービス内容',
    servicepower: 'TOKUMAの強み',
    services: {
      button: 'TOKUMAへ',
      free1: '初期費用',
      free2: '掲載費用',
      free3: '成果報酬',
      des: '6ヶ月無料プランが終了してからどうなりますか',
      des2:
        '6ヶ月無料プランが終了してから、自動的に有料プランへ切り替えません。\n引継サービスを利用したい場合、プラン一覧から有料プランを選んでから利用できます。\n有料プランは月額15,000円となります。成果報酬がございません。',
      1: {
        title: '01. 求人掲載から採用まで完全無料!',
        des:
          'TOKUMAで受け入れ機関もしくはサポート企業のIDを登録して、6ヶ月無料プランを選んでから、無料で求人掲載をスタートできます。\n初期費用や掲載費、応募や採用決定ごとの成果報酬も発生しません。掲載から採用まで無料でご利用頂けます。',
      },
      2: {
        title: '2. 多国籍の方が登録しています',
        des:
          '・ベトナム事業展開に関するお客様の課題や背景、目的についてヒアリングさせて頂きます。\n・ベトナム進出コンサルタントとより具体的な内容の設計、実地費用、スケジュールに関してすり合わせを実地致します。',
      },
      3: {
        title: '3. 特定技能の最新情報豊富',
        des:
          '簡単に、早く、特定技能の最新情報取得可能\n記事の種類 : 12分野の最新情報、制度説明、外国人の情報 (文化、経済、特定技能に関する情報など) ビザ申請の流れ、定着するためノウハウなどの記事もたくさんあります。',
      },
      4: {
        title: '4. 調査状況の確認',
        des:
          '探索の実地中には、定例報告会・中間報告会を設定する事により、探索の進捗状況や途中成果物をご報告致します。\n・定期的な報告会を実地する事により、探索の方向性や品質に問題がないか確認する事ができます。',
      },
      5: {
        title: '5. 最終報告・今後のアクションプラン',
        des:
          '・最終報告会にて面談内容のまとめを報告書作成の上、ご納品します。報告会では自由に質問をする事が可能です。\n・調査結果ではなく、お客様の課題解決に繋がる戦略立案、具合的なアクションプランまでご支援します。',
      },
    },
    service1: {
      title: ' 特定技能求人掲載',
      des: '~掲載無料~\n無料プランで特定技能求人掲載可能最短5分で完了!\nカンタン操作で求人掲載',
    },
    service2: {
      title: 'ノウハウ取得',
      des:
        '~特定技能記事豊富~\n特定技能制度説明から受入定着ノウハウなどの様々な記事がございます。\n日々に更新されますので、最新情報を\nアップデートできます。',
    },
    service3: {
      title: 'サポート企業検索',

      des:
        '~サポート企業を探せます~\n特定技能人材紹介会社、登録支援\n機関、行政書仕事事務所など探したい\n場合、TOKUMAで検索できます。',
    },
  },
  ja: '日本語',
  vi: 'ベトナム語',
  role: '権限',
  admin: '管理者',
  user: 'メンバー',
  emptyValue: '空',
  myAccount: 'アカウント',
  publish: '公開する',
  details: '詳細',
  edit: '編集する',
  delete: '削除する',
  created: '作成日',
  updated: '更新日',
  new: '作成する',
  noname: 'タイトルなし',
  description: 'コンテンツ',
  day: '日',
  hour: '時',
  minute: '分',
  updatedTime: '更新',
  display: '表示する',
  unpublish: '非公開',
  select: '選択する',
  search: '検索する',
  sharePost: '投稿を共有してください',
  pagination: 'ページ',
  seeMore: '続きを見る',
  noData: 'データなし',
  mobileSideBar: {
    home: 'ホームページ',
    news: '記事',
    service: 'サービス',
    login: 'ログイン',
    logout: 'ログアウト',
    language: '言語',
  },
  userDetails: {
    avatar: 'アバター',
    account: 'アカウント',
    title: 'アカウント情報',
    email: 'メール',
    name: '名前',
    gender: '性別',
    birthday: 'お誕生日',
    address: '住所',
    isLocked: '無効',
    isVerified: '検証',
    role: '権限',
    changePass: 'パスワードのリセット',
    save: '変更する',
    status: 'スターテス',
    resetPasswordModal: {
      header: 'パスワードのリセット',
      password: '新パスワード',
      confirmPassword: '新パスワード (確認用)',
      resetPassword: 'リセットする',
    },
  },
  usersSearch: {
    header: 'ユーザー一覧',
    avatar: 'アバター',
    information: '情報',
    email: 'メール',
    name: '名前',
    isLocked: '無効',
    isVerified: '検証',
    role: '権限',

    status: 'スターテス',
    searchbar: {
      title: '投稿検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: 'アカウントの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  public: {
    home: {
      news: {
        header: 'NEWS',
        subHeader: 'お知らせ',
        seeMore: 'お知らせ一覧',
        newsTab1: '通知',
        newsTab2: 'ニュース',
        newsTab3: '情報',
      },
      service: {
        header: 'SERVICE',
        subHeader: '事業案内',
        description:
          '当社は、日本とベトナムの架け橋となって健康食品の貿易、ビジネス支援、広告代行などのサービスを提供します。',
        seeMore: '詳細はこちら',
      },
    },
    login: {
      title: 'ログイン',
      email: 'メールアドレス',
      password: 'パスワード',
      resetPassword: 'パスワードをお忘れの方はこちら',
      register: 'アカウントをお持ちでない方は',
      registerMember: '新しいメンバーアカウントを作成する',
      button: 'こちら',
      loginButton: 'ログインする',
    },
    articlesList: {
      header: '記事',
      searchTitle: '記事検索',
      description: '記事、テクノロジーに関する情報の更新',
    },
    articleDetails: {
      header: '記事詳細',
      description: '',
    },
    articleSearch: {
      header: '検索記事',
      description: '',
    },
    serviceList: {
      header: '事業内容',
      description:
        '当社は、日本とベトナムの架け橋となって健康食品の貿易、ビジネス支援、広告代行などのサービスを提供します。',
    },
    about: {
      header: '会社概要',
    },
    privacy: {
      header: '使用条件',
      title: '使用条件',
    },
  },
  searchContacts: {
    all: '全',
    item: '件',
    name: 'お名前',
    email: 'メール',
    time: '予約時間',
    phone: '電話番号',
    subject: '件名',
    content: '内容',
    information: '情報',
    contactDirect: '予約時間',
  },
  searchArticles: {
    category: 'カテゴリー',
    header: '投稿一覧',
    headerService: 'サービス一覧',
    title: 'タイトル',
    description: '説明',
    public: '公開',
    content: '内容',
    preview: 'プレビュー',
    thumbnail: 'サムネイル',
    cover: '表紙画像',
    status: 'スターテス',
    date: '時間',
    information: '情報',
    searchbar: {
      title: '投稿検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: '投稿の削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },
  editArticle: {
    category: 'カテゴリー',
    header: '投稿',
    title: 'タイトル',
    description: '説明',
    public: '公開',
    content: '内容',
    preview: 'プレビュー',
    thumbnail: 'サムネイル',
    cover: '表紙画像',
    status: 'スターテス',
  },
  service: {
    category: 'サービス',
    service: 'サービス',
    title: 'タイトル',
    description: '説明',
    public: '公開',
    content: '内容',
    preview: 'プレビュー',
    status: 'ステータス',
    searchbar: {
      title: 'サービス検索',
      keyword: 'キーワード',
      condition: '検索条件クリア',
      buttonSearch: '検索',
    },
    alertDelete: {
      message: 'サービスの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },

  companyProfile: {
    header: '会社概要',
  },
  media: {
    title: 'メディア',
    message: '選択する',
    deleteAlert: {
      message: 'メディアの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },

  footer: {
    message: '「Viet Academy」でベトナム語を学びましょう。',
    buttonStart: '始まる',
    vietcademy: {
      title: 'Vietcademy の ベトナム語の教師になる',
      description: 'ネイティブレベルのベトナム語を生かし、 お金を稼ぎましょう。 Vietcademyはあなたを支援します。',
    },
  },

  common: {
    nodata: 'データなし',
    nocourse: 'コースなし',
    nobook: '教科書なし',
    course: 'コース',
    book: '教科書',
    exam: 'テスト',
    new: '新規',
    public: '公開',
    exportCsv: 'CSVエクスポート',
    buttonBack: '戻る',
    buttonSaveChange: '変更する',
    buttonSave: '保存する',
    buttonCreate: '作成する',
    yes: 'はい',
    no: 'いいえ',
    statistic: '統計',
    statisticData: 'コースデータの統計',
    topMenu: {
      home: 'HOME',
      about: '会社概要',
      service: '事業内容',
      news: 'ニュース',
      contact: 'お問い合わせ',
    },
  },
  mediaCenter: {
    title: 'メディアセンター',
    mediaList: 'メディアリスト',
  },
  about: {
    header: '運営会社情報',
  },
  privacy: {
    header: '使用条件',
  },
  terms: {
    header: '利用規約',
  },
  createUser: {
    title: '新規アカウント',
    email: 'メールアドレス',
    password: 'パスワード',
    confirmPassword: '確認パスワード',
    create: '作成する',
    role: '権限',
  },
  page404: {
    title: '',
    goHome: 'ホームへ戻る',
    description: '申し訳ありません。お探しのページが見つかりません。',
  },
  validator: {
    requiredCover: '表紙画像は必須項目です。',
    emailInvalid: '無効なメールアドレス',
    passwordMin: 'パスワードは8文字以上で入力してください。',
    passwordMax: 'パスワードは20文字以内で入力してください。',
    requiredPassword: 'パスワードは必須項目です。',
    requirePasswordConfirm: 'パスワードは必須項目です。',
    passwordInvalid: 'パスワードは半角英数記号(英数混合必須)で入力してください。',
    passwordNotMatch: '２つのパスワードが一致していません。',
    requiredEmail: 'メールアドレスは必須項目です。',
    invalidEmail: 'メールアドレスを正しく入力してください。',
    requiredForRole: '役割は必須項目です。',
    requiredSeoName: 'seonameは必須項目です。',
    requiredCategory: 'カテゴリーは必須項目です。',
    requiredThumbnail: 'サムネイルは必須項目です。',
    requiredTitle: 'タイトルは必須項目です。',
    requiredContent: '内容は必須項目です。',
    nameMinMax: '名前は2〜16文字で入力してください',
    requiredName: '名前は必須項目です。',
    requiredImage: '画像は必須項目です。',
    requiredAddress: '住所は必須項目です。',
    requiredGender: '性別は必須項目です。',
    requiredDescription: '説明は必須項目です。',
    requiredSubject: 'タイトルは必須項目です。',
    requiredPhone: '電話番号は必須項目です。',
    requiredAgree: '送信確認のチェックを選択してください。',
    requiredDatetime: '予約時間が必要です。',
    requiredType: 'タイプが必要です。',
    requiredExamLesson: 'レッスンを選択してください (最初にコースを選択する必要があります)',
    articleTitleMinMax: 'タイトルは5〜60文字で入力してください',
    articleDescriptionMinMax: 'タイトルは10〜120文字で入力してください',
    articleContentMinMax: 'タイトルは10〜文字で入力してください',
  },

  modals: {
    confirmLogout: {
      title: 'アカウントからサインアウトしますか？',
      close: 'いいえ',
      confirm: 'サインアウト',
    },
    confirmDelete: {
      message: 'データの削除を実施してもよろしですか？',
      close: 'いいえ',
      delete: 'はい',
    },
  },

  message: {
    create: 'データ作成',
    update: 'データ更新',
    delete: 'データ削除',
    error: '失敗しました',
    success: '成功しました',
    publishSuccess: '正常に公開されました',
    approvedSuccess: '承認しました。',
    submitForReviewSuccess: '提出しました',
    rejectSuccess: '差戻ししました',
    unpublishSuccess: 'コースのキャンセルが正常に完了しています',
    publishError: '',
    paymentError: 'エラーが発生しました。 お支払い情報をご確認ください',
    errorMessage1: 'エラーが発生しました。もう一度やり直してください',
  },
  searchbar: {
    keyword: 'キーワード',
    keywordPlaceholder: 'キーワードを入力します',
  },

  title: 'タイトル',
  content: 'コンテンツ',
  preview: 'プレビュー',
  publicTitle: '公衆',
  category: 'カテゴリー',
  keyword: 'キーワードを入力します',
  information: '情報',
  searchResult: '検索ヒット数',
  resultCount: '件',
  language: '言語',
  must: '必要',
  helpFor: 'ヘルプのために',
  save: '保存',
  close: '閉じる',
  newsTitle: '記事',
  postTitle: '役職',
  blogTitle: 'ブログ記事',
  examTitle: 'テスト記事',
  businessTitle: 'ビジネス',
  homeTitle: 'ホームページ',
  copied: 'コピーされました',
  userDetailsTitle: 'アカウント情報',
  contactDetails: '連絡先の詳細',
  contactHeader: 'お問い合わせ',
  contactList: 'コンタクト一覧',
  contactName: 'お名前',
  contactEmail: 'メール',
  contactTime: '予約時間',
  contactPhone: '電話番号',
  contactSubject: '件名',
  contactContent: '内容',
  contactDirect: '予約時間',
  sendContact: '送る',
  sendContactSuccess: '送りました。',
  sendContactError: 'エラーが発生しました。もう一度やり直してください。',
  contactMessage: 'お問い合わせいただきありがとうございます. 登録日時までにご連絡いたします。',
  contactMessage1: '※ 全ての時間帯に「×」が表示されている場合は、一度ページを再読み込みしてください',
  contactMessage2: '※ 解決しない場合は「contact@vietcademy.jp」までご連絡ください。',
  contactMessage3: '下記カレンダーから、ご希望の時間帯をクリックしてください。所要時間は25分を予定しております。',
  contactMessage4: '※ 通常、ご連絡頂いてから2営業日以内にご返信します',
  contactMessage5: 'ご不明な点などございましたら、弊社サポートセンターまでお問い合わせください。',
  contactSentMessage: 'このたびは、お問い合わせいただき誠にありがとうございます。',
  contactSentMessage2:
    'お問い合わせいただいた内容につきましては後ほど担当者よりご案内を差し上げます。今しばらくお待ちくださませ。',
  contactSentMessage3: 'なお、内容によりご返信にお時間をいただく場合がございます。あらかじめご了承ください。',
  contactPolicyMessage: '個人情報の取り扱いに同意の上、送信しますか？',
  contactInvalidContent: '入力内容に不備があります。再度ご確認ください。',
  back: '戻る',
  no: 'なし',
  privacyPolicy: '使用条件',
  newsKeyword: 'キーワードまたはIDを入力してください',
  cropImageModal: {
    title: 'クロップ画像',
    selectRatio: '比率を選択する',
  },
  processing: '処理中',
  company: {
    privacy: '使用条件',
    header: '会社情報の編集',
    profile: 'プロフィール',
    companyName: '会社名',
    foundation: '設立',
    representatorName: '代表者',
    tax: '資本金',
    postalCode: '郵便番号',
    city: '都道府県',
    streetAddress: '番地、丁・町村',
    building: '建物名・部屋番号',
    phone: '電話番号',
    email: 'メール',
    mail: 'メールアドレス',
    bussinessContent: '事業内容',
    mapUrl: '地図のリンク',
    description: '説明',
    save: '保存する',
  },
};
