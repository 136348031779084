import React from 'react';
import styled from 'styled-components';
import { CmsSidebar } from './components/CmsSidebar';
import { useAppSetting } from 'helpers/useAppSetting';
import { HorizontalView } from 'modules/common/vendors/Wrapper';
import CmsHeader from './components/CmsHeader';
import { CardWrapper } from 'modules/common/vendors/CommonWrappers';

const MainWrapper = styled.div<{ left: string; marginTop: string; width: string; paddingBottom: string }>`
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.left};
  width: ${(props) => props.width};
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  padding-bottom: ${(props) => props.paddingBottom};
`;

const IndexWrapper = styled.div`
  top: 0px;
  left: 0px;
  z-index: 999;
  width: 100vw;
`;

interface CMSIndexProps {
  children?: React.ReactNode;
}
export const CMSLayout = (props: CMSIndexProps) => {
  const { appLayout } = useAppSetting();
  const [isExplain, setExplain] = React.useState(true);
  React.useEffect(() => {
    setExplain(appLayout.type === 'mobile' ? false : true);
  }, [appLayout.type]);

  const isMobile = appLayout.type === 'mobile' || appLayout.type === 'tablet';

  return (
    <IndexWrapper>
      {isMobile && <CmsHeader />}

      {!isMobile && (
        <CmsSidebar
          hide={isMobile}
          onExplain={(_isExplain) => {
            setExplain(_isExplain);
          }}
        />
      )}
      <MainWrapper
        left={appLayout.isMobile ? '0px' : isExplain ? appLayout.sidebar.maxWidth : appLayout.sidebar.minWidth}
        width={appLayout.isMobile ? '100vw' : isExplain ? appLayout.body.minWidth : appLayout.body.maxWidth}
        marginTop={!isMobile ? '0px' : appLayout.header.height}
        paddingBottom={'20px'}
      >
        <HorizontalView fullWidthHeight alignContent={'topCenter'} padding={'20px'}>
          <CardWrapper minHeight={!isMobile ? '100vh' : 'calc(100vh - 80px)'}>{props.children}</CardWrapper>
        </HorizontalView>
      </MainWrapper>
    </IndexWrapper>
  );
};
