import React, { useState } from 'react';
import { Div, HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { NewsCard } from './parts/NewsCard';
import { IArticle } from 'models/Article';
import { AnimationView } from 'modules/common/vendors/Animation';
import { useTranslationText } from 'locale';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { IOptionType } from 'models/IOptionType';
import { useAppSetting } from 'helpers/useAppSetting';
import { BannerPreview } from 'modules/common/components/Banner';
import { FlatList } from 'modules/common/vendors/FlatList';
import { Button } from 'modules/common/vendors/Button';
import { ContentLoading } from 'modules/common/vendors/Loading';
import { useNavigate } from 'react-router';
import { AppColors } from 'helpers';
import { Text } from 'modules/common/vendors/Text';
import { ArticleCategory } from 'helpers/const';

type NewsProps = {
  loading: boolean;
  hasLoadMore: boolean;
  articles: IArticle[];
  onLoadMore: () => void;
  onChangeCategory: (cate: string) => void;
};

type TabProps = {
  title: string;
  isSelected: boolean;
  textSize: string;
  onClick(): void;
};

const NewsTab = (props: TabProps) => {
  const [isHovered, setHover] = useState(false);
  const background = props.isSelected || isHovered ? 'black' : 'white';
  const textColor = props.isSelected || isHovered ? 'white' : 'black';
  return (
    <Div
      width="33%"
      maxWidth="250px"
      height="60px"
      background={background}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={props.onClick}
      border="1px solid #ddd"
      transition="background 0.5s"
      alignContent={'center'}
    >
      <Text fontSize={props.textSize} color={textColor}>
        {props.title}
      </Text>
    </Div>
  );
};

const NewsComponent = (props: NewsProps) => {
  const [selectedTab, setSelectedTab] = useState(ArticleCategory.Notification);
  const { appLayout, appSize } = useAppSetting();
  const { translation, getTypes } = useTranslationText();
  const newsTypes: IOptionType[] = getTypes('articleCategories');
  const navigate = useNavigate();

  return (
    <BaseComponent>
      <VerticalView fullWidth alignContent={'topCenter'} paddingBottom={'40px'}>
        <BannerPreview
          blurValue={20}
          banner={'/images/news_bg.jpg'}
          title={translation('public.articlesList.header')}
          description={translation(`public.articlesList.description`)}
        />

        <HorizontalView
          fullWidth
          alignContent={'topCenter'}
          maxWidth={'1280px'}
          paddingLeft={'20px'}
          paddingRight={'20px'}
        >
          <VerticalView width={!appLayout.isMobile ? 'calc(100% - 360px)' : '100%'} alignContent={'topCenter'}>
            <AnimationView animation={'fadeIn'} width="100%">
              <HorizontalView fullWidth padding={appLayout.isMobile ? '0px' : '20px'}>
                <VerticalView width="100%" alignContent="topBetween">
                  <HorizontalView fullWidth alignContent="centerLeft">
                    <NewsTab
                      title={translation('public.home.news.newsTab1')}
                      isSelected={selectedTab === ArticleCategory.Notification}
                      textSize={appSize.h3}
                      onClick={() => {
                        setSelectedTab(ArticleCategory.Notification);
                        props.onChangeCategory(ArticleCategory.Notification);
                      }}
                    />
                    <NewsTab
                      title={translation('public.home.news.newsTab2')}
                      isSelected={selectedTab === ArticleCategory.News}
                      textSize={appSize.h3}
                      onClick={() => {
                        setSelectedTab(ArticleCategory.News);
                        props.onChangeCategory(ArticleCategory.News);
                      }}
                    />
                    <NewsTab
                      title={translation('public.home.news.newsTab3')}
                      isSelected={selectedTab === ArticleCategory.Information}
                      textSize={appSize.h3}
                      onClick={() => {
                        setSelectedTab(ArticleCategory.Information);
                        props.onChangeCategory(ArticleCategory.Information);
                      }}
                    />
                  </HorizontalView>
                  <FlatList
                    values={props.articles}
                    renderItem={(data: IArticle, index: number) => {
                      const type = newsTypes.find((category) => category.value === data.category);
                      if (data.category === selectedTab) {
                        return (
                          <HorizontalView width={'100%'} alignContent={'centerLeft'} key={index}>
                            <NewsCard
                              align={'left'}
                              time={data.createdTime}
                              title={data.title || ''}
                              image={data.thumbnail || ''}
                              description={data.description || ''}
                              category={type?.label || ''}
                              onClick={() => {
                                navigate(`/articles/${data.id}`);
                              }}
                            />
                          </HorizontalView>
                        );
                      }
                    }}
                  />
                </VerticalView>
              </HorizontalView>
            </AnimationView>

            {props.hasLoadMore && (
              <VerticalView width={'80%'} borderRadius={'4px'} height={'60px'} background={AppColors.GRAY_LIGHT3}>
                {props.loading && <ContentLoading loading size={'30px'} color={AppColors.PRIMARY} />}
                {!props.loading && (
                  <Button
                    background={'transparent'}
                    title={'seeMore'}
                    fontSize={'30px'}
                    color={AppColors.PRIMARY}
                    borderRadius={'10px'}
                    onClick={props.onLoadMore}
                  />
                )}
              </VerticalView>
            )}
          </VerticalView>
        </HorizontalView>
      </VerticalView>
    </BaseComponent>
  );
};

export default React.memo(NewsComponent);
