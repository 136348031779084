import { useBaseGetQuery } from 'api/base/useBaseGetQuery';
import { IHomePage } from 'models/HomePage';
import { homeQueries } from '.';

export const useGetHomeContent = () => {
  const homeContent = useBaseGetQuery<null, IHomePage>({
    fetchPolicy: 'cache-and-network',
    query: homeQueries.GET_HOME,
  });

  return {
    loading: homeContent.loading,
    homePage: homeContent.item,
    error: homeContent.error,
    onGetHomePage: homeContent.getQuery,
  };
};
