import config from './index';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('SESSION_TOKEN') || 'GUEST';
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const cache = new InMemoryCache({
  addTypename: false,
});

const httpLink = new HttpLink({
  uri: config.API_HOST_URL,
  credentials: 'same-origin',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (process.env.NODE_ENV === 'development') {
    if (graphQLErrors) {
      console.log(`[GraphQL error]: ${JSON.stringify(graphQLErrors)}`);
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: cache,
});

export default client;
