/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  id?: string;
  width?: string;
  gap?: number;
  items: any;
  columns: number;
  background?: string;
  renderItem: (item: any, index: number) => React.ReactNode;
};

const Div = styled.div<{ columns: number; width?: string; gap?: number; background?: string }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, minmax(200px, 1fr));
  grid-gap: ${(props) => props.gap || '20px'};
  width: ${(props) => props.width || '100%'};
  background: ${(props) => props.background || 'transparent'};
`;

export const GridView = React.forwardRef<HTMLDivElement | null, Props>((props, ref) => {
  const { items, renderItem } = props;
  return (
    <Div id={props.id} ref={ref} columns={props.columns} width={props.width} gap={props.gap}>
      {items.map((item: any, index: number) => {
        return renderItem(item, index);
      })}
    </Div>
  );
});
