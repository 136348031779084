import React from 'react';
import styled from 'styled-components';
import { HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { Text } from 'modules/common/vendors/Text';
import { IConType, SVGIcon } from 'modules/common/vendors/Icon';
import { DropdownProps, ItemProps } from 'modules/common/vendors/NaviDropdown';
import { AppColors, AppConst } from 'helpers';
import { useTranslationText } from 'locale';
import { useAppSetting } from 'helpers/useAppSetting';
import { ConfirmModal } from 'modules/common/components/Modals/ConfirmModal';
import { useNavigate } from 'react-router-dom';
import { useLogoutUser } from 'api/user/useUserApi';
import { useSession } from 'stores/user.store';

const SidebarWrapper = styled.div<{ width: string }>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: ${(props) => props.width};
  height: 100vh;
  padding-top: 10px;
  overflow: hidden;
  background: ${AppColors.sidebar.bg};
  z-index: ${AppConst.zIndex.header + 50};
  transition: 0.2s;
`;

const SidebarContentWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  padding-bottom: 60px;
  overflow-y: scroll;
  position: relative;
`;

const SidebarMenuWrapper = styled.div<{ background?: string }>`
  width: 100%;
  background: ${(props) => (props.background ? props.background : AppColors.sidebar.menu)};
  cursor: pointer;
`;
const MenuWrapper = styled.button`
  width: 100%;
  padding-left:10px;
  padding-right:10px;
  outline: none;
  border: none;
  border-style:none:
  cursor:pointer;
  cursor: pointer;
  background-color:transparent;
`;
const SubMenuWrapper = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const SubItemWrapper = styled.div`
  width: 100%;
  height: 50px;
  padding-left: 20px;
  background: ${AppColors.sidebar.menu};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const ItemIcon = styled.div<{ left?: number; right?: number }>`
  margin-left: ${(props) => (props.left ? props.left : 0)}px;
  margin-right: ${(props) => (props.right ? props.right : 0)}px;
  color: ${AppColors.WHITE};
  cursor: pointer;
`;

const ItemTitle = styled.div`
  color: ${AppColors.sidebar.text};
`;

const ExplainButtonWrapper = styled.div`
  z-index: 20;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: ${AppColors.sidebar.bg};
`;

const Dot = styled.div`
  // margin-right: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: white;
`;

interface SidebarProps {
  hide: boolean;
  children?: React.ReactNode;
  onExplain?: (isExplain: boolean) => void;
}

export const CmsSidebar = (props: SidebarProps) => {
  const navigate = useNavigate();
  const { getTypes } = useTranslationText();
  const { dispatch } = useSession();
  const { appLayout, appSize } = useAppSetting();
  const [status, setStatus] = React.useState<{ [key: string]: any }>({});
  const [tab, setTab] = React.useState<ItemProps>({});
  const [isExplain, setExplain] = React.useState(appLayout.type === 'mobile' ? false : true);
  const [confirmLogout, setConfirmLogout] = React.useState(false);
  const navisItems: DropdownProps[] = getTypes('cmsSidebarMenus');
  const { onLogout } = useLogoutUser();
  const isMobile = appLayout.type === 'mobile' || appLayout.type === 'tablet';
  if (isMobile) {
    return null;
  }

  return (
    <SidebarWrapper width={isExplain ? appLayout.sidebar.maxWidth : appLayout.sidebar.minWidth}>
      <SidebarContentWrapper>
        <VerticalView fullWidth paddingTop={'20px'} alignContent={'centerLeft'} overflowY={'scroll'}>
          {navisItems.map((item, index) => {
            return (
              <SidebarMenuWrapper key={index} background={AppColors.sidebar.bg}>
                <VerticalView fullWidth alignContent={'centerLeft'}>
                  <MenuWrapper
                    onClick={() => {
                      if (item.url?.includes('logout')) {
                        setConfirmLogout(true);
                      } else {
                        if (!item.subMenus) {
                          item.url && navigate(item.url);
                          setTab({ url: item.url } as any);
                        } else if (isExplain) {
                          const st = { ...status };
                          st[item.label!] = !st[item.label!];
                          setStatus(st);
                        } else {
                          if (item.subMenus && item.subMenus.length > 0 && item.subMenus[0].url) {
                            navigate(item.subMenus[0].url);
                          }
                        }
                      }
                    }}
                  >
                    <HorizontalView fullWidth height={'50px'} padding={'10px'} alignContent={'centerBetween'}>
                      <HorizontalView alignContent={'centerLeft'}>
                        <HorizontalView marginTop={'5px'}>
                          <ItemIcon right={isExplain ? 10 : 30}>
                            <SVGIcon
                              name={item.prefixIcon as IConType}
                              color={tab.url === item.url ? 'orange' : AppColors.sidebar.text}
                            />
                          </ItemIcon>
                        </HorizontalView>

                        {isExplain && (
                          <ItemTitle>
                            <Text
                              color={tab.url === item.url ? 'orange' : AppColors.sidebar.text}
                              fontWeight={tab.url === item.url ? 500 : 300}
                            >
                              {item.label}
                            </Text>
                          </ItemTitle>
                        )}
                      </HorizontalView>
                      {item.subMenus && isExplain && (
                        <ItemIcon style={{ margin: '0px' }}>
                          <SVGIcon
                            name={status[item.label!] ? 'arrow-up' : 'arrow-down'}
                            color={tab.url === item.url ? 'orange' : AppColors.sidebar.text}
                          />
                        </ItemIcon>
                      )}
                      {!item.subMenus && isExplain && (
                        <Dot style={{ background: tab.url === item.url ? 'orange' : AppColors.sidebar.text }} />
                      )}
                    </HorizontalView>
                    {/* )} */}
                  </MenuWrapper>

                  {item.subMenus && status[item.label!] && isExplain && (
                    <SubMenuWrapper>
                      {item.subMenus.map((subItem, subIndex) => {
                        return (
                          <SubItemWrapper
                            key={subIndex}
                            onClick={(e) => {
                              e.preventDefault();
                              if (!subItem.url) {
                                return;
                              }
                              setTab(subItem);
                              navigate(subItem.url);
                            }}
                          >
                            <HorizontalView fullWidthHeight padding={'15px'} alignContent={'centerLeft'}>
                              <Dot style={{ background: tab.url === subItem.url ? 'orange' : 'white' }} />
                              <ItemTitle>
                                <Text
                                  marginLeft={'15px'}
                                  fontSize={appSize.lg}
                                  color={tab.url === subItem.url ? 'orange' : 'white'}
                                >
                                  {subItem.label}
                                </Text>
                              </ItemTitle>
                            </HorizontalView>
                          </SubItemWrapper>
                        );
                      })}
                    </SubMenuWrapper>
                  )}
                </VerticalView>
              </SidebarMenuWrapper>
            );
          })}
        </VerticalView>
      </SidebarContentWrapper>
      <ExplainButtonWrapper
        onClick={() => {
          const _isExplain = !isExplain;
          props.onExplain && props.onExplain(_isExplain);
          setExplain(_isExplain);
        }}
      >
        <HorizontalView fullHeight alignContent={'centerRight'}>
          <ItemIcon right={10}>
            <SVGIcon name={isExplain ? 'arrow-left' : 'arrow-right'} color={AppColors.sidebar.text} size={'30px'} />
          </ItemIcon>
        </HorizontalView>
      </ExplainButtonWrapper>
      <ConfirmModal
        open={confirmLogout}
        title={'modals.confirmLogout.title'}
        cancelTitle={'modals.confirmLogout.close'}
        confirmTitle={'modals.confirmLogout.confirm'}
        onClose={() => setConfirmLogout(false)}
        onConfirm={async () => {
          setConfirmLogout(false);
          await onLogout();
          navigate('/');
          dispatch({ type: 'logout' });
        }}
      />
    </SidebarWrapper>
  );
};
