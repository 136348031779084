import React from 'react';
import { HorizontalView, Div, VerticalView } from 'modules/common/vendors/Wrapper';
import { AppColors } from 'helpers';
import { AnimationView } from 'modules/common/vendors/Animation';
import { ContentLoading } from 'modules/common/vendors/Loading';
import { TitleWrapper } from 'modules/common/vendors/CommonWrappers';

export const PlashScreen = () => {
  return (
    <Div
      position={'fixed'}
      left={'0'}
      top={'0'}
      bottom={'0'}
      right={'0'}
      width={'100vw'}
      height={'100vh'}
      zIndex={10000}
    >
      <AnimationView width={'100vw'} height={'100vh'}>
        <HorizontalView
          position={'fixed'}
          fullWidthHeight
          zIndex={10000}
          background={AppColors.PRIMARY}
          alignContent={'center'}
        >
          <VerticalView>
            <TitleWrapper size={'40px'} color={'white'}>
              Homepage
            </TitleWrapper>
            <Div marginTop={'60px'}>
              <ContentLoading loading color={'white'} />
            </Div>
          </VerticalView>
        </HorizontalView>
      </AnimationView>
    </Div>
  );
};
