import React from 'react';
import { NewsDetailsComponent } from 'modules/user-interface/article/components/ArticleDetailsPage';
import { useParams } from 'react-router-dom';
import { useGetArticle } from 'api/article/useArticleApi';

export const ArticleDetailsPage = () => {
  const { articleId } = useParams();
  const { loading, error, article } = useGetArticle(articleId);

  return <NewsDetailsComponent isLoading={loading} error={error?.error} news={article} recentNews={[]} />;
};
