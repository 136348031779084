import React from 'react';
import { HorizontalView } from 'modules/common/vendors/Wrapper';
import { SelectDropdown } from 'modules/common/vendors/Select';
import { useTranslationText } from 'locale';
import { IOptionType } from 'models/IOptionType';

type SelectLimitProps = {
  onChangeLimit: (limit: number) => void;
};
export const SelectLimit = (props: SelectLimitProps) => {
  const { getTypes } = useTranslationText();
  const [value, setValue] = React.useState(30);
  const items: IOptionType[] = getTypes('limitOptions');
  const onChange = (value: number) => {
    props.onChangeLimit(value);
    setValue(value);
  };
  return (
    <HorizontalView alignContent={'centerLeft'}>
      <SelectDropdown width={'100px'} value={value} options={items} onChange={(_value) => onChange(_value as number)} />
    </HorizontalView>
  );
};
