import React, { useState } from 'react';
import styled from 'styled-components';
import { VerticalView } from 'modules/common/vendors/CommonWrappers';
import { useTranslationText } from 'locale';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { BannerPreview } from 'modules/common/components/Banner';
import { FlatList } from 'modules/common/vendors/FlatList';
import { Div, HorizontalView } from 'modules/common/vendors/Wrapper';
import { Text } from 'modules/common/vendors/Text';
import { useAppSetting } from 'helpers/useAppSetting';
import { ICompany } from 'models/Company';
import { Image } from 'modules/common/vendors/Image';
import { Button } from 'modules/common/vendors/Button';
import { useNavigate } from 'react-router-dom';

const ShadowBox = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-top: -150px;
  box-shadow: 0px 0px 6px #8f8f8f;
  z-index: 1;
  background: white;
`;

export const BusinessMatchingComponent = () => {
  const { translation } = useTranslationText();
  const { appTheme, appLayout } = useAppSetting();
  const navigate = useNavigate();
  const [hover, setHover] = useState(-1);
  const services = [
    {
      iamge: 'images/contact.jpg',
      title: 'business.services.1.title',
      des: 'business.services.1.des',
    },
    {
      iamge: 'images/uchi.png',
      title: 'business.services.2.title',
      des: 'business.services.2.des',
    },
    {
      iamge: 'images/teian.png',
      title: 'business.services.3.title',
      des: 'business.services.3.des',
    },
    {
      iamge: 'images/accept.jpg',
      title: 'business.services.4.title',
      des: 'business.services.4.des',
    },
    {
      iamge: 'images/hokoku.png',
      title: 'business.services.5.title',
      des: 'business.services.5.des',
    },
  ];
  return (
    <BaseComponent>
      <VerticalView fullWidth>
        <HorizontalView
          fullWidth
          height={'600px'}
          style={{ backgroundImage: 'linear-gradient(0.25turn, #5987CE, #ebf8e1, #f69d3c)' }}
        >
          <VerticalView
            width={appLayout.isMobile ? '100%' : '50%'}
            height={appLayout.isMobile ? '50%' : '100%'}
            alignContent="center"
          >
            <Text fontSize={appLayout.isMobile ? '30px' : '50px'} fontWeight={900} color="#fff">
              {translation('business.title')}
            </Text>
            <Text
              fontSize={appLayout.isMobile ? '18px' : '22px'}
              borderRadius="7px"
              padding="0 60px"
              background="#fff"
              fontWeight={700}
              style={{ boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
              marginTop="40px"
              color="#6392D8"
            >
              {translation('business.title2')}
            </Text>
            <Button
              padding="0 40px"
              background="orange"
              color="#fff"
              borderRadius="25px"
              height={appLayout.isMobile ? '40px' : '50px'}
              marginTop="40px"
              fontWeight={700}
              fontSize={appLayout.isMobile ? '18px' : '25px'}
              title="business.button"
              onClick={() => navigate(`/contact`)}
            />
          </VerticalView>
          <VerticalView width={appLayout.isMobile ? '100%' : '50%'} height={appLayout.isMobile ? '50%' : '100%'}>
            <Image width="100%" height="100%" objectFit="cover" src="images/topBackground.jpg" />
          </VerticalView>
        </HorizontalView>
        <VerticalView
          fullWidth
          height={appLayout.isMobile ? 'auto' : '550px'}
          background="#10142F"
          alignContent="topCenter"
        >
          <Text
            fontSize={appLayout.isMobile ? '20px' : '40px'}
            color="#fff"
            fontWeight={700}
            marginTop={appLayout.isMobile ? '30px' : '65px'}
          >
            {translation('business.feature.title')}
          </Text>
          <HorizontalView fullWidth alignContent="center" marginTop="40px" padding={appLayout.isMobile ? '10px' : '0'}>
            <VerticalView
              width={appLayout.isMobile ? '80%' : '300px'}
              height="300px"
              style={{ transform: hover === 1 ? 'scale(1.1)' : 'scale(1)' }}
              transition={' all .3s ease'}
              onMouseEnter={() => setHover(1)}
              onMouseLeave={() => setHover(-1)}
              borderRadius="10px"
              background="#fff"
              overflow={'hidden'}
            >
              <Image width="100%" height="80%" objectFit="cover" src="images/feature2.jpg" />
              <Text fontWeight={500} padding="0 20px">
                {translation('business.feature.feature1')}
              </Text>
            </VerticalView>
            <VerticalView
              width={appLayout.isMobile ? '80%' : '300px'}
              marginTop={appLayout.isMobile ? '30px' : '0'}
              height="300px"
              style={{ transform: hover === 2 ? 'scale(1.1)' : 'scale(1)' }}
              transition={' all .3s ease'}
              onMouseEnter={() => setHover(2)}
              onMouseLeave={() => setHover(-1)}
              margin={appLayout.isMobile ? '0' : '0 50px'}
              borderRadius="10px"
              background="#fff"
              overflow={'hidden'}
            >
              <Image width="100%" height="80%" objectFit="cover" src="images/feature3.jpg" />
              <Text fontWeight={500} padding="0 20px">
                {translation('business.feature.feature2')}
              </Text>
            </VerticalView>
            <VerticalView
              width={appLayout.isMobile ? '80%' : '300px'}
              marginTop={appLayout.isMobile ? '30px' : '0'}
              marginBottom={appLayout.isMobile ? '30px' : '0'}
              height="300px"
              style={{ transform: hover === 3 ? 'scale(1.1)' : 'scale(1)' }}
              transition={' all .3s ease'}
              onMouseEnter={() => setHover(3)}
              onMouseLeave={() => setHover(-1)}
              borderRadius="10px"
              background="#fff"
              overflow={'hidden'}
            >
              <Image width="100%" height="80%" objectFit="cover" src="images/feature1.jpg" />
              <Text fontWeight={500} padding="0 20px">
                {translation('business.feature.feature3')}
              </Text>
            </VerticalView>
          </HorizontalView>
        </VerticalView>
        {!appLayout.isMobile && (
          <VerticalView fullWidth alignContent="topCenter">
            <Text fontSize={appLayout.isMobile ? '25px' : '50px'} fontWeight={800} color="#0B96D0" marginTop="40px">
              {translation('business.dokodemo')}
            </Text>
            <HorizontalView fullWidth height="400px">
              <HorizontalView marginTop="30px" width="65%" height="100%" position="relative">
                <VerticalView
                  position={'absolute'}
                  padding="15px"
                  background="#ffffffaa"
                  height="300px"
                  right="-45%"
                  top="50px"
                  alignContent="topLeft"
                >
                  <Text fontSize="45px" fontWeight={700}>
                    {'1' + '-' + translation('business.service1.title')}
                  </Text>
                  <Text fontSize="25px" fontWeight={500} whiteSpace="pre-line">
                    {translation('business.service1.des')}
                  </Text>
                </VerticalView>
                <Image width="100%" height="100%" objectFit="cover" src="images/service1.jpg" />
              </HorizontalView>
            </HorizontalView>
            <HorizontalView marginTop="30px" fullWidth height="400px" alignContent="centerRight">
              <HorizontalView marginTop="30px" width="65%" height="100%" position="relative">
                <VerticalView
                  position="absolute"
                  padding="15px"
                  background="#ffffffaa"
                  height="300px"
                  left="-45%"
                  top="50px"
                  alignContent="topLeft"
                >
                  <Text fontSize="45px" fontWeight={700}>
                    {'2' + '-' + translation('business.service2.title')}
                  </Text>
                  <Text fontSize="25px" fontWeight={500} whiteSpace="pre-line">
                    {translation('business.service2.des')}
                  </Text>
                </VerticalView>
                <Image width="100%" height="100%" objectFit="cover" src="images/service2.jpg" />
              </HorizontalView>
            </HorizontalView>
            <HorizontalView marginTop="60px" fullWidth height="400px">
              <HorizontalView width="65%" height="100%" position="relative">
                <VerticalView
                  position="absolute"
                  padding="15px"
                  background="#ffffffaa"
                  height="300px"
                  right="-45%"
                  top="50px"
                  alignContent="topLeft"
                >
                  <Text fontSize="45px" fontWeight={700}>
                    {'3' + '-' + translation('business.service3.title')}
                  </Text>
                  <Text fontSize="25px" fontWeight={500} whiteSpace="pre-line">
                    {translation('business.service3.des')}
                  </Text>
                </VerticalView>
                <Image width="100%" height="100%" objectFit="cover" src="images/service3.jpg" />
              </HorizontalView>
            </HorizontalView>
          </VerticalView>
        )}
        {appLayout.isMobile && (
          <VerticalView fullWidth alignContent="topCenter">
            <Text fontSize={'25px'} fontWeight={800} color="#0B96D0" marginTop="40px">
              {translation('business.dokodemo')}
            </Text>
            <VerticalView fullWidth padding="20px">
              <Div fullWidth height="40%">
                <Image width="100%" height="100%" objectFit="cover" src="images/service1.jpg" />
              </Div>
              <VerticalView fullWidth height="60%">
                <Text fontSize="20px" fontWeight={700}>
                  {'1' + '-' + translation('business.service1.title')}
                </Text>
                <Text fontSize="15px" fontWeight={500} whiteSpace="pre-line">
                  {translation('business.service1.des')}
                </Text>
              </VerticalView>
            </VerticalView>
            <VerticalView fullWidth padding="20px">
              <Div fullWidth height="40%">
                <Image width="100%" height="100%" objectFit="cover" src="images/service2.jpg" />
              </Div>
              <VerticalView fullWidth height="60%">
                <Text fontSize="20px" fontWeight={700}>
                  {'2' + '-' + translation('business.service2.title')}
                </Text>
                <Text fontSize="15px" fontWeight={500} whiteSpace="pre-line">
                  {translation('business.service2.des')}
                </Text>
              </VerticalView>
            </VerticalView>
            <VerticalView fullWidth padding="20px">
              <Div fullWidth height="40%">
                <Image width="100%" height="100%" objectFit="cover" src="images/service3.jpg" />
              </Div>
              <VerticalView fullWidth height="60%">
                <Text fontSize="20px" fontWeight={700}>
                  {'3' + '-' + translation('business.service3.title')}
                </Text>
                <Text fontSize="15px" fontWeight={500} whiteSpace="pre-line">
                  {translation('business.service3.des')}
                </Text>
              </VerticalView>
            </VerticalView>
          </VerticalView>
        )}
        <VerticalView fullWidth>
          <Text fontSize={appLayout.isMobile ? '25px' : '50px'} fontWeight={800} color="#0B96D0" marginTop="40px">
            {translation('business.service')}
          </Text>
          <HorizontalView fullWidth maxWidth="1400px" alignContent={appLayout.isMobile ? 'topCenter' : 'centerBetween'}>
            {services.map((e, i) => {
              return (
                <VerticalView
                  key={i}
                  width={appLayout.isMobile ? '85%' : '250px'}
                  marginTop={appLayout.isMobile ? '20px' : '0'}
                  minHeight={appLayout.isMobile ? 'auto' : '420px'}
                  border="1px solid #5cbcf3"
                  borderRadius="8px"
                  alignContent="topLeft"
                  overflow={'hidden'}
                  padding="5px"
                >
                  <Div width="100%" height="30%">
                    <Image src={e.iamge} width="100%" height="100%" objectFit="cover" />
                  </Div>

                  <Text whiteSpace="pre-line" fontWeight={700} fontSize="18px" width="100%" textAlign="center">
                    {translation(e.title)}
                  </Text>

                  <Text fontWeight={500} marginTop="10px" fontSize="15px" whiteSpace="pre-line">
                    {translation(e.des)}
                  </Text>
                </VerticalView>
              );
            })}
          </HorizontalView>
        </VerticalView>
      </VerticalView>
    </BaseComponent>
  );
};
