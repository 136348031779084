import React from 'react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { Button } from 'modules/common/vendors/Button';
import { Text } from 'modules/common/vendors/Text';
import { SVGIcon } from 'modules/common/vendors/Icon';
import { HorizontalView } from 'modules/common/vendors/Wrapper';
import { ContentLoading } from 'modules/common/vendors/Loading';
import { AppColors } from 'helpers';
import { useTranslationText } from 'locale';

const { ExportCSVButton } = CSVExport;

interface TableProps {
  loading?: boolean;
  keyField: string;
  height?: string;
  bordered?: boolean;
  striped?: boolean;
  condensed?: boolean;
  exportCSV?: boolean;
  addButton?: boolean;
  hover?: boolean;
  columns: Array<ColumnDescription<any>>;
  data: any[];
  searchBar?: React.ReactElement;
  onExport?: () => void;
  onAdd?: () => void;
}

export const Table = (props: TableProps) => {
  const {
    onAdd,
    columns,
    data,
    addButton,
    bordered,
    striped,
    hover,
    exportCSV,
    keyField,
    condensed,
    searchBar,
    loading: dataLoading,
  } = props;

  const { translation } = useTranslationText();
  const NoDataIndication = () => (
    <HorizontalView fullWidth alignContent={'center'}>
      <div className="no-data">{translation('common.nodata')}</div>
    </HorizontalView>
  );

  const renderTableData = (list: any[]) => {
    return (
      <ToolkitProvider keyField={keyField} data={dataLoading ? [] : list || []} columns={columns} exportCSV={exportCSV}>
        {(props) => (
          <div>
            {searchBar && <>{searchBar}</>}
            <HorizontalView marginBottom={addButton || exportCSV ? '20px' : '0px'} alignContent={'centerLeft'}>
              {addButton && (
                <Button
                  preixIcon={'plus'}
                  background={'#2E8B57'}
                  color={'white'}
                  borderRadius={'5px'}
                  paddingLeft={'10px'}
                  paddingRight={'10px'}
                  marginRight={'20px'}
                  height={'40px'}
                  onClick={onAdd}
                >
                  <Text>{translation('common.new')}</Text>
                </Button>
              )}
              {exportCSV && (
                <ExportCSVButton
                  /* eslint-disable react/prop-types */
                  {...props.csvProps}
                  style={{
                    backgroundColor: '#2E8B57',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    height: '40px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <HorizontalView alignContent={'center'}>
                    <SVGIcon name={'file'} />
                    <Text marginLeft={'5px'}>{translation('common.exportCsv')}</Text>
                  </HorizontalView>
                </ExportCSVButton>
              )}
            </HorizontalView>

            <BootstrapTable
              {...props.baseProps}
              bordered={bordered}
              striped={striped}
              hover={hover}
              remote={true}
              condensed={condensed}
              headerWrapperClasses="header-class"
              rowClasses={'custom-row-class'}
              noDataIndication={() => {
                // if (data.length) {
                //   return <></>;
                // }
                return (
                  <HorizontalView height={'60px'}>
                    {dataLoading ? (
                      <ContentLoading loading size={'40px'} color={AppColors.PRIMARY} />
                    ) : (
                      <NoDataIndication />
                    )}
                  </HorizontalView>
                );
              }}
            />
          </div>
        )}
      </ToolkitProvider>
    );
  };
  return renderTableData(data);
};

export default Table;
