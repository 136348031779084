export const ERROR_MESSAGE = {
  SYSTEM_ERROR: {
    code: 500,
    title: 'サーバーエラー',
    message: 'サーバーエラー。もう一回確認してください。',
  },
  INVALID_USERNAME_OR_PASSWORD: {
    code: 402,
    title: 'ログイン失敗',
    message: 'ユーザーID、パスワードのいずれかに誤りがあります。',
  },
  INVALID_PASSWORD: {
    code: 402,
    title: 'ログイン失敗',
    message: 'パスワードのいずれかに誤りがあります。',
  },
  ACCOUNT_TEMPORARY_LOCKED: {
    code: 414,
    title: 'ログイン失敗',
    message: 'アカウントは一時的にロックされています。',
  },
  ACCOUNT_IS_NOT_VERIFY: {
    code: 410,
    title: 'ログイン失敗',
    message: 'メールアドレスが検証されていません',
  },
  ACCOUNT_IS_NOT_EXIST: {
    code: 407,
    title: 'ログイン失敗',
    message: 'アカウントが存在しません',
  },
  ARTICLE_IS_NOT_EXIST: {
    code: 407,
    title: '記事',
    message: 'この記事は存在しません',
  },
  ARTICLE_CREATE_ERROR: {
    code: 500,
    title: '記事の作成',
    message: '記事の作成が失敗しました',
  },
  ARTICLE_UPDATE_ERROR: {
    code: 400,
    title: '記事の変更',
    message: '記事の変更が失敗しました',
  },
  ARTICLE_DELETE_ERROR: {
    code: 400,
    title: '記事の削除',
    message: '記事の削除が失敗しました',
  },
  COMPANY_IS_NOT_EXIST: {
    code: 407,
    title: '会社概要',
    message: '会社概要は存在しません',
  },
  COMPANY_CREATE_ERROR: {
    code: 500,
    title: '会社概要の作成',
    message: '会社概要の作成が失敗しました',
  },
  COMPANY_UPDATE_ERROR: {
    code: 400,
    title: '会社概要の変更',
    message: '会社概要の変更が失敗しました',
  },
  EMAIL_IS_EXIST: {
    code: 407,
    title: '作成アカウント',
    message: 'メールアドレスが既に存在します。',
  },
};
