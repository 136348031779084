import gql from 'graphql-tag';

export const CHECK_AUTH = gql`
  query checkAuth {
    checkAuth {
      id
      email
      role
    }
  }
`;

export const GET = gql`
  query operationGetUser($input: OperationGetUserInput!) {
    operationGetUser(input: $input) {
      id
      email
      name
      isVerified
      isLocked
      avatar
      updatedTime
      createdTime
      __typename
    }
  }
`;

export const SEARCH_USER = gql`
  query operationSearchUsers($input: OperationSearchUsersInput!) {
    operationSearchUsers(input: $input) {
      totalItems
      items {
        id
        name
        isLocked
        isVerified
        email
        avatar
        updatedTime
        createdTime
      }
    }
  }
`;
