import { useQuery } from '@apollo/client';
import { userQueries } from 'api/user';
import * as React from 'react';
import { IUser } from 'models/User';
import { authReducer, SessionState } from 'stores/user.store';
import { SessionContext } from 'stores/user.store';

const initialState = {
  isLoaded: false,
  token: null,
  isAuthenticated: false,
  user: {},
} as SessionState;

export const SessionProvider = (props: { children: React.ReactNode }) => {
  const token = localStorage.getItem('SESSION_TOKEN');
  const { data, loading } = useQuery(userQueries.CHECK_AUTH, { fetchPolicy: 'cache-and-network' });
  const [session, dispatch] = React.useReducer(authReducer, initialState);

  const checkAuth: IUser = data ? data.checkAuth : undefined;
  const isUnAuthenticated = !checkAuth || !token || (token && token.length === 0);

  React.useEffect(() => {
    if (!loading) {
      const user = {
        id: checkAuth?.id,
        role: checkAuth?.role,
      } as IUser;
      dispatch({ type: 'update', payload: { user: user, isAuthenticated: !isUnAuthenticated, token: token } });
    }
    // eslint-disable-next-line
  }, [checkAuth, loading]);

  return <SessionContext.Provider value={{ session, dispatch }}>{props.children}</SessionContext.Provider>;
};
