import React from 'react';
import { HorizontalView, VerticalView } from 'modules/common/vendors/CommonWrappers';
import { useTranslationText } from 'locale';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { BannerPreview } from 'modules/common/components/Banner';
import styled from 'styled-components';
import { IArticle } from 'models/Article';
import { useNavigate } from 'react-router-dom';
import { ServiceTab } from './parts/ServiceTab';

const ShadowBox = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-top: -150px;
  box-shadow: 0px 0px 6px #8f8f8f;
  z-index: 1;
  background: white;
`;
export type LoginFormik = {
  email: string;
  password: string;
};

type ServicesProps = {
  isLoading: boolean;
  serviceList: IArticle[];
};

export const ServiceComponent = (props: ServicesProps) => {
  const { translation } = useTranslationText();
  const navigate = useNavigate();

  return (
    <BaseComponent contentLoading={props.isLoading}>
      <VerticalView fullWidth alignContent={'topCenter'}>
        <BannerPreview
          banner={'/images/service_bg.jpg'}
          title={translation('public.serviceList.header')}
          description={translation('public.serviceList.description')}
        />
        <ShadowBox>
          <HorizontalView width="100%" alignContent="center" paddingBottom="100px" paddingTop="100px">
            {props.serviceList?.map((data, index) => {
              return (
                <ServiceTab
                  key={index}
                  title={data.title || ''}
                  image={data.thumbnail || ''}
                  onClick={() => {
                    if (data.id === '1656307785562rbstmiqj') {
                      navigate(`/tokuma-service`);
                    }
                    if (data.id === '1639445582728auelx2gz') {
                      navigate(`/business-matching`);
                    }
                    if (data.id === '16394003880464a7tqbo2') {
                      navigate(`/services/${data.id}`);
                    }
                  }}
                />
              );
            })}
          </HorizontalView>
        </ShadowBox>
      </VerticalView>
    </BaseComponent>
  );
};
