import React from 'react';
import { MobileCmsSideBar } from './MobileCmsSideBar';
import { HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { Button } from 'modules/common/vendors/Button';
import { useAppSetting } from 'helpers/useAppSetting';
import { AppColors } from 'helpers';
import { ConfirmModal } from 'modules/common/components/Modals/ConfirmModal';
import { Text } from 'modules/common/vendors/Text';
import { IUser } from 'models/User';
import { useNavigate } from 'react-router-dom';

type HeaderSearchBarProps = {
  isSessionLoaded?: boolean;
  auth?: IUser;
  isCMS: boolean;
  isMobile: boolean;
  path: string;
};

export const MobileHeaderView = (props: HeaderSearchBarProps) => {
  // const { logout } = useUserApi();
  const navigate = useNavigate();
  const { appTheme } = useAppSetting();
  const [confirmLogout, setConfirmLogout] = React.useState(false);
  const [openSidebar, setOpenSidebar] = React.useState(false);
  if (!props.isMobile) {
    return null;
  }

  return (
    <VerticalView fullWidth alignContent={'centerLeft'} zIndex={20} paddingBottom={'10px'} paddingTop={'10px'}>
      <HorizontalView fullWidth alignContent={'centerBetween'}>
        <HorizontalView>
          <HorizontalView>
            <Button
              preixIcon={'menu'}
              fontSize={'30px'}
              color={appTheme.header.text}
              background={AppColors.TRANSPARENT}
              onClick={() => {
                setOpenSidebar(true);
              }}
            />

            <HorizontalView>
              <HeaderTitle />
            </HorizontalView>
          </HorizontalView>
        </HorizontalView>

        <HorizontalView paddingRight={'10px'}>
          {!props.auth?.id && (
            <Button
              preixIcon={'user'}
              fontSize={'25px'}
              color={appTheme.header.text}
              background={AppColors.TRANSPARENT}
              onClick={() => {
                navigate('/login');
              }}
            />
          )}

          {props.auth?.id && (
            <>
              <Button
                preixIcon={'user'}
                fontSize={'20px'}
                color={appTheme.header.text}
                background={AppColors.TRANSPARENT}
                onClick={() => {
                  if (props.auth?.role === 'ADMIN') {
                    navigate(`/cms/users/${props.auth.id}`);
                  } else {
                    navigate('/user/my-profile');
                  }
                }}
              />
            </>
          )}
        </HorizontalView>
      </HorizontalView>
      <MobileCmsSideBar open={openSidebar} auth={props.auth} onClose={() => setOpenSidebar(false)} />
      <ConfirmModal
        open={confirmLogout}
        title={'modals.confirmLogout.title'}
        cancelTitle={'modals.confirmLogout.close'}
        confirmTitle={'modals.confirmLogout.confirm'}
        onClose={() => setConfirmLogout(false)}
        onConfirm={async () => {
          setConfirmLogout(false);
          // await logout.logoutUser();
          navigate('/');
        }}
      />
    </VerticalView>
  );
};

export const HeaderTitle = () => {
  //   const { pageTitle } = useTitle();
  const { appTheme } = useAppSetting();
  return (
    <Text fontWeight={600} fontSize={'15px'} color={appTheme.header.text}>
      {''}
    </Text>
  );
};
