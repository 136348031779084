import React from 'react';
import styled from 'styled-components';
import { Hr } from 'modules/common/vendors/CommonParts';
import { VerticalView } from 'modules/common/vendors/CommonWrappers';
import { Text } from 'modules/common/vendors/Text';
import { AppColors } from 'helpers';
import { IArticle } from 'models/Article';
import { dateUtil } from 'helpers/date.utils';
import { ArticleEditorPreview } from 'modules/common/vendors/ArticleEditor';
import { AnimationView } from 'modules/common/vendors/Animation';
import { HorizontalView } from 'modules/common/vendors/Wrapper';
import { useTranslationText } from 'locale';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { IError } from 'models/Models';
import { useAppSetting } from 'helpers/useAppSetting';
import { useNavigate } from 'react-router-dom';

const CategoryWrapper = styled.div`
  font-weight: normal;
  font-size: 15px;
  font-weight: 600;
  color: ${AppColors.BLUE};
  background: transparent;
  cursor: pointer;
`;

const TimeWrapper = styled.div`
  font-weight: normal;
  font-size: 15px;
  color: ${AppColors.DARK};
  background: transparent;
`;

type ServiceDetailsPros = {
  error?: IError;
  isLoading: boolean;
  service?: IArticle;
};

export const ServiceDetailsComponent = (props: ServiceDetailsPros) => {
  const { translation } = useTranslationText();
  const { appLayout, appSize } = useAppSetting();
  const navigate = useNavigate();
  return (
    <BaseComponent error={props.error} contentLoading={props.isLoading}>
      <VerticalView fullWidth alignContent={'topCenter'}>
        <VerticalView fullWidth maxWidth={'1000px'} marginTop={'40px'}>
          <AnimationView animation={'fadeIn'}>
            <VerticalView width="100%" alignContent="topLeft" padding={appLayout.isMobile ? '10px' : '0px'}>
              <HorizontalView fullWidth alignContent={'centerBetween'}>
                <CategoryWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/services');
                  }}
                >
                  <Text fontSize={appSize.lg}>{translation('service.category')}</Text>
                </CategoryWrapper>
                <TimeWrapper>
                  <Text fontSize={appSize.xs}>
                    {dateUtil.dateFormat(props.service?.createdTime || '', 'YYYY/MM/DD, dddd')}
                  </Text>
                </TimeWrapper>
              </HorizontalView>

              <Text marginTop={'10px'} fontSize={appSize.xl}>
                {props.service?.title}
              </Text>

              <HorizontalView fullWidth paddingBottom={'20px'}>
                {props.service && props.service.content && <ArticleEditorPreview setContents={props.service.content} />}
              </HorizontalView>
              <Hr />
              {/* <ContactContainer isServicePage /> */}
            </VerticalView>
          </AnimationView>
        </VerticalView>
      </VerticalView>
    </BaseComponent>
  );
};
