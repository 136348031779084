import React from 'react';
import styled from 'styled-components';
import { IConType, SVGIcon } from '../Icon';
import { HorizontalView } from '../Wrapper';
import { Text } from '../Text';

const DropdownStyled = styled.ul`
  z-index: 9999;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const Dropbtn = styled.div`
  cursor: pointer;
  display: inline-block;
  color: black;
  text-align: center;
  padding: 14px 10px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 9999;
`;

const DropDownContentWrapper = styled.div`
  display: none;
  z-index: 9999;
  position: absolute;
`;

const DropDownContent = styled.li`
  z-index: 9999;
  margin-right: 10px;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1;
  font-family: hiragino kaku gothic pro;
`;

const DropDownItem = styled.div<{ direction?: string }>`
  display: block;
  z-index: 9999;
  &:hover {
    ${(props) => {
      if (props.direction === 'right') {
        return `
          display: flex;
          align-items:flex-start;
        `;
      } else if (props.direction === 'left') {
        return `
          display: flex;
          justify-content:flex-end;
          align-items:flex-start;
        `;
      } else {
        return 'font-weight: bold;';
      }
    }};
    transition: 0.3s;
  }
  &:hover ${DropDownContentWrapper} {
    ${(props) => {
      if (props.direction === 'right') {
        return 'padding-top:40px;';
      } else if (props.direction === 'left') {
        return 'padding-top:40px;';
      } else {
        return '';
      }
    }};
    transition: 0.3s;
    display: block;
  }
`;

const SubItemWrapper = styled.div`
  padding-left: 10px;
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const SubItem = styled.div`
  color: black;
  padding: 12px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const IconWrapper = styled.div``;

export const HoverWrapper = styled.div<{ isHover?: boolean }>`
  position: relative;
  width: auto;
  cursor: pointer;
  &:hover {
    ${(props) => `
      transition:width 0.3s;
      font-weight:bolder !important;
      border-radius: ${props.isHover ? '10px' : '0px'};
      // background-color:${props.isHover ? '#30111111' : '0px'};
    `}
  }
`;

export interface ItemProps {
  prefixIcon?: IConType;
  suffixesIcon?: IConType;
  color?: string;
  label?: string;
  value?: string;
  url?: string;
}
export interface DropdownProps {
  prefixIcon?: IConType | string;
  prefixIconSize?: string;
  suffixesIcon?: IConType;
  suffixesIconSize?: string;
  label?: string;
  size?: string;
  color?: string;
  value?: string;
  isHover?: boolean;
  subMenus?: ItemProps[];
  isBolder?: boolean;
  direction?: 'right' | 'bottom' | 'left';
  url?: string;
  onClick?: (item: ItemProps | DropdownProps) => void;
}
export const NaviDropdown = (props: DropdownProps) => {
  const item = props;

  return (
    <DropdownStyled>
      <DropDownItem direction={props.direction}>
        <HoverWrapper
          isHover={item.isHover}
          style={{ transition: 'height 3s' }}
          onClick={() => {
            if (props.url && props.url.length > 0) {
              props.onClick && props.onClick(item);
            }
          }}
        >
          <HorizontalView alignContent="centerLeft">
            {item.prefixIcon && item.prefixIcon.includes('images/') && (
              <IconWrapper>
                <img
                  height={props.prefixIconSize ? props.prefixIconSize : '20px'}
                  width={props.prefixIconSize ? props.prefixIconSize : '20px'}
                  src={item.prefixIcon}
                  alt=""
                />
              </IconWrapper>
            )}
            {props.prefixIcon && !item.prefixIcon!!.includes('images/') && (
              <SVGIcon
                name={item.prefixIcon as IConType}
                size={props.prefixIconSize ? props.prefixIconSize : '20px'}
                color={item.color ? item.color : 'white'}
              />
            )}
            {item.label && (
              <Dropbtn
                onClick={() => {
                  if (props.url && props.url.length > 0) {
                    props.onClick && props.onClick(item);
                  }
                }}
              >
                <Text
                  color={item.color ? item.color : 'white'}
                  fontWeight={props.isBolder ? 700 : 500}
                  fontSize={props.size ? props.size : '20px'}
                >
                  {item.label}
                </Text>
              </Dropbtn>
            )}
            {props.suffixesIcon && (
              <SVGIcon
                name={item.suffixesIcon}
                size={props.prefixIconSize ? props.prefixIconSize : '20px'}
                color={item.color ? item.color : 'white'}
              />
            )}
          </HorizontalView>
        </HoverWrapper>

        {item.subMenus && item.subMenus.length > 0 && (
          <DropDownContentWrapper>
            <DropDownContent>
              {item.subMenus.map((menu, index) => {
                return (
                  <SubItemWrapper key={index}>
                    <HorizontalView alignContent={'centerLeft'} flexWrap={'nowrap'}>
                      {menu.prefixIcon && <SVGIcon name={menu.prefixIcon} size={'20px'} color={'black'} />}
                      <SubItem
                        key={index}
                        onClick={() => {
                          props.onClick && props.onClick(menu);
                        }}
                      >
                        <Text fontWeight={'normal'} color={'black'}>
                          {menu.label}
                        </Text>
                      </SubItem>
                      {menu.suffixesIcon && <SVGIcon name={menu.suffixesIcon} size={'20px'} color={'black'} />}
                    </HorizontalView>
                  </SubItemWrapper>
                );
              })}
            </DropDownContent>
          </DropDownContentWrapper>
        )}
      </DropDownItem>
    </DropdownStyled>
  );
};
