import React, { useState } from 'react';
import { Div, HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { Text } from 'modules/common/vendors/Text';
import { Image } from 'modules/common/vendors/Image';
import { useLocation } from 'react-router';
import { AppColors } from 'helpers';
import { useTranslationText } from 'locale';
import { useNavigate } from 'react-router-dom';

type HeaderSearchBarProps = {
  isMobile: boolean;
  path: string;
  menuItems: { title: string; path: string }[];
};

type MenuItemProps = {
  title: string;
  path: string;
  isSelected: boolean;
};

const MenuItem = (props: MenuItemProps) => {
  const navigate = useNavigate();
  const [isHovered, setHover] = useState(false);
  const backgroundColor = isHovered || props.isSelected ? AppColors.RED_PRIMARY : AppColors.GRAY_BGR;
  const textColor = isHovered || props.isSelected ? AppColors.WHITE : AppColors.BLACK;
  const { translation } = useTranslationText();

  return (
    <HorizontalView
      width="20%"
      height="60px"
      background={backgroundColor}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      transition="background 0.5s"
      position="relative"
      onClick={(e) => {
        e.preventDefault();
        navigate(props.path);
      }}
      alignContent={'center'}
    >
      <Div
        fullHeight
        width="1px"
        display={props.title === 'HOME' ? 'flex' : 'none'}
        position="absolute"
        left="0"
        background="white"
      />
      <Text color={textColor} fontSize="14px">
        {translation(props.title)}
      </Text>
      <Div fullHeight width="1px" position="absolute" right="0" background="white" />
    </HorizontalView>
  );
};

export const WebHeaderBar = (props: HeaderSearchBarProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (props.isMobile) {
    return null;
  }

  return (
    <Div fullWidth alignContent="center" background="white">
      <HorizontalView width="100%" maxWidth="1200px" height="100px" alignContent="centerLeft">
        <VerticalView fullHeight onClick={() => navigate('/')} paddingBottom="10px">
          <Image height="100%" src="/logo.png" objectFit="contain" />
        </VerticalView>
      </HorizontalView>
      <Div fullWidth background={AppColors.GRAY_BGR} alignContent="center">
        <HorizontalView width="100%" maxWidth="1200px">
          {props.menuItems.map((item, index) => {
            return (
              <MenuItem key={index} title={item.title} path={item.path} isSelected={location.pathname === item.path} />
            );
          })}
        </HorizontalView>
      </Div>
    </Div>
  );
};
