import { useBaseSearchQuery } from 'api/base/useBaseSearchQuery';
import { mediaQueries } from 'api/media';
import { IMedia, ISearchMediasInput } from 'models/Media';
import { ISortDirection } from 'models/types';
import { useEffect, useCallback } from 'react';
import { CropRatio } from '../../Modals/CropImageModal';

export const useSearchMedias = (mediaType: string | null, ratios?: CropRatio[]) => {
  const { onSearch, onChangePage, setInput, input, items, page, totalPages, loading } = useBaseSearchQuery<
    ISearchMediasInput,
    IMedia
  >({
    defaulInput: {
      limit: 30,
      page: 1,
      sortDirection: ISortDirection.Desc,
    } as ISearchMediasInput,
    query: mediaQueries.SEARCH,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const _query = { ...input };
    if (ratios?.length === 1) {
      _query.ratio = ratios[0];
    }
    if (mediaType) {
      _query.mimetype = mediaType;
    }
    setInput(_query);
  }, [mediaType, ratios]);

  const onSearchMedia = useCallback(() => {
    onSearch(input);
  }, [input]);

  // Mounted
  useEffect(() => {
    if (!input.mimetype) {
      return;
    }
    onSearchMedia();
  }, [input]);

  return {
    loading,
    page,
    totalPages,
    onSearchMedia,
    onChangePage,
    setSearchParam: setInput,
    searchParam: input,
    items,
  };
};
