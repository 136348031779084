import React from 'react';
import { Div, HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { IArticle } from 'models/Article';
import { Text } from 'modules/common/vendors/Text';
import { useAppSetting } from 'helpers/useAppSetting';
import { Image } from 'modules/common/vendors/Image';

type PromotionCardProps = {
  cardHeight: number;
  featurePost: IArticle;
};

export const PromotionCard = (props: PromotionCardProps) => {
  const { appLayout } = useAppSetting();

  return (
    <VerticalView fullWidthHeight alignContent={'center'} flexWrap={'nowrap'} position="relative">
      {/* <VerticalView
        alignContent={'center'}
        position="absolute"
        top={'0'}
        left={'0'}
        bottom={'0'}
        right={'0'}
        zIndex={2}
      >
        <Text
          marginTop={appLayout.isMobile ? '10px' : '0px'}
          fontSize={`${appLayout.isMobile ? 20 : props.cardHeight / 14}px`}
          color={'white'}
          fontWeight={700}
          textAlign={'left'}
        >
          {props.featurePost?.title || ''}
        </Text>
        <HorizontalView maxWidth="1200px">
          <Text fontSize={`${props.cardHeight / 25}px`} marginTop={'10px'} color={'white'} textAlign={'center'}>
            {props.featurePost?.description || ''}
          </Text>
        </HorizontalView>
      </VerticalView> */}
      {/* <Div
        position={'absolute'}
        top={'0'}
        left={'0'}
        bottom={'0'}
        right={'0'}
        alignContent={'centerLeft'}
        background="black"
        opacity={0.3}
        zIndex={1}
      /> */}
      <Div position={'absolute'} top={'0'} left={'0'} bottom={'0'} right={'0'} alignContent={'centerLeft'}>
        <Image
          height={'100%'}
          width={'100%'}
          objectFit={'cover'}
          src={props.featurePost.cover || '/images/banner.png'}
        />
      </Div>
    </VerticalView>
  );
};
