import React from 'react';
import styled from 'styled-components';
import { HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { AppColors } from 'helpers';
import { CropImage } from 'modules/common/vendors/CropImage';
import { zIndex } from 'helpers/const';
import { Text } from 'modules/common/vendors/Text';
import { useTranslationText } from 'locale';
import { Modal } from 'modules/common/vendors/Modal';
import { useWindowDimensions } from 'modules/common/vendors/Hooks/useWindow/useWindowDimensions';

export const ModalWrapper = styled.div``;
export const AlertModalWrapper = styled.div``;
export const ContentWrapper = styled.div``;
export const AddUserWrapper = styled.div``;

export type CropRatio = '16x9' | '4x3' | '3x2' | '2x1' | '1x1' | '1x2' | '3x4' | '5x1' | '3x1';

const ratiosFixed = [
  { label: '16x9', value: 16 / 9 },
  { label: '5x1', value: 5 },
  { label: '4x3', value: 4 / 3 },
  { label: '3x2', value: 3 / 2 },
  { label: '2x1', value: 2 },
  { label: '1x1', value: 1 },
  { label: '3x1', value: 3 / 1 },
  { label: '1x2', value: 1 / 2 },
  { label: '3x4', value: 3 / 4 },
];

interface Props {
  ratios?: CropRatio[];
  maxImageWidth?: number;
  open: boolean;
  file: File | null;
  onCrop: (file: File | null, ratio: string) => void;
  onClose: () => void;
}

const Wrapper = styled.div``;

export const CropImageModal = (props: Props) => {
  const { isMobile } = useWindowDimensions();
  const { translation } = useTranslationText();
  const ratios = props.ratios
    ? ratiosFixed.filter((ratio) => props.ratios?.find((label) => label === ratio.label))
    : ratiosFixed;

  const [currentRatio, setCurrentRatio] = React.useState(ratios[0]);
  const [isChanged, setIsChanged] = React.useState(false);

  React.useEffect(() => {
    setIsChanged(true);
  }, [currentRatio]);

  React.useEffect(() => {
    if (props.ratios) {
      return;
    }

    document.body.style.overflow = props.open ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobile, props.open, props.ratios]);

  return (
    <Wrapper>
      <Modal
        zIndex={zIndex.header + 2001}
        title={translation('cropImageModal.title')}
        height={isMobile ? '80vh' : '90vh'}
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
        closeOnBackDropClick
      >
        <HorizontalView
          width={isMobile ? '100vw' : '90vw'}
          height={isMobile ? '80vh' : '90vh'}
          alignContent={'center'}
          padding={'20px'}
        >
          <VerticalView fullWidth alignContent={'centerLeft'}>
            <HorizontalView
              fullWidth
              minWidth={'300px'}
              padding={'20px'}
              background={AppColors.GRAY_LIGHT2}
              marginBottom={'20px'}
              borderRadius={'20px'}
            >
              {props.open && isChanged && (
                <CropImage
                  maxImageWidth={props.maxImageWidth}
                  file={props.file}
                  onCrop={props.onCrop}
                  aspect={currentRatio}
                />
              )}
            </HorizontalView>
            {ratios.length > 1 && (
              <HorizontalView fullWidth alignContent={'center'}>
                {ratios.map((ratio, index) => {
                  return (
                    <HorizontalView
                      key={index}
                      padding={'20px'}
                      background={ratio.label === currentRatio.label ? AppColors.PRIMARY : AppColors.GRAY_LIGHT2}
                      borderRadius={'5px'}
                      alignContent={'center'}
                      margin={'5px'}
                      onClick={() => setCurrentRatio(ratio)}
                    >
                      <Text color={ratio.label === currentRatio.label ? AppColors.WHITE : AppColors.BLACK}>
                        {ratio.label}
                      </Text>
                    </HorizontalView>
                  );
                })}
              </HorizontalView>
            )}
          </VerticalView>
        </HorizontalView>
      </Modal>
    </Wrapper>
  );
};
