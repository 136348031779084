import React from 'react';
import styled from 'styled-components';
import { useTranslationText } from 'locale';
import { useLocation, useNavigate } from 'react-router';
import { HorizontalView } from 'modules/common/vendors/Wrapper';
import { Button } from 'modules/common/vendors/Button';
import { useAppSetting } from 'helpers/useAppSetting';
import { IUser } from 'models/User';
import { DropdownProps, ItemProps, NaviDropdown } from 'modules/common/vendors/NaviDropdown';
import { AppColors, AppConst } from 'helpers';
import { useSession } from 'stores/user.store';
import { useWindowDimensions } from 'modules/common/vendors/Hooks/useWindow/useWindowDimensions';
import { ConfirmModal } from 'modules/common/components/Modals/ConfirmModal';

const MenuWrapper = styled.div<{ isCMS: boolean }>`
  z-index: 20;
  padding-left: 10px;
  padding-right: 10px;
  display: ${(props) => (props.isCMS ? 'none' : 'block')};
`;

type HeaderSearchBarProps = {
  isSessionLoaded?: boolean;
  auth?: IUser;
  isCMS: boolean;
  isMobile: boolean;
  path: string;
};

export function getLang(getTypes: (path: string) => ItemProps[]) {
  const lang = 'vi';
  const menus = getTypes('languages');
  const languagesMenu: DropdownProps = {
    prefixIcon: `/images/lang_${lang}.png`,
    subMenus: menus,
  };
  return languagesMenu;
}

function getLeftMenus(translation: (path: string) => string) {
  const userMenu = [
    {
      prefixIcon: 'book',
      label: translation('common.topMenu.course'),
      url: '/courses',
    },
    {
      prefixIcon: 'file',
      label: translation('common.topMenu.news'),
      url: '/articles',
    },
    // {
    //   prefixIcon: 'service',
    //   label: translation('common.topMenu.service'),
    //   url: '/services',
    // },
    {
      prefixIcon: 'question',
      label: translation('common.topMenu.help'),
      url: '/help-center',
    },
  ];

  return userMenu as ItemProps[];
}

const RightMenu = (props: HeaderSearchBarProps) => {
  const { translation } = useTranslationText();
  const navigate = useNavigate();
  // const { logout } = useUserApi();
  const { appTheme } = useAppSetting();
  const [confirmLogout, setConfirmLogout] = React.useState(false);

  let isCms = false;
  const textColor = appTheme.header.text;

  if (location.pathname.includes('/cms') || location.pathname.includes('/user')) {
    isCms = true;
  }

  return (
    <HorizontalView alignContent={'centerRight'} flexWrap={'nowrap'}>
      <HorizontalView display={!props.isSessionLoaded || props.auth?.id ? 'none' : 'flex'}>
        <Button
          fontWeight={500}
          fontSize={'20px'}
          title={translation('mobileSideBar.register')}
          color={textColor}
          background={AppColors.TRANSPARENT}
          onClick={() => navigate('/register')}
        />
      </HorizontalView>
      <HorizontalView display={!props.isSessionLoaded || props.auth?.id ? 'none' : 'flex'}>
        <Button
          fontWeight={500}
          fontSize={'20px'}
          title={translation('mobileSideBar.login')}
          color={textColor}
          background={AppColors.TRANSPARENT}
          onClick={() => navigate('/login')}
        />
      </HorizontalView>
      <HorizontalView display={!props.isSessionLoaded || !props.auth?.id ? 'none' : 'flex'}>
        <Button
          preixIcon={'user'}
          fontWeight={500}
          fontSize={'25px'}
          color={textColor}
          background={AppColors.TRANSPARENT}
          onClick={() => {
            if (props.auth?.role === 'ADMIN') {
              navigate(`/cms/users/${props.auth.id}`);
            } else {
              navigate('/user/my-profile');
            }
          }}
        />
      </HorizontalView>

      {props.auth?.id && (
        <HorizontalView>
          <HorizontalView height={'30px'} width={'1px'} background={textColor} marginLeft={'5px'} marginRight={'5px'} />
          <Button
            preixIcon={'log-out'}
            fontSize={isCms ? '15px' : '25px'}
            color={textColor}
            background={AppColors.TRANSPARENT}
            onClick={() => {
              setConfirmLogout(true);
            }}
          />
        </HorizontalView>
      )}
      <HorizontalView height={'30px'} width={'1px'} background={textColor} marginLeft={'5px'} marginRight={'15px'} />

      <ConfirmModal
        open={confirmLogout}
        title={'modals.confirmLogout.title'}
        cancelTitle={'modals.confirmLogout.close'}
        confirmTitle={'modals.confirmLogout.confirm'}
        onClose={() => setConfirmLogout(false)}
        onConfirm={async () => {
          setConfirmLogout(false);
          // await logout.logoutUser();
          navigate('/');
        }}
      />
    </HorizontalView>
  );
};

export const WebHeaderView = (props: HeaderSearchBarProps) => {
  const { translation, getLanguage } = useTranslationText();
  const navigate = useNavigate();
  const location = useLocation();
  const { session } = useSession();
  const { windowDimensions } = useWindowDimensions();
  const { appTheme, appLayout } = useAppSetting();
  const publicMenus = getLeftMenus(translation);

  if (props.isMobile) {
    return <HorizontalView display={'none'} />;
  }

  let isCms = false;

  if (location.pathname.includes('/cms') || location.pathname.includes('/user')) {
    if (!location.pathname.includes('/user/exam')) {
      isCms = true;
    }
  }

  const isShowTopMenu = () => {
    return (
      location.pathname.includes('/user/') &&
      !location.pathname.includes('cms') &&
      !location.pathname.includes('/user/exam/') &&
      !location.pathname.includes('/user/lecture/')
    );
  };

  return (
    <HorizontalView
      fullWidthHeight
      alignContent={'centerBetween'}
      zIndex={AppConst.zIndex.header + 500}
      overflow={'hidden'}
      paddingLeft={'20px'}
      paddingRight={'20px'}
      flexWrap={'nowrap'}
    >
      <HorizontalView alignContent={'centerBetween'}>
        {!isCms && (
          <Button
            marginBottom={'3px'}
            fontWeight={500}
            fontSize={'30px'}
            color={appTheme.header.text}
            title={'Vietnhatnavi'}
            background={AppColors.TRANSPARENT}
            onClick={() => navigate('/')}
          />
        )}
        <HorizontalView>
          {isShowTopMenu() && (
            <HorizontalView
              paddingLeft={location.pathname.includes('/user/lecture') ? '0px' : appLayout.sidebar.minWidth}
            >
              {publicMenus.map((menu, i) => {
                return (
                  <HorizontalView margin={'5px'} key={i} width={windowDimensions.width <= 1100 ? '50px' : 'auto'}>
                    <NaviDropdown
                      {...menu}
                      label={windowDimensions.width <= 1100 ? '' : menu.label}
                      color={'dark'}
                      size={'13px'}
                      isHover={true}
                      onClick={(menu) => {
                        navigate(menu.url!);
                      }}
                    />
                  </HorizontalView>
                );
              })}
            </HorizontalView>
          )}
          <MenuWrapper isCMS={props.isCMS || location.pathname.includes('/user')}>
            <HorizontalView width={'100%'} alignContent={'center'}>
              <HorizontalView width="auto" alignContent={'centerAround'} flexWrap={'wrap'}>
                {!isCms &&
                  publicMenus.map((menu, i) => {
                    const isSelect = menu.url ? location.pathname.includes(menu.url) : false;
                    const hideLabel = () => {
                      if (getLanguage() === 'ja') {
                        return windowDimensions.width <= 1100;
                      } else {
                        if (session.isAuthenticated) {
                          return windowDimensions.width <= 1100;
                        }
                        return windowDimensions.width <= 1250;
                      }
                    };

                    return (
                      <HorizontalView margin={'5px'} key={i} width={hideLabel() ? '50px' : 'auto'}>
                        <NaviDropdown
                          {...menu}
                          label={hideLabel() ? '' : menu.label}
                          color={appTheme.header.text}
                          isHover={true}
                          isBolder={isSelect}
                          onClick={(menu) => {
                            navigate(menu.url!);
                          }}
                        />
                      </HorizontalView>
                    );
                  })}
              </HorizontalView>
            </HorizontalView>
          </MenuWrapper>
        </HorizontalView>
      </HorizontalView>
      <RightMenu {...props} />
    </HorizontalView>
  );
};
