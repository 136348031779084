import { AppColors } from 'helpers';
import { useTranslationText } from 'locale';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSetting } from 'helpers/useAppSetting';
import { IArticle } from 'models/Article';
import { Text } from 'modules/common/vendors/Text';
import { HorizontalView, VerticalView, Div } from 'modules/common/vendors/Wrapper';
import { NewsCard } from 'modules/user-interface/article/components/parts/NewsCard';
import { ArticleCategory } from 'helpers/const';

type HomeArticleProps = {
  news: IArticle[];
  color?: string;
  background?: string;
};

type TabProps = {
  title: string;
  isSelected: boolean;
  textSize: string;
  onClick(): void;
};

const NewsTab = (props: TabProps) => {
  const [isHovered, setHover] = useState(false);
  const background = props.isSelected || isHovered ? 'black' : 'white';
  const textColor = props.isSelected || isHovered ? 'white' : 'black';
  return (
    <Div
      width="33%"
      maxWidth="250px"
      height="60px"
      background={background}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={props.onClick}
      border="1px solid #ddd"
      transition="background 0.5s"
      alignContent={'center'}
    >
      <Text fontSize={props.textSize} color={textColor}>
        {props.title}
      </Text>
    </Div>
  );
};

export const HomeNewsPreview = (props: HomeArticleProps) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(ArticleCategory.Notification);
  const { appLayout, appSize } = useAppSetting();
  const [hoverOpacity, setHover] = useState(1);
  const isMobile = appLayout.type === 'mobile' || appLayout.type === 'tablet';
  const { translation } = useTranslationText();

  return (
    <VerticalView fullWidth maxWidth="1200px" paddingTop="30px" paddingBottom="50px" alignContent="topCenter">
      <VerticalView marginBottom={isMobile ? '30px' : '50px'}>
        <Text fontSize={appSize.h1}>{translation('public.home.news.header')}</Text>
        <Text fontSize={appSize.h4}>{translation('public.home.news.subHeader')}</Text>
      </VerticalView>
      <VerticalView fullWidth minHeight="270px">
        <HorizontalView fullWidth alignContent="centerLeft">
          <NewsTab
            title={translation('public.home.news.newsTab1')}
            isSelected={selectedTab === ArticleCategory.Notification}
            textSize={appSize.h3}
            onClick={() => setSelectedTab(ArticleCategory.Notification)}
          />
          <NewsTab
            title={translation('public.home.news.newsTab2')}
            isSelected={selectedTab === ArticleCategory.News}
            textSize={appSize.h3}
            onClick={() => setSelectedTab(ArticleCategory.News)}
          />
          <NewsTab
            title={translation('public.home.news.newsTab3')}
            isSelected={selectedTab === ArticleCategory.Information}
            textSize={appSize.h3}
            onClick={() => setSelectedTab(ArticleCategory.Information)}
          />
        </HorizontalView>
        {props.news && (
          <VerticalView
            width="100%"
            overflow="scroll"
            paddingLeft={isMobile ? '10px' : '0'}
            paddingRight={isMobile ? '10px' : '0'}
          >
            {props.news.map((item, index) => {
              if (item.category === selectedTab) {
                return (
                  <NewsCard
                    key={index}
                    align={'left'}
                    time={item?.createdTime}
                    title={item?.title || ''}
                    image={item?.thumbnail || ''}
                    description={item?.description || ''}
                    onClick={() => {
                      navigate(`/articles/${item.id}`);
                    }}
                  />
                );
              }
            })}
          </VerticalView>
        )}
      </VerticalView>
      <Div
        marginTop="30px"
        minWidth={isMobile ? '180px' : '240px'}
        height={isMobile ? '45px' : '60px'}
        background={AppColors.RED_PRIMARY}
        opacity={hoverOpacity}
        transition="opacity 0.5s"
        onMouseEnter={() => setHover(0.8)}
        onMouseLeave={() => setHover(1)}
        alignContent={'center'}
        onClick={() => {
          navigate('/articles');
        }}
      >
        <Text fontSize={appSize.md} color={AppColors.WHITE} fontWeight="bold">
          {translation('public.home.news.seeMore')}
        </Text>
      </Div>
    </VerticalView>
  );
};
