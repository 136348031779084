import React from 'react';
import { Routes, Route } from 'react-router';
import PrivacyContainer from '../containers';

export const PrivacyRouter = () => {
  return (
    <Routes>
      <Route index element={<PrivacyContainer />} />
    </Routes>
  );
};
