import React from 'react';
import { Div, VerticalView } from 'modules/common/vendors/Wrapper';
import { AppColors } from 'helpers';
import styled from 'styled-components';
import { Text } from 'modules/common/vendors/Text';
import { Image } from 'modules/common/vendors/Image';
import { useAppSetting } from 'helpers/useAppSetting';

const HeaderWrapper = styled.div`
  border-bottom: 5px solid ${AppColors.GRAY_LIGHT};
`;

const Blur = styled.div<{ alpha: number }>`
  ${(props) => ` background: rgba(1, 1, 1, ${props.alpha});`}
  backdrop-filter: blur(8px); // This be the blur
  height: 100%;
  width: 100%;
  position: absolute;
`;

type BannerPreviewProps = {
  title: string;
  description: string;
  banner: string;
  blurValue?: number;
  height?: string;
  children?: React.ReactNode;
};

export const BannerPreview = (props: BannerPreviewProps) => {
  const { appSize, appLayout } = useAppSetting();

  return (
    <Div
      fullWidth
      position={'relative'}
      height={appLayout.isMobile ? '400px' : '500px'}
      alignContent={'center'}
      paddingBottom="100px"
    >
      <Div position={'absolute'} fullWidthHeight overflow={'hidden'} zIndex={1}>
        <Image src={process.env.PUBLIC_URL + props.banner} width={'100%'} height={'100%'} objectFit={'cover'} />
        <Blur alpha={(props.blurValue || 10) / 100} />
      </Div>

      <VerticalView fullWidthHeight alignContent={'center'} zIndex={2} padding={'20px'}>
        {props.title.length > 0 && (
          <HeaderWrapper>
            <Text color={AppColors.WHITE} fontSize={appSize.h1} textAlign={'center'}>
              {props.title}
            </Text>
          </HeaderWrapper>
        )}

        <Text color={AppColors.WHITE} fontSize={appSize.h3} marginTop={'15px'} textAlign={'center'}>
          {props.description}
        </Text>
        {props.children}
      </VerticalView>
    </Div>
  );
};
