import BusinessMatchingContainer from 'modules/user-interface/bussiness/containers';
import React from 'react';
import { Routes, Route } from 'react-router';
import ServiceDetailsContainer from '../containers/details';
import ServiceContainer from '../containers/list';

export const ServicesRouter = () => {
  return (
    <Routes>
      <Route index element={<ServiceContainer />} />
      <Route path="/:articleId" element={<ServiceDetailsContainer />} />
      <Route path="/business-matching" element={<BusinessMatchingContainer />} />
    </Routes>
  );
};
