import React, { useState } from 'react';
import styled from 'styled-components';
import { VerticalView } from 'modules/common/vendors/CommonWrappers';
import { useTranslationText } from 'locale';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { BannerPreview } from 'modules/common/components/Banner';
import { FlatList } from 'modules/common/vendors/FlatList';
import { Div, HorizontalView } from 'modules/common/vendors/Wrapper';
import { Text } from 'modules/common/vendors/Text';
import { useAppSetting } from 'helpers/useAppSetting';
import { ICompany } from 'models/Company';
import { Image } from 'modules/common/vendors/Image';
import { Button } from 'modules/common/vendors/Button';
import { isMobile } from 'react-device-detect';
import { Icon } from 'modules/common/vendors/Icon';

const ShadowBox = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-top: -150px;
  box-shadow: 0px 0px 6px #8f8f8f;
  z-index: 1;
  background: white;
`;

export const TokumaComponent = () => {
  const { translation } = useTranslationText();
  const { appLayout } = useAppSetting();
  const logos = [
    'images/companies/company1.png',
    'images/companies/company2.png',
    'images/companies/company3.png',
    'images/companies/company4.png',
    'images/companies/company5.png',
    'images/companies/company6.png',
    'images/companies/company7.png',
    'images/companies/company8.png',
    'images/companies/company9.png',
    'images/companies/company10.png',
  ];
  const national = [
    {
      flag: 'images/flags/vietnam.png',
      name: 'ベトナム',
      num: '55%',
    },
    {
      flag: 'images/flags/myanmar.png',
      name: 'ミャンマー',
      num: '8%',
    },
    {
      flag: 'images/flags/nepal.png',
      name: 'ネパール',
      num: '3%',
    },
    {
      flag: 'images/flags/sri-lanka.png',
      name: 'スリランカ',
      num: '5%',
    },
    {
      flag: 'images/flags/indonesia.png',
      name: 'インドネシア',
      num: '3%',
    },
    {
      flag: 'images/flags/thailand.png',
      name: 'タイ',
      num: '2%',
    },
    {
      flag: 'images/flags/philippines.png',
      name: 'フィリピン',
      num: '13%',
    },
    {
      flag: 'images/flags/earth-globe.png',
      name: 'その他',
      num: '13%',
    },
  ];

  return (
    <BaseComponent>
      <VerticalView fullWidth>
        <HorizontalView
          fullWidth
          height={'600px'}
          style={{ backgroundImage: 'linear-gradient(0.25turn, #5987CE, #ebf8e1)' }}
        >
          <VerticalView
            width={appLayout.isMobile ? '100%' : '50%'}
            height={appLayout.isMobile ? '50%' : '100%'}
            alignContent="center"
          >
            <Text fontSize={appLayout.isMobile ? '30px' : '50px'} fontWeight={900} color="#fff">
              {translation('tokuma.title')}
            </Text>
            <Text
              fontSize={appLayout.isMobile ? '18px' : '32px'}
              borderRadius="7px"
              padding="0 60px"
              background="#fff"
              fontWeight={700}
              style={{ boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
              marginTop="40px"
              color="#6392D8"
            >
              {translation('tokuma.title2')}
            </Text>
            <Text
              fontSize={appLayout.isMobile ? '18px' : '42px'}
              borderRadius="7px"
              padding="0 60px"
              fontWeight={700}
              marginTop="40px"
              color="#fff"
            >
              {translation('tokuma.title3')}
            </Text>
          </VerticalView>
          <VerticalView width={appLayout.isMobile ? '100%' : '50%'} height={appLayout.isMobile ? '50%' : '100%'}>
            <Image width="100%" height="100%" objectFit="cover" src="images/tokumatop.jpg" />
          </VerticalView>
        </HorizontalView>
        <HorizontalView
          fullWidth
          height={appLayout.isMobile ? 'auto' : '500px'}
          background="#10142F"
          alignContent="topCenter"
          flexWrap="wrap"
        >
          <VerticalView
            width={appLayout.isMobile ? '100%' : '50%'}
            height={appLayout.isMobile ? '180px' : '100%'}
            alignContent="center"
          >
            <Text fontSize={appLayout.isMobile ? '16px' : '30px'} color="#fff" fontWeight={700}>
              {translation('tokuma.feature.title1')}
            </Text>
            <Text
              fontSize={appLayout.isMobile ? '16px' : '30px'}
              color="#fff"
              fontWeight={700}
              marginTop={appLayout.isMobile ? '10px' : '25px'}
            >
              {translation('tokuma.feature.title2')}
            </Text>
            <Text
              fontSize={appLayout.isMobile ? '16px' : '30px'}
              color="#fff"
              fontWeight={700}
              marginTop={appLayout.isMobile ? '10px' : '25px'}
            >
              {translation('tokuma.feature.title3')}
            </Text>
          </VerticalView>
          <VerticalView
            width={appLayout.isMobile ? '100%' : '50%'}
            height={appLayout.isMobile ? 'auto' : '100%'}
            alignContent="center"
            padding={appLayout.isMobile ? '10px 40px' : '60px'}
          >
            <Image src="images/tokumahome.jpg" width="100%" height="100%" objectFit="contain" />
          </VerticalView>
        </HorizontalView>
        <VerticalView fullWidth>
          <Text fontSize={appLayout.isMobile ? '25px' : '40px'} fontWeight={700} color="#0B96D0" marginTop="40px">
            {translation('tokuma.usedCompany')}
          </Text>
          <HorizontalView
            maxWidth="1200px"
            padding={appLayout.isMobile ? '0 20px' : '0'}
            alignContent="topCenter"
            fullWidth
          >
            {logos.map((e, i) => {
              return (
                <HorizontalView
                  key={i}
                  width={appLayout.isMobile ? '25%' : '20%'}
                  height={appLayout.isMobile ? '100px' : '140px'}
                  marginTop="10px"
                >
                  <Image width="100%" height="100%" src={e} />
                </HorizontalView>
              );
            })}
          </HorizontalView>
        </VerticalView>
        {!appLayout.isMobile && (
          <VerticalView fullWidth alignContent="topCenter">
            <Text fontSize={appLayout.isMobile ? '25px' : '40px'} fontWeight={700} color="#0B96D0" marginTop="40px">
              {translation('tokuma.service')}
            </Text>
            <HorizontalView fullWidth height="400px">
              <HorizontalView marginTop="30px" width="65%" height="100%" position="relative">
                <HorizontalView
                  position={'absolute'}
                  padding="15px"
                  background="#ffffffaa"
                  height="300px"
                  right="-45%"
                  top="50px"
                  alignContent="topLeft"
                  width="700px"
                >
                  <VerticalView width="50%" height="100%" alignContent="topLeft" background="#fff" padding="10px">
                    <Text fontSize="35px" fontWeight={700} color="#EBAB43">
                      {'1' + '-' + translation('tokuma.service1.title')}
                    </Text>
                    <Text fontSize="20px" fontWeight={500} whiteSpace="pre-line">
                      {translation('tokuma.service1.des')}
                    </Text>
                  </VerticalView>
                  <VerticalView width="50%" height="100%">
                    <Image padding="10px" width="100%" height="100%" src="images/demo.png" />
                  </VerticalView>
                </HorizontalView>

                <Image width="100%" height="100%" objectFit="cover" src="images/service1.jpg" />
              </HorizontalView>
            </HorizontalView>
            <HorizontalView marginTop="30px" fullWidth height="400px" alignContent="centerRight">
              <HorizontalView marginTop="30px" width="65%" height="100%" position="relative">
                <VerticalView
                  position="absolute"
                  padding="15px"
                  background="#ffffffaa"
                  height="300px"
                  left="-45%"
                  top="50px"
                  alignContent="topLeft"
                  width="700px"
                >
                  <VerticalView width="40%" height="100%">
                    <Image padding="10px" width="100%" height="100%" src="images/demo2.png" />
                  </VerticalView>
                  <VerticalView background="#fff" padding="10px" alignContent="centerLeft" width="60%" height="100%">
                    <Text fontSize="35px" fontWeight={700} color="#EBAB43">
                      {'2' + '-' + translation('tokuma.service2.title')}
                    </Text>
                    <Text fontSize="20px" fontWeight={500} whiteSpace="pre-line">
                      {translation('tokuma.service2.des')}
                    </Text>
                  </VerticalView>
                </VerticalView>
                <Image width="100%" height="100%" objectFit="cover" src="images/service2.jpg" />
              </HorizontalView>
            </HorizontalView>
            <HorizontalView marginTop="60px" fullWidth height="400px">
              <HorizontalView width="65%" height="100%" position="relative">
                <HorizontalView
                  position={'absolute'}
                  padding="15px"
                  background="#ffffffaa"
                  height="300px"
                  right="-45%"
                  top="50px"
                  alignContent="topLeft"
                  width="700px"
                >
                  <VerticalView width="50%" height="100%" alignContent="topLeft" background="#fff" padding="10px">
                    <Text fontSize="35px" fontWeight={700} color="#EBAB43">
                      {'3' + '-' + translation('tokuma.service3.title')}
                    </Text>
                    <Text fontSize="20px" fontWeight={500} whiteSpace="pre-line">
                      {translation('tokuma.service3.des')}
                    </Text>
                  </VerticalView>
                  <VerticalView width="50%" height="100%">
                    <Image padding="10px" width="100%" height="100%" src="images/demo3.png" />
                  </VerticalView>
                </HorizontalView>

                <Image width="100%" height="100%" objectFit="cover" src="images/service3.jpg" />
              </HorizontalView>
            </HorizontalView>
          </VerticalView>
        )}
        {appLayout.isMobile && (
          <VerticalView fullWidth alignContent="topCenter">
            <Text fontSize={'25px'} fontWeight={800} color="#0B96D0" marginTop="40px">
              {translation('business.dokodemo')}
            </Text>
            <VerticalView fullWidth padding="20px">
              <Div fullWidth height="40%">
                <Image width="100%" height="100%" objectFit="cover" src="images/service1.jpg" />
              </Div>
              <HorizontalView fullWidth height="60%">
                <VerticalView height="100%" width="60%">
                  <Text fontSize="20px" fontWeight={700}>
                    {'1' + '-' + translation('tokuma.service1.title')}
                  </Text>
                  <Text fontSize="15px" fontWeight={500} whiteSpace="pre-line">
                    {translation('tokuma.service1.des')}
                  </Text>
                </VerticalView>
                <HorizontalView width="40%" padding="10px">
                  <Image width="100%" height="100%" src="images/demo.png" />
                </HorizontalView>
              </HorizontalView>
            </VerticalView>
            <VerticalView fullWidth padding="20px">
              <Div fullWidth height="40%">
                <Image width="100%" height="100%" objectFit="cover" src="images/service2.jpg" />
              </Div>
              <HorizontalView fullWidth height="60%">
                <HorizontalView width="40%" padding="10px">
                  <Image width="100%" height="100%" src="images/demo2.png" />
                </HorizontalView>
                <VerticalView width="60%" height="100%">
                  <Text fontSize="20px" fontWeight={700}>
                    {'2' + '-' + translation('tokuma.service2.title')}
                  </Text>
                  <Text fontSize="15px" fontWeight={500} whiteSpace="pre-line">
                    {translation('tokuma.service2.des')}
                  </Text>
                </VerticalView>
              </HorizontalView>
            </VerticalView>
            <VerticalView fullWidth padding="20px">
              <Div fullWidth height="40%">
                <Image width="100%" height="100%" objectFit="cover" src="images/service3.jpg" />
              </Div>
              <HorizontalView fullWidth height="60%">
                <VerticalView width="60%" height="100%">
                  <Text fontSize="20px" fontWeight={700}>
                    {'3' + '-' + translation('tokuma.service3.title')}
                  </Text>
                  <Text fontSize="15px" fontWeight={500} whiteSpace="pre-line">
                    {translation('tokuma.service3.des')}
                  </Text>
                </VerticalView>
                <HorizontalView width="40%" padding="10px">
                  <Image width="100%" height="100%" src="images/demo3.png" />
                </HorizontalView>
              </HorizontalView>
            </VerticalView>
          </VerticalView>
        )}
        <VerticalView fullWidth>
          <Text fontSize={appLayout.isMobile ? '25px' : '40px'} fontWeight={700} color="#0B96D0" marginTop="40px">
            {translation('tokuma.servicepower')}
          </Text>
          <VerticalView fullWidth maxWidth="1200px">
            <Text
              padding={appLayout.isMobile ? '0 20px' : '0'}
              fontSize={appLayout.isMobile ? '18px' : '25px'}
              color="#1C2AC9"
              fontWeight={600}
              width="100%"
              textAlign="left"
            >
              {translation('tokuma.services.1.title')}
            </Text>
            <VerticalView fullWidth padding="20px">
              <Text fontSize={appLayout.isMobile ? '14px' : '16px'} width="100%" textAlign="left" whiteSpace="pre-line">
                {translation('tokuma.services.1.des')}
              </Text>
              <VerticalView marginTop="20px" fullWidth background="#F4F4F4" padding="15px">
                <HorizontalView fullWidth padding="0 40px" alignContent="centerBetween">
                  <VerticalView
                    backgroundImage="linear-gradient(140deg, #72D6BF 55%, #95DFCF 45%)"
                    width="250px"
                    height="250px"
                    borderRadius="150px"
                    alignContent="center"
                  >
                    <Text fontSize="24px" color="#fff" fontWeight={600}>
                      {translation('tokuma.services.free1')}
                    </Text>
                    <HorizontalView alignContent="bottomCenter">
                      <Text fontSize="50px" overflow={'hidden'} color="#fff" fontWeight={600} lineHeight="50px">
                        {'0'}
                      </Text>
                      <Text fontSize="20px" color="#fff" fontWeight={600}>
                        {'円'}
                      </Text>
                    </HorizontalView>
                  </VerticalView>
                  <VerticalView
                    backgroundImage="linear-gradient(140deg, #6CBFDE 55%, #8FCEE7 45%)"
                    marginTop={appLayout.isMobile ? '20px' : '0'}
                    width="250px"
                    height="250px"
                    borderRadius="150px"
                    alignContent="center"
                  >
                    <Text fontSize="24px" color="#fff" fontWeight={600}>
                      {translation('tokuma.services.free2')}
                    </Text>
                    <HorizontalView alignContent="bottomCenter">
                      <Text overflow={'hidden'} fontSize="50px" color="#fff" fontWeight={600} lineHeight="50px">
                        {'0'}
                      </Text>
                      <Text fontSize="20px" color="#fff" fontWeight={600}>
                        {'円'}
                      </Text>
                    </HorizontalView>
                  </VerticalView>
                  <VerticalView
                    backgroundImage="linear-gradient(140deg, #4A77C5 55%, #7598D3 45%)"
                    width="250px"
                    marginTop={appLayout.isMobile ? '20px' : '0'}
                    height="250px"
                    borderRadius="150px"
                    alignContent="center"
                  >
                    <Text fontSize="24px" color="#fff" fontWeight={600}>
                      {translation('tokuma.services.free3')}
                    </Text>
                    <HorizontalView alignContent="bottomCenter">
                      <Text fontSize="50px" overflow={'hidden'} color="#fff" fontWeight={600} lineHeight="50px">
                        {'0'}
                      </Text>
                      <Text fontSize="20px" color="#fff" fontWeight={600}>
                        {'円'}
                      </Text>
                    </HorizontalView>
                  </VerticalView>
                </HorizontalView>
                <HorizontalView
                  marginTop="40px"
                  border="1px solid blue"
                  borderRadius="8px"
                  background="#fff"
                  fullWidth
                  alignContent="topLeft"
                  padding="20px 40px"
                >
                  <Text
                    marginTop="10px"
                    marginRight="20px"
                    fontWeight={700}
                    height="20px"
                    textAlign="center"
                    width="20px"
                    lineHeight="20px"
                    fontSize="18px"
                    borderRadius="20px"
                    background="#4872BB"
                    color="#fff"
                  >
                    ?
                  </Text>

                  <VerticalView alignContent="topLeft">
                    <Text fontSize="18px" fontWeight={600}>
                      {translation('tokuma.services.des')}
                    </Text>
                    <Text whiteSpace="pre-line" fontSize="18px">
                      {translation('tokuma.services.des2')}
                    </Text>
                  </VerticalView>
                </HorizontalView>
              </VerticalView>
            </VerticalView>
          </VerticalView>
          <VerticalView fullWidth maxWidth="1200px">
            <Text
              fontSize="25px"
              padding={appLayout.isMobile ? '0 20px' : '0'}
              color="#1C2AC9"
              fontWeight={600}
              width="100%"
              textAlign="left"
            >
              {translation('tokuma.services.2.title')}
            </Text>
            <HorizontalView fullWidth flexWrap="wrap" padding={appLayout.isMobile ? '0 20px' : '0'} marginBottom="30px">
              {national.map((e, i) => {
                return (
                  <HorizontalView
                    key={i}
                    width={appLayout.isMobile ? '80%' : '33.3%'}
                    height={appLayout.isMobile ? '60px' : '80px'}
                    marginTop="15px"
                  >
                    <Image src={e.flag} height="100%" />
                    <Text marginLeft="20px" fontSize={appLayout.isMobile ? '22px' : '30px'} fontWeight={600}>
                      {e.name}
                    </Text>
                    <Text
                      fontSize={appLayout.isMobile ? '22px' : '30px'}
                      marginLeft="30px"
                      color="red"
                      fontWeight={600}
                    >
                      {e.num}
                    </Text>
                  </HorizontalView>
                );
              })}
            </HorizontalView>
          </VerticalView>
          <VerticalView fullWidth maxWidth="1200px">
            <Text
              fontSize="25px"
              padding={appLayout.isMobile ? '0 20px' : '0'}
              color="#1C2AC9"
              fontWeight={600}
              width="100%"
              textAlign="left"
            >
              {translation('tokuma.services.3.title')}
            </Text>
            <Text fontSize="20px" width="100%" textAlign="left" whiteSpace="pre-line" padding="0 20px">
              {translation('tokuma.services.3.des')}
            </Text>
          </VerticalView>
          <Button
            onClick={() => {
              window.open('https://tokutei-gino.com/');
            }}
            border="2px solid blue"
            height="50px"
            padding="0 40px"
            marginTop="50px"
            fontSize="25px"
            fontWeight={700}
            color="blue"
            borderRadius="10px"
            title="tokuma.services.button"
          />
        </VerticalView>
      </VerticalView>
    </BaseComponent>
  );
};
