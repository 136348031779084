import React from 'react';
import styled from 'styled-components';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';
import lang from 'suneditor/src/lang';
import { mediaCenter } from './mediaCenter';
import { vi } from './lang/vi';
import { MediaModal } from 'modules/common/components/Modals/MediaModal';
import { Text } from '../Text';
import { useTranslationText } from 'locale';

const ArticleEditorWrapper = styled.div``;
const ArticleEditorPreviewWrapper = styled.div`
  width: 100%;
  .sun-editor {
    border: 0px solid transparent;
    .se-resizing-bar {
      display: none;
    }
    .se-toolbar {
      display: none;
    }
  }
`;

const userButtonList = [
  ['undo', 'redo'],
  ['font', 'fontSize', 'formatBlock'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['removeFormat'],
  '/',
  ['fontColor', 'hiliteColor'],
  ['outdent', 'indent'],
  ['align', 'horizontalRule', 'list', 'table'],
  ['fullScreen'],
  ['image', 'link', 'audio'],
];

const adminButtonList = [
  ['undo', 'redo'],
  ['font', 'fontSize', 'formatBlock'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['removeFormat'],
  '/',
  ['fontColor', 'hiliteColor'],
  ['outdent', 'indent'],
  ['align', 'horizontalRule', 'list', 'table'],
  ['fullScreen'],
  ['image', 'audio'],
];

const editSyllabusButtonBar = [
  ['undo', 'redo'],
  ['font', 'fontSize', 'formatBlock'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['removeFormat'],
  '/',
  ['fontColor', 'hiliteColor'],
  ['outdent', 'indent'],
  ['align', 'horizontalRule', 'list'],
  ['fullScreen'],
  ['image', 'audio'],
];

const videoButton = ['video'];

export type IMediaEditor = {
  id: string;
  caption: string;
  url: string;
};

export type ArticleEditorProps = {
  allowVideo?: boolean;
  type?: 'EditSyllabus';
  isAdmin?: boolean;
  name: string;
  value?: string;
  setContents?: string;
  height?: string;
  onFocus?: () => void;
  onBlur?: (editorContents: string) => void;
  onChange?: (content: string) => void;
};

function ResizeImage(files: File[], uploadHandler: Function) {
  const uploadFile = files[0];
  const img = document.createElement('img');
  const canvas = document.createElement('canvas');
  const reader = new FileReader();

  reader.onload = function (e: ProgressEvent<FileReader>) {
    if (img && e && e.target && e.target.result) {
      img.src = e.target.result + '';
      img.onload = function () {
        let ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);

          const MAX_WIDTH = 320;
          const MAX_HEIGHT = 500;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
              function (blob: Blob | null) {
                if (blob) {
                  uploadHandler([new File([blob], uploadFile.name)]);
                }
              },
              uploadFile.type,
              1,
            );
          }
        }
      };
    }
  };

  reader.readAsDataURL(uploadFile);
}

export const ArticleEditor = React.memo((props: ArticleEditorProps) => {
  const btnList = props.isAdmin ? adminButtonList : userButtonList;
  const [isOpenMedia, setOpenMedia] = React.useState(false);
  const [editor, setEditor] = React.useState<any>(null);

  React.useEffect(() => {
    const imageElement = document.querySelector('[data-command="image"]') as HTMLElement;
    if (imageElement) {
      imageElement.style.visibility = 'hidden'; // = "width:0px,opacity:0;"
      imageElement.style.width = '0px';
    }
  }, []);

  const onImageUploadBefore = (files: File[], info: object, uploadHandler: Function) => {
    try {
      ResizeImage(files, uploadHandler);
    } catch (err) {
      // uploadHandler(err.toString());
    }
  };

  const weblang = localStorage.getItem('QHC_LANG') || 'ja';

  const onOpenMedia = (editor: any) => {
    setEditor(editor);
    setOpenMedia(true);
  };

  const buttons = React.useMemo(() => {
    switch (props.type) {
      case 'EditSyllabus':
        return [...editSyllabusButtonBar, ['mediaCenter']];
      default:
        return [...btnList, ['mediaCenter']];
    }
  }, [props.type]);

  return (
    <ArticleEditorWrapper>
      <SunEditor
        setDefaultStyle="font-size: 20px;"
        name={props.name}
        setOptions={{
          lang: weblang === 'ja' ? lang.ja : vi,
          customPlugins: [mediaCenter([], window, document, onOpenMedia)],
          buttonList: props.allowVideo ? [...buttons, videoButton] : buttons,
          imageUrlInput: true,
          imageFileInput: true,
        }}
        setContents={props.setContents || ''}
        height={'auto'}
        onFocus={props.onFocus}
        onBlur={(event: FocusEvent, editorContents: string) => {
          if (props.onBlur) {
            props.onBlur(editorContents);
          }
        }}
        onChange={(content) => {
          if (props.onChange) {
            props.onChange(content);
          }
        }}
        onImageUploadBefore={onImageUploadBefore}
      />
      <>
        {isOpenMedia && (
          <MediaModal
            open
            accept={'image'}
            onClose={() => setOpenMedia(false)}
            onSelect={(url) => {
              if (editor && editor.plugins && editor.plugins.image && editor.plugins.image.create_image) {
                editor.plugins.image.create_image.call(editor, url, '', false, 0, 0, 'none', null);
              }
              setOpenMedia(false);
            }}
          />
        )}
      </>
    </ArticleEditorWrapper>
  );
});

ArticleEditor.displayName = 'ArticleEditor';

export const ArticleEditorPreview = React.memo((props: { height?: string; setContents: string }) => {
  return (
    <ArticleEditorPreviewWrapper>
      <SunEditor
        disable
        showToolbar={false}
        setContents={props.setContents || ''}
        height={props.height || 'auto'}
        setDefaultStyle={'none'}
      />
    </ArticleEditorPreviewWrapper>
  );
});

ArticleEditorPreview.displayName = 'ArticleEditorPreview';

const InputWrapper = styled.div`
  width: 100%;
  .ant-select {
    border-color: #d9d9d9;
    z-index: 5000;
  }
  .ant-select-selector {
    border-color: #d9d9d9;
    z-index: 5000;
  }
  .ant-select-open {
    z-index: 5000;
  }
`;

const InputText = styled.div`
  margin-bottom: 5px;
  font-weight: 400;
`;

const MustLabel = styled.span`
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 3px;
  color: red;
  margin-left: 2px;
`;

const ErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 10px;
`;

const InputContent = styled.div<{ noBorder?: boolean }>`
  .ant-input {
    ${(props) => (props.noBorder ? 'border:0px;' : '')}
  }
`;

const PreviewViewTextEditor = styled.div<{ height: string }>`
  border: 1px solid rgba(32, 33, 36, 0.28);
  padding: 10px;
  border-style: dashed;
  cursor: text;
  overflow: scroll;
  height: ${(props) => props.height || 'auto'};
  width: 100%;
  &:hover {
    border: 1px solid #00bfff;
  }
`;

type EditorTextInputProps = {
  type?: 'EditSyllabus';
  allowVideo?: boolean;
  isEditing?: boolean;
  hide?: boolean;
  width?: string;
  height?: string;
  minHeight?: string;
  placeholder?: string;
  required?: boolean;
  label?: string;
  defaultValue?: any;
  name: string;
  error?: string;
  style?: React.CSSProperties;
  onPressEnter?: () => void;
  onFocus?: () => void;
  onChange?: (e: string) => void;
  onBlur?: (e: string) => void;
};

export const ArticleEditorInput = (props: EditorTextInputProps) => {
  const { onBlur, onChange, defaultValue } = props;

  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState<string>('');

  const { translation } = useTranslationText();

  React.useEffect(() => {
    setEditing(props.isEditing || false);
  }, [props.isEditing]);

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  if (props.hide) {
    return <></>;
  }

  return (
    <InputWrapper>
      {props.label && (
        <InputText>
          <Text fontWeight={500} fontSize={'14px'}>
            {translation(props.label)} {props.required && <MustLabel>{'(*)'}</MustLabel>}
          </Text>
        </InputText>
      )}
      <InputContent style={props.style}>
        {!editing && (
          <PreviewViewTextEditor
            height={'auto'}
            onClick={(e) => {
              e.preventDefault();
              setEditing(true);
            }}
          >
            {value === '' && <Text color={'lightGray'}>{props.placeholder ? translation(props.placeholder) : ''}</Text>}

            {value !== '' && <ArticleEditorPreview height={'100%'} setContents={props.defaultValue} />}
          </PreviewViewTextEditor>
        )}
        {editing && (
          <ArticleEditor
            name={props.name}
            allowVideo={props.allowVideo}
            type={props.type}
            setContents={props.defaultValue}
            height={props.height}
            onBlur={onBlur}
            onFocus={props.onFocus}
            onChange={(content) => {
              const span = document.createElement('span');
              span.innerHTML = content;
              if (span.textContent?.replaceAll(/ /g, '').length === 0) {
                onChange && onChange('');
              } else {
                onChange && onChange(content);
              }
            }}
          />
        )}

        {props.error && <ErrorMessage>{`※ ${translation(props.error)}`}</ErrorMessage>}
      </InputContent>
    </InputWrapper>
  );
};
