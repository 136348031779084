import { AppColors } from 'helpers';
import React from 'react';
import { IError } from 'models/Models';
import { ContentLoading, Loading } from 'modules/common/vendors/Loading';
import { VerticalView } from 'modules/common/vendors/Wrapper';
import { ErrorCodes } from 'helpers/const';
import NotFoundContainer from 'modules/user-interface/not-found/containers';

interface BaseProps {
  fullLoading?: boolean;
  contentLoading?: boolean;
  error?: IError;
  scrollToTop?: boolean;
  children: React.ReactElement;
}

export const BaseComponent = (props: BaseProps) => {
  const [loadLayout, setLoadLayout] = React.useState(false);

  React.useEffect(() => {
    setLoadLayout(true);
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 300);
    return () => clearTimeout(timeout);
  }, [props.scrollToTop]);

  if (props.error && props.error.code === ErrorCodes.ERROR_CODE_ITEM_NOT_EXIST) {
    return <NotFoundContainer />;
  }

  return (
    <>
      {(props.contentLoading || !loadLayout) && (
        <VerticalView fullWidth alignContent={'center'}>
          <ContentLoading loading height={'100px'} color={AppColors.GRAY_DARK} />
        </VerticalView>
      )}
      {props.fullLoading && <Loading loading />}
      {!(props.contentLoading || !loadLayout || props.fullLoading) && props.children}
    </>
  );
};
