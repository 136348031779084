import React from 'react';
import styled from 'styled-components';
import { VerticalView } from 'modules/common/vendors/CommonWrappers';
import { useTranslationText } from 'locale';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { BannerPreview } from 'modules/common/components/Banner';
import { FlatList } from 'modules/common/vendors/FlatList';
import { AboutCard } from './parts/AboutCard';
import { Div, HorizontalView } from 'modules/common/vendors/Wrapper';
import { Text } from 'modules/common/vendors/Text';
import { useAppSetting } from 'helpers/useAppSetting';
import { ICompany } from 'models/Company';

const ShadowBox = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-top: -150px;
  box-shadow: 0px 0px 6px #8f8f8f;
  z-index: 1;
  background: white;
`;

type AboutProps = {
  loading: boolean;
  company: ICompany;
};

export const AboutComponent = (props: AboutProps) => {
  const { translation } = useTranslationText();
  const { appTheme } = useAppSetting();
  let aboutData: { label: string; value: string }[] = [];
  if (props.company) {
    aboutData = [
      {
        label: '社名',
        value: props.company.name as string,
      },
      {
        label: '設立',
        value: props.company.foundation as string,
      },
      {
        label: '代表者',
        value: props.company.representativeName as string,
      },
      {
        label: '資本金',
        value: props.company.tax as string,
      },
      {
        label: '本社',
        value: `〒${props.company.postalCode || ''} ${props.company.stateOrCityName || ''} ${
          props.company.streetAddress || ''
        } ${props.company.buildingNameAndRoomName || ''}`,
      },
      {
        label: 'メール',
        value: props.company.telephone as string,
      },
      {
        label: '事業内容',
        value: props.company.businessContent as string,
      },
    ];
  }

  return (
    <BaseComponent contentLoading={props.loading}>
      {props.company && (
        <VerticalView width={'100vw'}>
          <BannerPreview
            banner={'/images/company_bg.jpg'}
            title={translation('public.about.header')}
            description={''}
          />
          <ShadowBox>
            <VerticalView width="100%" alignContent="topCenter" marginTop={'30px'} position={'relative'}>
              <HorizontalView paddingBottom={'40px'}>
                <Text color={appTheme.header.text} fontSize={'30px'} fontWeight={'bold'}>
                  {`${props.company.name}`}
                </Text>
              </HorizontalView>
              <Div fullWidth maxWidth="800px" paddingBottom="20px">
                <FlatList
                  direction={'row'}
                  values={aboutData}
                  renderItem={(about: any, index: number) => {
                    return (
                      <AboutCard key={index} icon={about.icon} label={about.label} value={about.value} index={index} />
                    );
                  }}
                />
              </Div>
            </VerticalView>
          </ShadowBox>
          {props.company.locationMapUrl && (
            <VerticalView fullWidth alignContent="topCenter">
              <Div alignContent="center" paddingTop="50px" paddingBottom="50px">
                <Text fontSize="30px" fontWeight="bold">
                  {'アクセス'}
                </Text>
              </Div>
              <Div fullWidth maxWidth="1200px" paddingLeft="10px" paddingRight="10px">
                <iframe
                  src={props.company.locationMapUrl}
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  loading="lazy"
                ></iframe>
              </Div>
            </VerticalView>
          )}
        </VerticalView>
      )}
    </BaseComponent>
  );
};
