import React from 'react';
import { Routes, Route } from 'react-router';
import TokumaContainer from '../containers';

export const TokumaRouter = () => {
  return (
    <Routes>
      <Route index element={<TokumaContainer />} />
    </Routes>
  );
};
