import { CmsRouter } from 'routes/cms.router';
import { UserRouter } from 'routes/user.router';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SessionProvider } from '../modules/common/providers/SessionProvider';

export const AppRoutes = () => {
  return (
    <SessionProvider>
      <Routes>
        <Route path="cms/*" element={<CmsRouter />} />
        <Route path="*" element={<UserRouter />} />
      </Routes>
    </SessionProvider>
  );
};
