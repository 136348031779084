import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAppSetting } from 'helpers/useAppSetting';
import { Footer } from '../modules/common/components/PageFooter/Footer';
import { Header } from '../modules/common/components/Header/Header';
import { BodyContentWrapper } from '../modules/common/vendors/CommonWrappers';
import { ArticleRouter } from 'modules/user-interface/article/router/article.router';
import { AboutRouter } from 'modules/user-interface/about/router/about.router';
import { ContactRouter } from 'modules/user-interface/contact/router/contact.router';
import { HomeRouter } from 'modules/user-interface/home/router/home.router';
import { PrivacyRouter } from 'modules/user-interface/privacy/router/privacy.router';
import { ServicesRouter } from 'modules/user-interface/service/router/services.router';
import { NotFoundRouter } from 'modules/user-interface/not-found/router/notfound.router';
import { LoginRouter } from 'modules/user-interface/login/router/login.router';
import { BusinessMatchingRouter } from 'modules/user-interface/bussiness/router/about.router';
import { TokumaRouter } from 'modules/user-interface/tokuma/router/about.router';

export const UserRouter = () => {
  const { appLayout } = useAppSetting();
  return (
    <div>
      <Header />
      <BodyContentWrapper isMobile={appLayout.isMobile}>
        <Routes>
          <Route index element={<HomeRouter />} />
          <Route path={'login'} element={<LoginRouter />} />
          <Route path={'about'} element={<AboutRouter />} />
          <Route path={'articles/*'} element={<ArticleRouter />} />
          <Route path={'services/*'} element={<ServicesRouter />} />
          <Route path={'contact'} element={<ContactRouter />} />
          <Route path={'privacy'} element={<PrivacyRouter />} />
          <Route path={'business-matching'} element={<BusinessMatchingRouter />} />
          <Route path={'tokuma-service'} element={<TokumaRouter />} />
          <Route path={'*'} element={<NotFoundRouter />} />
        </Routes>
        <Footer />
      </BodyContentWrapper>
    </div>
  );
};
