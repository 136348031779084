import React from 'react';
import { CMSConpanyProfileComponent } from '../../components/company-profile';
import { useGetCompany, useUpdateCompany } from 'api/company/useCompanyApi';
import { ICompany, IUpdateCompanyInput } from 'models/Company';
import { openNotification } from 'modules/common/vendors/Notification';
import { useTranslationText } from 'locale';

export const CMSCompanyProfileContainer = () => {
  const { loading, company, onGetCompany } = useGetCompany();
  const { onUpdateCompany, loading: updateLoading } = useUpdateCompany();
  const { translation } = useTranslationText();

  const update = async (updateData: ICompany) => {
    const update = {
      name: updateData.name,
      logo: updateData.logo,
      description: updateData.description,
      foundation: updateData.foundation,
      tax: updateData.tax,
      representativeName: updateData.representativeName,
      postalCode: updateData.postalCode,
      stateOrCityName: updateData.stateOrCityName,
      streetAddress: updateData.streetAddress,
      buildingNameAndRoomName: updateData.buildingNameAndRoomName,
      telephone: updateData.telephone,
      businessContent: updateData.businessContent,
      locationMapUrl: updateData.locationMapUrl,
    } as IUpdateCompanyInput;

    const result = await onUpdateCompany(update);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('message.update'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.update'),
        description: translation('message.error'),
      });
    }
  };

  React.useEffect(() => {
    onGetCompany();
  }, []);

  return (
    <CMSConpanyProfileComponent
      loading={loading}
      saving={updateLoading}
      company={company}
      onUpdate={(data) => update(data)}
    />
  );
};

export default CMSCompanyProfileContainer;
