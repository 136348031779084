import React from 'react';
import { AppColors } from 'helpers';
import { Button } from 'modules/common/vendors/Button';
import { CardWrapper } from 'modules/common/vendors/CommonWrappers';
import { Input } from 'modules/common/vendors/Input';
import { VerticalView, HorizontalView } from 'modules/common/vendors/Wrapper';
import { useAppSetting } from 'helpers/useAppSetting';
import { Formik, FormikProps } from 'formik';
import { Label } from 'modules/common/vendors/Label';
import { loginValidatorSchema } from '../validator';

type LoginPros = {
  isLoading: boolean;
  handleSubmitForm: (values: LoginFormik) => void;
};

export type LoginFormik = {
  email: string;
  password: string;
};

export const LoginComponent = (props: LoginPros) => {
  const ref = React.useRef<FormikProps<LoginFormik>>(null);
  const { appLayout, appSize } = useAppSetting();

  const isMobile = appLayout.isMobile;

  return (
    <VerticalView fullWidthHeight alignContent={'center'} minHeight="50vh">
      <CardWrapper padding={'0px'} width={isMobile ? '90%' : '450px'} noShadow={true}>
        <VerticalView fullWidth paddingTop={'20px'} background={AppColors.GRAY_BGR}>
          <Label
            fontWeight={600}
            marginTop={'5px'}
            fontSize={appLayout.isMobile ? appSize.h3 : appSize.h2}
            marginBottom={'20px'}
            color={AppColors.DARK}
            label={'public.login.title'}
          />
        </VerticalView>
        <Formik
          innerRef={ref}
          initialValues={{ email: '', password: '' }}
          validationSchema={loginValidatorSchema}
          onSubmit={(values: LoginFormik) => {
            props.handleSubmitForm(values);
          }}
        >
          {({ values, submitCount, errors, handleChange }) => {
            return (
              <VerticalView
                width={'100%'}
                alignContent={'center'}
                paddingTop={'30px'}
                background={AppColors.BODY_BACKGROUND}
                padding={'20px'}
              >
                <HorizontalView fullWidth>
                  <Input
                    disabled={props.isLoading}
                    id={'email'}
                    fontSize={'13px'}
                    value={values.email}
                    placeholder={'public.login.email'}
                    onChange={handleChange}
                    height={'45px'}
                    borderRadius={'5px'}
                    background={AppColors.WHITE}
                    error={submitCount ? errors.email : ''}
                  />
                </HorizontalView>
                <HorizontalView fullWidth paddingTop={'15px'}>
                  <Input
                    disabled={props.isLoading}
                    id={'password'}
                    fontSize={'13px'}
                    type={'password'}
                    height={'45px'}
                    borderRadius={'5px'}
                    value={values.password}
                    background={AppColors.WHITE}
                    onChange={handleChange}
                    placeholder={'public.login.password'}
                    error={submitCount ? errors.password : ''}
                  />
                </HorizontalView>
                <HorizontalView fullWidth paddingTop={'35px'} alignContent={'center'}>
                  <Button
                    loading={props.isLoading}
                    disabled={props.isLoading}
                    width={'100%'}
                    height={'60px'}
                    borderRadius={'5px'}
                    title={'public.login.loginButton'}
                    fontSize={'20px'}
                    background={AppColors.DARK}
                    color={AppColors.WHITE}
                    onClick={() => {
                      if (ref.current) {
                        ref.current.handleSubmit();
                      }
                    }}
                  />
                </HorizontalView>
              </VerticalView>
            );
          }}
        </Formik>
      </CardWrapper>
    </VerticalView>
  );
};

export default React.memo(LoginComponent);
