export default {
  graphql: {
    uri: '/graphql',
  },
  UI_HOST_URL: 'http://localhost:3000',
  HOST_URL: 'http://localhost:3000',
  // API_HOST_URL: 'http://localhost:5000',
  API_HOST_URL: 'https://api.dokodemo-j.co.jp',
  STRIPE_PUBLISH:
    'pk_test_51IpWGPHZr2kCozyWrl0VG59yKToKcCBh4djjmu1v50yQ30pV9PEG2i1Hyj1QmlynDvBEAxFq4qUGokplz44Nw2ZD001RLKWGGN',
  FACEBOOK_APP_ID: '1777256802456199',
};
