import React from 'react';
import { useApolloClient } from '@apollo/client';
import { userQueries } from 'api/user';
import { IUser } from 'models/User';
import EventEmitter from 'events';
export const event = new EventEmitter();

export type IAuth = {
  session: SessionState;
  dispatch: React.Dispatch<Actions>;
};

export const SessionContext = React.createContext<IAuth>({} as IAuth);

export type SessionState = {
  user: IUser;
  isAdmin?: boolean;
  isGuest?: boolean;
  token?: string | null;
  isAuthenticated: boolean;
  isLoaded: boolean;
};

export type Actions =
  | { type: 'update'; payload: { user: IUser; isAuthenticated: boolean; token: string | null } }
  | { type: 'delete-session' }
  | { type: 'login'; payload: { user: IUser; isAuthenticated: boolean; token: string | null } }
  | { type: 'logout' };

export function authReducer(state: SessionState, action: Actions): SessionState {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
        isLoaded: true,
        isAdmin: action.payload.user?.role === 'ADMIN',
        isGuest: !action.payload.user.id,
      };
    case 'delete-session':
    case 'logout':
      localStorage.removeItem('SESSION_TOKEN');
      return { isAuthenticated: false, isLoaded: true, isGuest: true, user: {} as IUser };
    default:
      return state;
  }
}

type UseSession = () => {
  session: SessionState;
  reauthenticate: () => Promise<boolean>;
  dispatch: React.Dispatch<Actions>;
};

export const useSession: UseSession = () => {
  const client = useApolloClient();

  const context = React.useContext(SessionContext);

  const reauthenticate = async (): Promise<boolean> => {
    const token = localStorage.getItem('SESSION_TOKEN');
    const { data } = await client.query<{ checkAuth: IUser }>({
      query: userQueries.CHECK_AUTH,
      fetchPolicy: 'network-only',
    });

    if (data && data.checkAuth) {
      const user = {
        id: data.checkAuth.id,
        role: data.checkAuth.role,
      } as IUser;
      context.dispatch({ type: 'update', payload: { user: user, isAuthenticated: true, token: token } });

      return true;
    } else {
      context.dispatch({ type: 'delete-session' });
    }

    return false;
  };

  return { session: context.session, dispatch: context.dispatch, reauthenticate };
};
