import React from 'react';
import { useParams } from 'react-router-dom';
import { CMSNewsDetailsComponent } from 'modules/cms-interface/article/components/ArticleDetailsComponent';
import { openNotification } from 'modules/common/vendors/Notification';
import { useTranslationText } from 'locale';
import { useGetArticle, useUpdateArticle } from 'api/article/useArticleApi';
import { IArticle, IUpdateArticleInput } from 'models/Article';

export const CmsArticleDetailsPage = () => {
  const { articleId } = useParams();
  const { loading, article } = useGetArticle(articleId);
  const { onUpdateArticle, loading: updateLoading } = useUpdateArticle();
  const { translation } = useTranslationText();

  const update = async (update: IArticle) => {
    const input = {
      id: update.id,
      isPublic: update.isPublic,
      thumbnail: update.thumbnail || '',
      cover: update.cover || '',
      title: update.title || '',
      category: update.category || '',
      description: update.description || '',
      content: update.content || '',
    } as IUpdateArticleInput;
    const result = await onUpdateArticle(input);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('message.update'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.update'),
        description: translation('message.error'),
      });
    }
  };

  return (
    <CMSNewsDetailsComponent
      isServicePage={false}
      loading={loading}
      saving={updateLoading}
      article={article}
      error={undefined}
      onUpdate={(updateData) => {
        update(updateData);
      }}
    />
  );
};
