import React from 'react';
import { Routes, Route } from 'react-router';
import NotFoundContainer from 'modules/user-interface/not-found/containers';
import { CmsContactDetailsPage } from '../containers/CmsContactDetailsPage';
import { CmsSearchContactsPage } from '../containers/CmsSearchContactsPage';

export const ContactRouter = () => {
  return (
    <Routes>
      <Route index element={<CmsSearchContactsPage />} />
      <Route path=":contactId" element={<CmsContactDetailsPage />} />
      <Route path="*" element={<NotFoundContainer />} />
    </Routes>
  );
};
