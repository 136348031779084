import React from 'react';
import { HomePageComponent } from '../components';
import { useGetHomeContent } from 'api/home/useHomeApi';
import { openNotification } from 'modules/common/vendors/Notification';
import { ISendContactInput } from 'models/Contact';
import { useSearchSchedule, useSendContact } from 'api/contact/useContactApi';
import { useTranslationText } from 'locale';
import dayjs from 'dayjs';
import { ScheduleEvent } from 'modules/common/components/SchedulePicker';
export const HomeContainer = () => {
  const lang = localStorage['QHC_LANG'] || 'ja';
  const { loading, homePage, onGetHomePage } = useGetHomeContent();
  const [sent, setSent] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  const { onSendContact, loading: sendContactLoading } = useSendContact();
  const { translation } = useTranslationText();
  const { onSearchSchedule, schedule } = useSearchSchedule();
  const dateFrom = dayjs().weekday(1).format('YYYY/MM/DD 00:00');
  const dateTo = dayjs().weekday(14).format('YYYY/MM/DD 23:59');

  React.useEffect(() => {
    setMounted(true);
    onSearchSchedule({
      from: dateFrom,
      to: dateTo,
    });
  }, []);

  const events = () => {
    if (schedule && schedule.length > 0) {
      return schedule.map((item) => {
        return {
          id: item.id,
          datetime: item.datetime,
        };
      });
    }
  };

  const update = async (data: ISendContactInput) => {
    const result = await onSendContact(data);
    if (!result.error) {
      setSent(true);
      openNotification({
        type: 'success',
        message: translation('message.update'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.update'),
        description: translation('message.error'),
      });
    }
  };

  React.useEffect(() => {
    onGetHomePage();
  }, []);

  return (
    <HomePageComponent
      lang={lang}
      loading={loading}
      news={homePage ? homePage.news : []}
      services={homePage ? homePage.services : []}
      success={sent}
      isServicePage
      loadingContact={!mounted}
      sendLoading={sendContactLoading}
      scheduleList={events() as ScheduleEvent[]}
      onSend={(value) => update(value)}
    />
  );
};

export default HomeContainer;
