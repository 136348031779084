import React, { useState } from 'react';
import { Div, HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { Text } from 'modules/common/vendors/Text';
import { Image } from 'modules/common/vendors/Image';
import { Button } from 'modules/common/vendors/Button';
import { useAppSetting } from 'helpers/useAppSetting';
import { AppColors } from 'helpers';
import { useTranslationText } from 'locale';
import { useNavigate } from 'react-router-dom';

type HeaderSearchBarProps = {
  isMobile: boolean;
  path: string;
  menuItems: { title: string; path: string }[];
};

type MenuItemProps = {
  title: string;
  path: string;
  isSelected: boolean;
  itemClick: () => void;
};

const MenuItem = (props: MenuItemProps) => {
  const backgroundColor = props.isSelected ? AppColors.RED_PRIMARY : AppColors.WHITE;
  const textColor = props.isSelected ? AppColors.WHITE : AppColors.BLACK;
  const { translation } = useTranslationText();
  const navigate = useNavigate();

  return (
    <Div
      width="40%"
      height="50px"
      marginLeft="5%"
      marginRight="5%"
      background={backgroundColor}
      transition="background 0.5s"
      alignContent="center"
      onClick={(e) => {
        e.preventDefault();
        navigate(props.path);
        props.itemClick();
      }}
    >
      <Text color={textColor} fontSize="13px">
        {translation(props.title)}
      </Text>
    </Div>
  );
};

export const MobileHeaderBar = (props: HeaderSearchBarProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { appTheme } = useAppSetting();
  const navigate = useNavigate();

  if (!props.isMobile) {
    return null;
  }

  return (
    <VerticalView fullWidth>
      <HorizontalView fullWidth height="60px" alignContent="centerLeft">
        <Div fullHeight onClick={() => navigate('/')} paddingBottom="10px">
          <Image height="100%" src="/logo.png" objectFit="contain" />
        </Div>
        <Div alignContent="centerRight" width="calc(100% - 126px)">
          <Button
            preixIcon={'menu'}
            fontSize={'30px'}
            color={appTheme.header.text}
            background={AppColors.TRANSPARENT}
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          />
        </Div>
      </HorizontalView>

      <VerticalView
        width="100%"
        background={AppColors.GRAY_BGR}
        display={openMenu ? 'flex' : 'none'}
        position="relative"
        zIndex={10}
      >
        <HorizontalView width="90%" paddingBottom="20px" paddingTop="20px">
          <MenuItem
            title={props.menuItems[0].title}
            path={props.menuItems[0].path}
            isSelected={location.pathname === props.menuItems[0].path}
            itemClick={() => setOpenMenu(false)}
          />
          <MenuItem
            title={props.menuItems[1].title}
            path={props.menuItems[1].path}
            isSelected={location.pathname === props.menuItems[1].path}
            itemClick={() => setOpenMenu(false)}
          />
        </HorizontalView>
        <HorizontalView width="90%" paddingBottom="20px">
          <MenuItem
            title={props.menuItems[2].title}
            path={props.menuItems[2].path}
            isSelected={location.pathname === props.menuItems[2].path}
            itemClick={() => setOpenMenu(false)}
          />
          <MenuItem
            title={props.menuItems[3].title}
            path={props.menuItems[3].path}
            isSelected={location.pathname === props.menuItems[3].path}
            itemClick={() => setOpenMenu(false)}
          />
        </HorizontalView>
        <HorizontalView width="90%" paddingBottom="20px" alignContent="centerLeft">
          <MenuItem
            title={props.menuItems[4].title}
            path={props.menuItems[4].path}
            isSelected={location.pathname === props.menuItems[4].path}
            itemClick={() => setOpenMenu(false)}
          />
        </HorizontalView>
        <Div fullWidth height="1px" background="#ddd" />
        <Div
          width="100%"
          height="40px"
          onClick={() => {
            setOpenMenu(false);
          }}
          alignContent="center"
        >
          <Text>{'閉じる'}</Text>
        </Div>
      </VerticalView>
    </VerticalView>
  );
};
