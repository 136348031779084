import gql from 'graphql-tag';

export const LOG_IN = gql`
  mutation loginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      id
      email
      token
      role
    }
  }
`;

export const CREATE = gql`
  mutation operationCreateUser($input: OperationCreateUserInput!) {
    operationCreateUser(input: $input) {
      id
    }
  }
`;

export const LOG_OUT = gql`
  mutation logoutUser {
    logoutUser
  }
`;

export const UPDATE = gql`
  mutation operationUpdateUser($input: OperationUpdateUserInput!) {
    operationUpdateUser(input: $input) {
      id
    }
  }
`;

export const DELETE = gql`
  mutation operationDeleteUser($input: OperationDeleteUserInput!) {
    operationDeleteUser(input: $input)
  }
`;

export const RESET_PASS = gql`
  mutation operationResetUserPassword($input: OperationResetUserPasswordInput!) {
    operationResetUserPassword(input: $input) {
      id
    }
  }
`;
