import defaultConfig from './default-config';

type Config = {
  graphql: {
    uri: string;
  };
  UI_HOST_URL: string;
  HOST_URL: string;
  API_HOST_URL: string;
  FB_TOKEN: string;
};
const envConfig = require(`./${process.env.NODE_ENV}-config`).default;

export default Object.assign({ env: process.env.NODE_ENV }, defaultConfig, envConfig) as Config;
