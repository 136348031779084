import moment from 'moment';
import crypto from 'crypto';
import { IMedia } from 'models/Media';
import { isNumber } from 'lodash';
const POSSIBLE_KEY = 'afEFGHIJaNOP1STUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function keyGen(len: number) {
  return crypto
    .randomBytes(Math.ceil((len * 3) / 4))
    .toString('base64')
    .slice(0, len)
    .replace(/\+/g, POSSIBLE_KEY.charAt(Math.floor(Math.random() * POSSIBLE_KEY.length)))
    .replace(/\//g, POSSIBLE_KEY.charAt(Math.floor(Math.random() * POSSIBLE_KEY.length)))
    .toLowerCase();
}

export interface UtilsInterface {
  setValidateFormikStatus(input: string): 'success' | 'warning' | 'error' | 'validating';
}

export const getValidateFormikStatus = (input: boolean): 'success' | 'warning' | 'error' | 'validating' => {
  return input ? 'error' : 'success';
};

export function generateId() {
  return `${moment().valueOf()}${keyGen(8)}`;
}

export function updateData(data: any, key: string, value: any) {
  if (data) {
    const newObject: { [key: string]: any } = JSON.parse(JSON.stringify(data));
    newObject[key] = value;
    return newObject;
  } else {
    const newObject: { [key: string]: any } = {};
    newObject[key] = value;
    return newObject;
  }
}

export function friendlyURL(id?: string, name?: string): string {
  let str = name;
  if (!str) {
    return 'details--' + id;
  }

  str = str.trim().toLowerCase();
  str = str.replace(/\(/g, '-');
  str = str.replace(/\)/g, '-');
  str = str.replace(/\s/g, '-');
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/:/g, '');
  str = str.replace(/%/g, '');
  str = str.replace(/'/g, '-');
  str = str.replace(/\//g, '-');
  str = str.replace(/\?|#|\./g, '');
  if (!id) {
    return str;
  }

  return encodeURIComponent(str.trim().replace(/--/g, '-')) + '--' + id;
}

export function parseIdFromSeoname(seoname: string): string | null {
  if (!seoname || (seoname && !seoname.includes('--'))) {
    return null;
  }
  const arr = seoname.split('--');
  return arr[arr.length - 1];
}

export function copyToClipboard(str: string) {
  const textarea = document.createElement('textarea');
  textarea.textContent = str;
  document.body.appendChild(textarea);

  const selection = document.getSelection();
  if (!selection) {
    return;
  }
  const range = document.createRange();
  range.selectNode(textarea);
  selection.removeAllRanges();
  selection.addRange(range);

  document.execCommand('copy');
  selection.removeAllRanges();

  document.body.removeChild(textarea);
}

export function getMediaSrc(media: IMedia): string {
  return media.url.includes('google') ? media.url : media.src || '';
}

export function isManagerCourseDetailsPage(path: string) {
  if (path.includes('/courses/upload')) {
    return false;
  }
  return (
    (path.includes('/author/courses/') && path.length > 17) || (path.includes('/cms/courses/') && path.length > 14)
  );
}

export function getRemainTime(seconds: number) {
  const daysRemain = parseInt(`${seconds / (24 * 60 * 60)}`);
  const hoursRemain = parseInt(`${(seconds - daysRemain * 24 * 60 * 60) / (60 * 60)}`);
  const minutesRemain = parseInt(`${(seconds - daysRemain * 24 * 60 * 60 - hoursRemain * 60 * 60) / 60}`);
  const secondsRemain = parseInt(
    `${(seconds - daysRemain * 24 * 60 * 60 - hoursRemain * 60 * 60 - minutesRemain * 60) / 60}`,
  );

  return {
    days: daysRemain,
    hours: hoursRemain,
    minutes: minutesRemain,
    second: secondsRemain,
  };
}

export function makeBoldChar(text: string, char: string) {
  return text.replace(new RegExp('(' + char + ')', 'ig'), '<b>$1</b>');
}

export function isFloat(n: number) {
  return Number(n) === n && n % 1 !== 0;
}

export const getTotalPages = (totalData: number, perPage: number) => {
  let totalPage = 1;
  if (totalData > perPage) {
    if (totalData % perPage === 0) {
      totalPage = totalData / perPage;
    } else {
      totalPage = parseInt((totalData / perPage).toFixed(0));
      if (totalData > totalPage * perPage) {
        totalPage += 1;
      }
    }
  }
  return totalPage;
};

export const getMediaUrl = (media: IMedia) => {
  return media.url;
};

export const convertObjectToDictionary = (object: { [key: string]: any }, rootpath?: string) => {
  const keys = Object.keys(object);

  let newObject: { [key: string]: string } = {};
  keys.forEach((key) => {
    const path = rootpath ? `${rootpath}.${key}` : key;
    if (typeof object[key] === 'boolean' || typeof object[key] === 'string' || typeof object[key] === 'number') {
      newObject[path] = object[key];
    } else {
      const newParser = convertObjectToDictionary(object[key], path);
      newObject = { ...newObject, ...newParser };
    }
  });

  return newObject;
};

export const updateDictionary = (object: { [key: string]: any }, key: string, value: string) => {
  const newObject = JSON.parse(JSON.stringify(object));

  if (key.includes('.')) {
    const keys = key.split('.');
    const rootKey = isNumber(keys[0]) ? parseInt(keys[0]) : keys[0];
    let rootObj = object[rootKey];
    keys.splice(0, 1);
    rootObj = updateDictionary(rootObj, keys.join('.'), value);
    newObject[rootKey] = rootObj;
  } else {
    const lastKey = isNumber(key) ? parseInt(key) : key;
    newObject[lastKey] = value;
  }
  return newObject;
};

export function chunkArray(array: any[], size: number) {
  const chunkSize = size;

  const allGroups: any[][] = [];
  let groups: any[] = [];
  let start = 1;
  array.forEach((node, index) => {
    groups.push(node);
    if (start === chunkSize || index === array.length - 1) {
      allGroups.push(groups);
      start = 1;
      groups = [];
    } else {
      start += 1;
    }
  });
  return allGroups;
}
