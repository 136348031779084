import React from 'react';
import { Div, VerticalView } from 'modules/common/vendors/Wrapper';
import { AppColors } from 'helpers';
import { Label } from 'modules/common/vendors/Label';

type PageTitleProps = {
  color?: string;
  size?: string;
  title: string;
};
export const PageTitle = (props: PageTitleProps) => {
  return (
    <Div fullWidth alignContent={'centerLeft'}>
      <VerticalView fullWidth alignContent={'centerLeft'}>
        <Label fontSize={props.size || '30px'} color={props.color || AppColors.DARK} label={props.title} />
      </VerticalView>
    </Div>
  );
};
