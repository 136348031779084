import React from 'react';
import { useParams } from 'react-router-dom';
import { ServiceDetailsComponent } from '../../components/details';
import { useGetArticle } from 'api/article/useArticleApi';

export const ServiceDetailsContainer = () => {
  const { articleId } = useParams();
  const { loading, article } = useGetArticle(articleId);

  return <ServiceDetailsComponent isLoading={loading} service={article} />;
};

export default ServiceDetailsContainer;
