import React, { memo } from 'react';
import styled from 'styled-components';
import { Modal } from 'modules/common/vendors/Modal';
import { Formik, FormikProps } from 'formik';
import { HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { AppConst } from 'helpers';
import { useTranslationText } from 'locale';
import { useAppSetting } from 'helpers/useAppSetting';
import { PrimaryButton } from 'modules/common/components/Button/PrimaryButton';
import { Input } from 'modules/common/vendors/Input';
import { IOperationCreateUserInput } from 'models/User';
import { createUserValidatorSchema } from '../../validator';

export const ModalWrapper = styled.div``;
export const AlertModalWrapper = styled.div``;
export const ContentWrapper = styled.div``;
export const AddUserWrapper = styled.div``;

interface Props {
  showCreate: boolean;
  createLoading: boolean;
  onCreate: (values: IOperationCreateUserInput) => void;
  onClose: () => void;
}

const Wrapper = styled.div``;

export const CreateUserModal = (props: Props) => {
  const ref = React.useRef<FormikProps<IOperationCreateUserInput>>(null);

  const { translation } = useTranslationText();
  const { appLayout, appTheme } = useAppSetting();
  return (
    <Wrapper>
      <Modal
        title={translation('createUser.title')}
        open={props.showCreate}
        height={appLayout.isMobile ? '100vh' : 'auto'}
        zIndex={AppConst.zIndex.header + 60}
        onClose={() => {
          props.onClose();
        }}
        headerBackground={appTheme.sidebar.bg}
        closeOnBackDropClick
      >
        <HorizontalView minWidth={'300px'} fullWidthHeight alignContent={'topCenter'}>
          <Formik
            innerRef={ref}
            initialValues={{ email: '', password: '', confirmPassword: '' } as IOperationCreateUserInput}
            validationSchema={createUserValidatorSchema}
            onSubmit={(values: IOperationCreateUserInput) => {
              props.onCreate(values);
            }}
          >
            {({ errors, values, submitCount, handleChange }) => {
              return (
                <VerticalView width={appLayout.isMobile ? '100vw' : '450px'} padding={'20px'}>
                  <Input
                    id={'email'}
                    width={'100%'}
                    label={'createUser.email'}
                    placeholder={'createUser.email'}
                    onChange={handleChange}
                    value={values.email}
                    error={submitCount ? errors.email : undefined}
                  />
                  <HorizontalView fullWidth paddingTop={'15px'}>
                    <Input
                      id={'password'}
                      label={'createUser.password'}
                      type={'password'}
                      width={'100%'}
                      value={values.password}
                      onChange={handleChange}
                      placeholder={'createUser.password'}
                      error={submitCount ? errors.password : undefined}
                    />
                  </HorizontalView>
                  <HorizontalView fullWidth paddingTop={'15px'} paddingBottom={'20px'}>
                    <Input
                      id={'confirmPassword'}
                      label={'createUser.confirmPassword'}
                      type={'password'}
                      width={'100%'}
                      value={values.confirmPassword}
                      onChange={handleChange}
                      placeholder={'createUser.confirmPassword'}
                      error={submitCount ? errors.confirmPassword : undefined}
                    />
                  </HorizontalView>

                  <PrimaryButton
                    title={'createUser.create'}
                    icon={'plus'}
                    width={'80%'}
                    onClick={() => {
                      if (ref.current) {
                        ref.current.handleSubmit();
                      }
                    }}
                  />
                </VerticalView>
              );
            }}
          </Formik>
        </HorizontalView>
      </Modal>
    </Wrapper>
  );
};

export default memo(CreateUserModal);
