import React from 'react';
import { Routes, Route } from 'react-router';
import CMSPrivacyContainer from '../containers/company-privacy';
import CMSCompanyProfileContainer from '../containers/company-profile';

export const CompanyRouter = () => {
  return (
    <Routes>
      <Route path="/company-privacy" element={<CMSPrivacyContainer />} />
      <Route path="/company-profile" element={<CMSCompanyProfileContainer />} />
    </Routes>
  );
};
