export default {
  graphql: {
    uri: '/graphql',
  },
  UI_HOST_URL: 'https://dokodemo-j.co.jp',
  HOST_URL: 'https://dokodemo-j.co.jp',
  API_HOST_URL: 'https://api.dokodemo-j.co.jp',
  FB_TOKEN:
    '&access_token=EAAFnSdequosBAE6q7Wgs8lDizHQFodTjm5ZCNnwbAITZCfUG0SlzrZA5P25e0ZAk9XOtkb7tuSmrAWFAiK1aCS8EiZCk7RL7dLXjFIkx2R3YKFJke2ZAOF4ZCSmcOy0pjVGl6WQzmd9OvV0gn83N9hbsoxhGS2WKmOwsBINPne3LddZBrZBEtTZAwypmocK8kBcOsgbaXxVT94FSGGabdHCPjo1S8A4ZA0xYqZB6uZCRpvF5YzaCIPcfoyKG0',
  FACEBOOK_APP_ID: '1777256802456199',
};
