import React from 'react';
import { useApolloClient } from '@apollo/client';
import { openNotification } from 'modules/common/vendors/Notification';
import { LoginComponent, LoginFormik } from '../components/';
import { delay } from 'lodash';
import { useSession } from 'stores/user.store';
import { useLoginUser } from 'api/user/useUserApi';
import { useNavigate } from 'react-router-dom';

export const LoginContainer = () => {
  const { loginUser } = useLoginUser();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const client = useApolloClient();
  const { dispatch } = useSession();

  const onLogin = async (values: LoginFormik) => {
    await client.clearStore();
    setLoading(true);
    const loginData = await loginUser(values);
    if (loginData.user) {
      localStorage.setItem('SESSION_TOKEN', loginData.user?.token || '');
      dispatch({
        type: 'update',
        payload: { user: loginData.user, isAuthenticated: true, token: loginData.user?.token || '' },
      });
    }

    await delay(() => {
      setLoading(false);
      if (!loginData.error) {
        navigate('/cms/users');
      } else {
        openNotification({
          type: 'error',
          message: loginData.error?.title,
          description: loginData.error?.message,
        });
        client.resetStore();
      }
    }, 500);
  };

  return <LoginComponent isLoading={loading} handleSubmitForm={onLogin} />;
};

export default LoginContainer;
