export const userRoleList = [
  {
    label: 'ユーザー',
    value: 'USER',
  },
  {
    label: '管理者',
    value: 'ADMIN',
  },
];

export const yesNoType = [
  {
    label: 'いいえ',
    value: false,
  },
  {
    label: 'はい',
    value: true,
  },
];

export const role = [
  {
    label: 'ADMIN',
    value: 'ADMIN',
  },
  {
    label: 'USER',
    value: 'USER',
  },
];

export const articleCategories = [
  {
    label: 'ニュース',
    value: 'News',
  },
  {
    label: '情報',
    value: 'Information',
  },
  {
    label: '通知',
    value: 'Notification',
  },
  {
    label: 'サービス',
    value: 'Service',
  },
];

export enum ArticleCategory {
  News = 'News',
  Notification = 'Notification',
  Information = 'Information',
  Service = 'Service',
}

export const zIndex = {
  header: 100,
  confirm: 50,
  modal: 150,
  body: 30,
};

export enum ErrorCodes {
  ERROR_CODE_SUCCESS = 200,
  ERROR_CODE_BAD_REQUEST = 400,
  ERROR_CODE_UNAUTHORIZED = 401,
  ERROR_CODE_INVALID_USERNAME_OR_PASSWORD = 402,
  ERROR_CODE_FORBIDDEN = 403,
  ERROR_CODE_API_NOT_FOUND = 404,
  ERROR_CODE_PASSWORD_NOT_MATCHED = 405,
  ERROR_CODE_EMAIL_EXIST = 406,
  ERROR_CODE_ITEM_EXIST = 407,
  ERROR_CODE_ITEM_NOT_EXIST = 444,
  ERROR_CODE_UPLOAD_ERROR = 409,
  ERROR_CODE_ACCOUNT_IS_NOT_VERIFY = 410,
  ERROR_CODE_CANNOT_DELETE_YOURSELF = 411,
  ERROR_CODE_NEW_PASSWORD_MUST_DIFFERENT_OLD_PASSWORD = 412,
  ERROR_CODE_INVALID_PARAMETER = 413,
  ERROR_CODE_ACCOUNT_TEMPORARY_LOCKED = 414,
  ERROR_CODE_ACCOUNT_RESTRICTED = 415,
  ERROR_CODE_TOKEN_NOT_EXPIRED = 416,
  ERROR_CODE_ITEM_REQUIRED = 417,
  ERROR_CODE_TOKEN_EXPIRED = 418,
  ERROR_CODE_WARNING = 419,
  ERROR_CODE_GRAPHQL_VALIDATION_FAILED = 420,
  ERROR_CODE_NO_ACCESS = 421,
  ERROR_CODE_INVALID_PASSWORD = 422,
  ERROR_CODE_SYSTEM_ERROR = 500,
  ERROR_CODE_SERVER_TEMPORARILY_UNAVAILABLE = 503,
}

export enum Regex {
  PHONE = '^[0-9]{10,11}$',
  NUMBER_ONLY = '^[0-9]+$',
  EMAIL = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,20}$',
  PASSWORD = '^(?=.*[0-9])(?=.*[a-zA-Z])([\x20-\x7E]+)$',
  WEBSITE_URL = '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$',
  FILE_EXTENSION = '^.*.(jpg|jpeg|png|pdf|xlsx)$',
}
