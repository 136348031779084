import React from 'react';
import styled from 'styled-components';
import { Div, HorizontalView } from 'modules/common/vendors/Wrapper';
import { Button } from 'modules/common/vendors/Button';
import { Input } from 'modules/common/vendors/Input';
import { AppColors } from 'helpers';
import { VerticalView } from 'modules/common/vendors/CommonWrappers';
import { Label } from 'modules/common/vendors/Label';
import { PrimaryButton } from 'modules/common/components/Button/PrimaryButton';
import { SelectLimit } from 'modules/common/components/SelectLimit';
import { Text } from 'modules/common/vendors/Text';
import { Hr } from 'modules/common/vendors/CommonParts';

export const SearchWrapper = styled.div`
  border: 1px solid ${AppColors.BORDER};
  border-radius: 25px;
  overflow: hidden;
  width: 80%;
`;

type SearchBarProps = {
  createLoading: boolean;
  totalItems: number;
  onChangeLimit: (limit: number) => void;
  onSearch: (params: string) => void;
  onCreate: () => void;
};

const SearchKeyword = (props: { onSearch: (key: string) => void }) => {
  const [keyword, setKeyword] = React.useState('');
  return (
    <HorizontalView fullWidth alignContent={'centerLeft'} paddingTop={'20px'}>
      <Div width={'350px'} paddingRight={'20px'}>
        <Input
          width={'100%'}
          height={'50px'}
          placeholder={'usersSearch.searchbar.keyword'}
          value={keyword}
          borderRadius={'5px'}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onPressEnter={() => {
            props.onSearch(keyword);
          }}
        />
      </Div>
      <Div>
        <PrimaryButton
          icon={'search'}
          title={'search'}
          onClick={() => {
            props.onSearch(keyword);
          }}
        />
      </Div>
    </HorizontalView>
  );
};

export const CmsSearchUserBar = (props: SearchBarProps) => {
  return (
    <VerticalView fullWidth>
      <VerticalView width={'100%'} borderRadius={'5px'} alignContent={'centerLeft'} paddingBottom={'20px'}>
        <SearchKeyword onSearch={props.onSearch} />
      </VerticalView>
      <Hr />
      <HorizontalView fullWidth alignContent={'centerBetween'} paddingTop={'20px'} paddingBottom={'20px'}>
        <Button
          loading={props.createLoading}
          title={'new'}
          height={'40px'}
          minWidth={'130px'}
          preixIcon={'plus'}
          background={AppColors.RED2}
          color={AppColors.WHITE}
          borderRadius={'5px'}
          onClick={props.onCreate}
        />
        <HorizontalView>
          <Text paddingRight={'20px'}>
            <Label label={'all'} />
            {` ${props.totalItems} `}
            <Label label={'item'} />
          </Text>
          <SelectLimit onChangeLimit={props.onChangeLimit} />
        </HorizontalView>
      </HorizontalView>
    </VerticalView>
  );
};
