import React from 'react';
import { Button } from 'modules/common/vendors/Button';
import { IConType } from 'modules/common/vendors/Icon';
import { useAppSetting } from 'helpers/useAppSetting';
import { AppColors } from 'helpers';

const css = `
    position: relative;
    border-radius: 5px;
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s; /* Safari */
    :hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    :after {
        content: "";
        display: block;
        position: absolute;
        border-radius: 10px;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.5s;
    }
    
    :active:after {
        position: absolute;
        border-radius: 10px;
        left: 0;
        top:0;
        opacity: 1;
        transition: 0s;
    }
    :active {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
`;

type PrimaryButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  icon?: IConType;
  size?: string;
  width?: string;
  height?: string;
  marginTop?: string;
  marginRight?: string;
  onClick?: () => void;
};

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const { appTheme } = useAppSetting();
  const [color, setColor] = React.useState(appTheme.base.white);
  const [bg, setBg] = React.useState(appTheme.base.base);
  return (
    <Button
      marginRight={props.marginRight}
      marginTop={props.marginTop}
      disabled={props.disabled}
      title={props.title}
      loading={props.loading}
      preixIcon={props.icon}
      width={props.width}
      height={props.height}
      background={bg}
      color={color}
      borderRadius={'5px'}
      padding={'10px'}
      paddingLeft={'15px'}
      paddingRight={'15px'}
      minWidth={'50px'}
      fontSize={props.size || '15px'}
      border={`1px solid ${appTheme.base.border}`}
      onClick={props.disabled ? undefined : props.onClick}
      onMouseEnter={() => {
        if (props.disabled) {
          return;
        }
        setColor(appTheme.base.white);
        setBg(AppColors.GRAY_DARK);
      }}
      onMouseLeave={() => {
        if (props.disabled) {
          return;
        }
        setColor(appTheme.base.white);
        setBg(appTheme.base.base);
      }}
      css={!props.disabled ? css : undefined}
    />
  );
};
