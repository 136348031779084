import React from 'react';
import { Routes, Route } from 'react-router';
import { CmsServicesSearchPage } from '../containers/ServicesSearchPage';
import { ServiceDetailsPage } from '../containers/ServiceDetailsPage';
import NotFoundContainer from 'modules/user-interface/not-found/containers';

export const ServicesRouter = () => {
  return (
    <Routes>
      <Route index element={<CmsServicesSearchPage />} />
      <Route path="/:articleId" element={<ServiceDetailsPage />} />
      <Route path="*" element={<NotFoundContainer />} />
    </Routes>
  );
};
