import React from 'react';
import { useOperationSearchContacts } from 'api/contact/useContactApi';
import { useNavigate } from 'react-router-dom';
import { CmsContactsSearchComponent } from '../components/CmsContactsSearchComponent';

export const CmsSearchContactsPage = () => {
  const {
    contacts,
    loading,
    totalPages,
    totalItems,
    page,
    limit,
    query,
    onSearchContacts,
    onChangePage,
    onChangeLimit,
  } = useOperationSearchContacts();

  const navigate = useNavigate();

  return (
    <CmsContactsSearchComponent
      loading={loading}
      contacts={contacts || []}
      totalPages={totalPages}
      limit={limit}
      page={page}
      totalItems={totalItems}
      onSearch={(keyword) => onSearchContacts({ ...query, keyword })}
      onDetails={(id) => {
        navigate(`/cms/contacts/${id}`);
      }}
      onChangePage={onChangePage}
      onChangeLimit={onChangeLimit}
    />
  );
};
