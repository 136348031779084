import React from 'react';
import { ArticleRouter } from 'modules/cms-interface/article/router/article.router';
import { CompanyRouter } from 'modules/cms-interface/company/router/company.router';
import { ContactRouter } from 'modules/cms-interface/contact/router/contact.router';
import { MediaRouter } from 'modules/cms-interface/media/router/media.router';
import { ServicesRouter } from 'modules/cms-interface/service/router/services.router';
import { UsersRouter } from 'modules/cms-interface/users/router/users.router';
import { PlashScreen } from 'modules/common/components/Plash';
import { CMSLayout } from 'modules/cms-interface/layout';
import { useSession } from 'stores/user.store';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { NotFoundRouter } from 'modules/user-interface/not-found/router/notfound.router';

export const CmsRouter = () => {
  const location = useLocation();
  const navigative = useNavigate();
  const { session } = useSession();
  if (!session.isLoaded) {
    return <PlashScreen />;
  }

  if (location.pathname == '/cms' || location.pathname == '/cms/index') {
    navigative('/cms/users');
    return <PlashScreen />;
  }

  if (!session.isAdmin) {
    navigative('/');
    return <PlashScreen />;
  }

  return (
    <CMSLayout>
      <Routes>
        <Route path={'medias/*'} element={<MediaRouter />} />
        <Route path={'users/*'} element={<UsersRouter />} />
        <Route path={'articles/*'} element={<ArticleRouter />} />
        <Route path={'services/*'} element={<ServicesRouter />} />
        <Route path={'company/*'} element={<CompanyRouter />} />
        <Route path={'contacts/*'} element={<ContactRouter />} />
        <Route path={'*'} element={<NotFoundRouter />} />
      </Routes>
    </CMSLayout>
  );
};
