import { useState, useEffect } from 'react';
import { AcceptFileType } from 'modules/common/vendors/Upload';

export const useMediaType = (accept: AcceptFileType) => {
  const [mediaType, setMediaType] = useState<string | null>(null);

  useEffect(() => {
    if (accept !== 'image,audio,video') {
      setMediaType(accept);
    } else {
      setMediaType('image');
    }
  }, [accept]);

  return {
    mediaType,
    setMediaType,
  };
};
