import React from 'react';
import styled from 'styled-components';
import { Div, HorizontalView } from 'modules/common/vendors/Wrapper';
import { Input } from 'modules/common/vendors/Input';
import { AppColors } from 'helpers';
import { SVGIcon } from 'modules/common/vendors/Icon';

export const SearchWrapper = styled.div`
  border: 1px solid ${AppColors.BORDER};
  border-radius: 4px;
  overflow: hidden;
`;

type SearchBarProps = {
  placeholder: string;
  onSearch: (keyword: string) => void;
};

export const MediaSearchBar = (props: SearchBarProps) => {
  const [keyword, setKeyword] = React.useState('');

  return (
    <HorizontalView>
      <SearchWrapper>
        <HorizontalView alignContent={'centerBetween'} fullWidthHeight>
          <Div width={'150px'}>
            <Input
              id={'keyword'}
              placeholder={'keyword'}
              name={'keyword'}
              value={keyword}
              noBorder
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              onPressEnter={() => props.onSearch(keyword)}
            />
          </Div>

          <Div
            height={'40px'}
            width={'60px'}
            background={AppColors.PRIMARY}
            onClick={() => {
              props.onSearch(keyword);
            }}
            alignContent={'center'}
          >
            <SVGIcon name={'search'} color={AppColors.WHITE} />
          </Div>
        </HorizontalView>
      </SearchWrapper>
    </HorizontalView>
  );
};
