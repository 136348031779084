import React from 'react';
import styled from 'styled-components';
import { HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { TableWrapper } from 'modules/common/vendors/CommonParts';
import { CreateUserModal } from './parts/CreateUserModal';
import { Loading } from 'modules/common/vendors/Loading';
import { useAppSetting } from 'helpers/useAppSetting';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { MobileTableView } from './parts/MobileTableView';
import { WebTableView } from './parts/WebTableView';
import { CmsSearchUserBar } from './parts/CmsSearchUserBar';
import { PageTitle } from 'modules/common/components/PageTitle';
import { PaginationBar } from 'modules/common/components/Pagination/PaginationBar';
import { IOperationCreateUserInput, IUser } from 'models/User';

export const SearchWrapper = styled.div`
  width: 100%;
  padding: 10px;
`;

export const ModalWrapper = styled.div``;
export const AlertModalWrapper = styled.div``;
export const ContentWrapper = styled.div``;
export const AddUserWrapper = styled.div``;

interface UserListProps {
  loading: boolean;
  createLoading: boolean;
  deleteLoading: boolean;
  totalPages: number;
  currentPage: number;
  totalItems: number;
  users: IUser[];
  showCreate: boolean;
  setShowCreate: (showCreate: boolean) => void;
  onDelete: (userId: string) => void;
  onDetails: (userId: string) => void;
  onSearch: (keyword: string) => void;
  onCreate: (values: IOperationCreateUserInput) => void;
  onChangePage: (page: number) => void;
  onChangeLimit: (page: number) => void;
}

export const UserSearchComponent = (props: UserListProps) => {
  const { appLayout } = useAppSetting();

  return (
    <BaseComponent>
      <VerticalView fullWidth alignContent={'center'}>
        <Loading loading={props.createLoading || props.deleteLoading} />
        <PageTitle title={'usersSearch.header'} />
        <CmsSearchUserBar
          createLoading={props.createLoading}
          totalItems={props.totalItems}
          onSearch={(keyword) => props.onSearch(keyword)}
          onCreate={() => props.setShowCreate(true)}
          onChangeLimit={props.onChangeLimit}
        />
        <TableWrapper>
          {appLayout.isMobile && (
            <MobileTableView
              loading={props.loading}
              users={props.users}
              onDelete={props.onDelete}
              onDetails={props.onDetails}
            />
          )}
          {!appLayout.isMobile && (
            <WebTableView
              loading={props.loading}
              users={props.users}
              onDelete={props.onDelete}
              onDetails={props.onDetails}
            />
          )}
        </TableWrapper>

        {props.totalPages && !props.loading && (
          <HorizontalView fullWidth alignContent={'centerRight'}>
            <PaginationBar
              totalPages={props.totalPages}
              currentPage={props.currentPage}
              onChangePage={props.onChangePage}
            />
          </HorizontalView>
        )}
        <CreateUserModal
          showCreate={props.showCreate}
          createLoading={props.createLoading}
          onCreate={props.onCreate}
          onClose={() => {
            props.setShowCreate(false);
          }}
        />
      </VerticalView>
    </BaseComponent>
  );
};
