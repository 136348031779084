import { useGetCompanyPrivacy } from 'api/company/useCompanyApi';
import React from 'react';
import { PrivacyComponent } from '../components';

export const PrivacyContainer = () => {
  const { loading, company, onGetCompanyPrivacy } = useGetCompanyPrivacy();
  React.useEffect(() => {
    onGetCompanyPrivacy();
  }, []);

  return <PrivacyComponent isLoading={loading} content={company ? (company.privacyPolicy as string) : ''} />;
};

export default PrivacyContainer;
