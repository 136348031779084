import { useTranslationText } from 'locale';
import React, { useState } from 'react';
import styled from 'styled-components';
import { HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { Text } from 'modules/common/vendors/Text';
import { AppColors } from 'helpers';
import { SVGIcon } from 'modules/common/vendors/Icon';
import { useAppSetting } from 'helpers/useAppSetting';
import { useNavigate } from 'react-router';
import { useGetFooterInfo } from 'api/company/useCompanyApi';

const CopyrightWrapper = styled.div`
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 13px;
  padding: 10px 0;
`;
const LinkWrapper = styled.div`
  &:hover {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`;

type FooterItem = {
  type: 'text' | 'header' | 'link' | 'email' | 'phone' | 'ios' | 'android';
  title: string;
  url?: string;
};

export const FooterMenu = (props: { item: FooterItem }) => {
  const navigate = useNavigate();
  const item = props.item;

  return (
    <HorizontalView alignContent={'centerLeft'} flexWrap={'nowrap'}>
      {item.type === 'header' && (
        <Text fontWeight={600} fontSize={'20px'} color={AppColors.DARK}>
          {item.title}
        </Text>
      )}
      {item.type === 'link' && (
        <Text fontSize={'15px'} color={AppColors.DARK} marginTop={'15px'}>
          <LinkWrapper
            onClick={() => {
              item.url && navigate(item.url);
            }}
          >
            {item.title}
          </LinkWrapper>
        </Text>
      )}
      {item.type === 'text' && (
        <Text fontSize={'15px'} color={AppColors.DARK} marginTop={'15px'}>
          {item.title}
        </Text>
      )}
      {item.type === 'phone' && (
        <HorizontalView marginTop={'15px'} flexWrap={'nowrap'}>
          <SVGIcon name={'phone'} size={'15px'} color={AppColors.DARK} />
          <Text fontSize={'15px'} color={AppColors.DARK} marginLeft={'10px'}>
            {item.title}
          </Text>
        </HorizontalView>
      )}
      {item.type === 'email' && (
        <HorizontalView marginTop={'15px'} flexWrap={'nowrap'}>
          <SVGIcon name={'email'} size={'15px'} color={AppColors.DARK} />
          <Text fontSize={'15px'} color={AppColors.DARK} marginLeft={'10px'}>
            {item.title}
          </Text>
        </HorizontalView>
      )}
    </HorizontalView>
  );
};

export const Footer = () => {
  const { appLayout } = useAppSetting();
  const { getTypes } = useTranslationText();
  const footerMenu: FooterItem[][] = getTypes('footerMenu');
  const { company, onGetFooterInfo } = useGetFooterInfo();
  const [companyInfo, setCompanyInfo] = useState([] as FooterItem[]);

  React.useEffect(() => {
    onGetFooterInfo();
  }, []);

  React.useEffect(() => {
    if (company) {
      setCompanyInfo([
        { type: 'header', title: `${company.name}` },
        { type: 'text', title: `${company.description}` },
        { type: 'email', title: `${company.telephone}` },
      ]);
    }
  }, [company]);

  return (
    <VerticalView fullWidth paddingTop={appLayout.isMobile ? '40px' : '100px'}>
      <VerticalView fullWidth alignContent={'center'} background={AppColors.BACKGROUND_GREY} id={'footer'}>
        <VerticalView fullWidth maxWidth={'1200px'} alignContent={'center'}>
          {footerMenu && (
            <HorizontalView
              fullWidth
              alignContent={'topAround'}
              paddingTop={appLayout.isMobile ? '20px' : '0px'}
              paddingBottom={'40px'}
            >
              {companyInfo && (
                <VerticalView
                  alignContent={appLayout.isMobile ? 'center' : 'topLeft'}
                  padding={'15px'}
                  paddingRight={appLayout.isMobile ? '15px' : '50px'}
                  paddingTop={appLayout.isMobile ? '0px' : '15px'}
                  minWidth={appLayout.isMobile ? '100%' : '200px'}
                  width={`40%`}
                >
                  <VerticalView fullWidth maxWidth={appLayout.isMobile ? '550px' : 'auto'} alignContent={'centerLeft'}>
                    {companyInfo.map((menu, i) => {
                      return (
                        <HorizontalView key={i} alignContent={'centerLeft'} flexWrap={'nowrap'}>
                          <FooterMenu item={menu} />
                        </HorizontalView>
                      );
                    })}
                    {appLayout.isMobile && (
                      <HorizontalView
                        marginTop={'15px'}
                        fullWidth
                        height={'1px'}
                        background={AppColors.DARK}
                        opacity={0.3}
                      />
                    )}
                  </VerticalView>
                </VerticalView>
              )}
              {footerMenu.map((items, index) => {
                return (
                  <VerticalView
                    key={index}
                    alignContent={appLayout.isMobile ? 'center' : 'topLeft'}
                    padding={'15px'}
                    paddingRight={appLayout.isMobile ? '15px' : '50px'}
                    paddingTop={appLayout.isMobile ? '0px' : '15px'}
                    minWidth={appLayout.isMobile ? '100%' : '200px'}
                    width={`${60 / footerMenu.length}%`}
                  >
                    <VerticalView
                      fullWidth
                      maxWidth={appLayout.isMobile ? '550px' : 'auto'}
                      alignContent={'centerLeft'}
                    >
                      {items.map((menu, i) => {
                        return (
                          <HorizontalView key={i} alignContent={'centerLeft'} flexWrap={'nowrap'}>
                            <FooterMenu item={menu} />
                          </HorizontalView>
                        );
                      })}
                      {appLayout.isMobile && (
                        <HorizontalView
                          marginTop={'15px'}
                          fullWidth
                          height={'1px'}
                          background={AppColors.DARK}
                          opacity={0.3}
                        />
                      )}
                    </VerticalView>
                  </VerticalView>
                );
              })}
            </HorizontalView>
          )}
        </VerticalView>

        <CopyrightWrapper>{`© 2022 Dokodemo.,ltd. All rights reserved.`}</CopyrightWrapper>
      </VerticalView>
    </VerticalView>
  );
};

export default Footer;
