import React from 'react';
import { Routes, Route } from 'react-router';
import CMSUserDetailsContainer from '../containers/UserDetailsPage';
import CMSUserListContainer from '../containers/UsersSearchPage';
import { NotFoundContainer } from '../../../user-interface/not-found/containers/index';

export const UsersRouter = () => {
  return (
    <Routes>
      <Route index element={<CMSUserListContainer />} />
      <Route path="/:userId" element={<CMSUserDetailsContainer />} />
      <Route path="*" element={<NotFoundContainer />} />
    </Routes>
  );
};
