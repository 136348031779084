import { ArticleCategory } from 'helpers/const';
import * as yup from 'yup';

export const updateArticleValidatorSchema = yup.object().shape({
  category: yup.string().required('validator.requiredCategory').nullable(),
  thumbnail: yup.string().required('validator.requiredThumbnail').nullable(),
  title: yup
    .string()
    .required('validator.requiredTitle')
    .min(5, 'validator.articleTitleMinMax')
    .max(60, 'validator.articleTitleMinMax')
    .nullable(),
  description: yup
    .string()
    .required('validator.requiredDescription')
    .min(10, 'validator.articleDescriptionMinMax')
    .max(120, 'validator.articleDescriptionMinMax')
    .nullable(),
  content: yup.string().required('validator.requiredContent').min(10, 'validator.articleContentMinMax').nullable(),
  cover: yup.string().when('category', {
    is: ArticleCategory.Service,
    then: yup.string().required('validator.requiredCover').nullable(),
    otherwise: yup.string().nullable(),
  }),
});
