import React from 'react';
import { CMSArticleSearchComponent } from 'modules/cms-interface/article/components/ArticleSearchComponent';
import { useTranslationText } from 'locale';
import { useNavigate } from 'react-router';
import { useCreateArticle, useOperationSearchArticles, useDeleteArticle } from 'api/article/useArticleApi';
import { openNotification } from 'modules/common/vendors/Notification';
import { IArticle } from 'models/Article';
import { ArticleCategory } from 'helpers/const';

export const CmsArticleSearchPage = () => {
  const navigate = useNavigate();
  const { translation } = useTranslationText();

  const {
    onSearchArticles,
    onChangeLimit,
    onChangePage,
    onDeleteArticleInList,
    query,
    totalItems,
    articles,
    page,
    totalPages,
    loading,
  } = useOperationSearchArticles();
  const { onCreateArticle, loading: createLoading } = useCreateArticle();
  const { onDeleteArticle, loading: deleteLoading } = useDeleteArticle();

  const onSearchBy = (tags: string[], type: string) => {
    if (type === 'categories') {
      const defaultSearch = [ArticleCategory.Information, ArticleCategory.News, ArticleCategory.Notification];
      const categories = tags.length ? tags : defaultSearch;
      onSearchArticles({ ...query, ...{ [type]: categories } });
    } else {
      onSearchArticles({ ...query, ...{ [type]: tags } });
    }
  };

  const onCreate = async () => {
    const { article, error } = await onCreateArticle(ArticleCategory.News);
    if (article) {
      navigate(`/cms/articles/${article.id}`);
    } else {
      openNotification({
        type: 'error',
        message: error?.title,
        description: error?.message,
      });
    }
  };

  const onDelete = async (id: string) => {
    const result = await onDeleteArticle({ id });
    if (result.article) {
      onDeleteArticleInList({ id } as IArticle);
      openNotification({
        type: 'success',
        message: translation('message.delete'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: result.error?.title,
        description: result.error?.message,
      });
    }
  };

  const onDetails = (id: string) => {
    navigate(`/cms/articles/${id}`);
  };

  const onSearchKeyword = (keyword: string) => {
    onSearchArticles({ ...query, keyword });
  };

  return (
    <CMSArticleSearchComponent
      loading={loading}
      isServicePage={false}
      createLoading={createLoading}
      deleteLoading={deleteLoading}
      articles={articles}
      currentPage={page}
      totalItems={totalItems}
      totalPages={totalPages}
      onSearch={onSearchKeyword}
      onSearchBy={onSearchBy}
      onCreate={onCreate}
      onDetails={onDetails}
      onDelete={onDelete}
      onChangeLimit={onChangeLimit}
      onChangePage={onChangePage}
    />
  );
};
