import React, { useState } from 'react';
import { Div, VerticalView } from 'modules/common/vendors/Wrapper';
import { Image } from 'modules/common/vendors/Image';
import { Text } from 'modules/common/vendors/Text';
import { AppColors } from 'helpers';
import { useAppSetting } from 'helpers/useAppSetting';

type ServiceItemProps = {
  title: string;
  image: string;
  onClick(): void;
};

export const ServiceTab = (props: ServiceItemProps) => {
  const [isHovered, setHover] = useState(false);
  const { appLayout } = useAppSetting();
  const isMobile = appLayout.width < 768;
  const [width, setWidth] = React.useState(400);
  const titleHeight = isMobile ? 75 : 90;

  React.useEffect(() => {
    if (isMobile) {
      setWidth(appLayout.width * 0.8);
    } else {
      setWidth(appLayout.width > 1200 ? 400 : appLayout.width / 3);
    }
  }, [appLayout.width]);

  return (
    <VerticalView
      width={`${width}px`}
      height={`${(width / 3) * 4}px`}
      background={AppColors.WHITE}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      position="relative"
      onClick={props.onClick}
      border="1px solid #ddd"
      overflow="hidden"
      alignContent="topCenter"
    >
      <Div fullWidth height={`${titleHeight}px`} alignContent="center" zIndex={12} transition="color 0.3s">
        <Text
          fontSize={isMobile ? '23px' : '28px'}
          color={isHovered ? AppColors.WHITE : AppColors.BLACK}
          textAlign="center"
        >
          {props.title}
        </Text>
      </Div>
      <Div
        fullWidthHeight
        position="absolute"
        left="0"
        top={isHovered ? '0' : `${titleHeight}px`}
        transition="top 0.5s"
        zIndex={10}
      >
        <Image width="100%" height="100%" src={props.image} objectFit="cover" />
      </Div>
      <Div
        fullWidthHeight
        position="absolute"
        left="0"
        top="0"
        background={AppColors.BLACK}
        opacity={isHovered ? 0.4 : 0}
        zIndex={11}
        transition="opacity 0.5s"
      />
    </VerticalView>
  );
};
