import React from 'react';
import styled from 'styled-components';
import { HorizontalView } from 'modules/common/vendors/Wrapper';
import { Text } from 'modules/common/vendors/Text';
import { useAppSetting } from 'helpers/useAppSetting';

const TitleWrapper = styled.div`
  min-width: 150px;
  font-weight: bold;
  font-size: 18px;
`;
interface AboutProps {
  index: number;
  icon: string;
  label: string;
  value: string;
  onClick?: () => void;
}

export const AboutCard = (props: AboutProps) => {
  const { appTheme, appLayout } = useAppSetting();
  return (
    <HorizontalView
      fullWidth
      alignContent={'topLeft'}
      background={props.index % 2 === 0 ? '#F6F6F6' : appTheme.base.white}
      key={props.index}
      padding={'20px'}
    >
      <TitleWrapper>
        <Text>{props.label}</Text>
      </TitleWrapper>
      <HorizontalView
        width={appLayout.isMobile ? '100%' : 'calc(100% - 170px)'}
        alignContent={'centerLeft'}
        onClick={props.onClick}
      >
        <Text fontSize={'15px'} width={appLayout.isMobile ? '100%' : 'auto'} textAlign={'left'} whiteSpace="pre-line">
          {props.value}
        </Text>
      </HorizontalView>
    </HorizontalView>
  );
};
