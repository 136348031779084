import React from 'react';
import { useGetContact } from 'api/contact/useContactApi';
import { useParams } from 'react-router';
import { CMSContactDetailsComponent } from '../components/CmsContactDetailsComponent';

export const CmsContactDetailsPage = () => {
  const { contactId } = useParams();
  const { contact, error, loading } = useGetContact(contactId);

  return <CMSContactDetailsComponent loading={loading} error={error} contact={contact} />;
};
