import gql from 'graphql-tag';

export const GET_HOME = gql`
  query getHomePage {
    getHomepage {
      news {
        id
        category
        thumbnail
        title
        seoname
        description
        createdTime
        updatedTime
      }
      services {
        id
        category
        thumbnail
        cover
        title
        seoname
        description
        createdTime
        updatedTime
      }
    }
  }
`;
