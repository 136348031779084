import { userMutations, userQueries } from './index';
import {
  IUser,
  ILoginUserInput,
  IOperationUpdateUserInput,
  IOperationCreateUserInput,
  IOperationDeleteUserInput,
  IOperationGetUserInput,
  IOperationSearchUsersInput,
  IOperationResetUserPasswordInput,
} from 'models/User';
import { IError } from 'models/Models';
import { useTranslationText } from 'locale';
import { useBaseSearchQuery } from 'api/base/useBaseSearchQuery';
import { useBaseGetQuery } from 'api/base/useBaseGetQuery';
import { useEffect, useMemo } from 'react';
import { ErrorCodes } from 'helpers/const';
import { useBaseMutation } from 'api/base/useBaseMutation';
import { ISortDirection } from 'models/types';

export const useLoginUser = () => {
  const { getError } = useTranslationText();
  const { mutation, loading } = useBaseMutation<ILoginUserInput, { loginUser: IUser }>({
    mutation: userMutations.LOG_IN,
  });

  async function login(input: ILoginUserInput): Promise<{ user?: IUser; error?: IError }> {
    const { data, error } = await mutation(input);
    if (error) {
      switch (error.code) {
        case ErrorCodes.ERROR_CODE_INVALID_USERNAME_OR_PASSWORD:
          return { error: getError('INVALID_USERNAME_OR_PASSWORD') };
        case ErrorCodes.ERROR_CODE_ACCOUNT_TEMPORARY_LOCKED:
          return { error: getError('ACCOUNT_TEMPORARY_LOCKED') };
        case ErrorCodes.ERROR_CODE_ACCOUNT_IS_NOT_VERIFY:
          return { error: getError('ACCOUNT_IS_NOT_VERIFY') };
        case ErrorCodes.ERROR_CODE_ITEM_NOT_EXIST:
          return { error: getError('ACCOUNT_IS_NOT_EXIST') };
        case ErrorCodes.ERROR_CODE_INVALID_PASSWORD:
          return { error: getError('INVALID_PASSWORD') };
        default:
          return { error: getError('SYSTEM_ERROR') };
      }
    }

    if (!data?.loginUser) {
      return { error: getError('SYSTEM_ERROR') };
    }

    return { user: data?.loginUser };
  }

  return {
    loading: loading,
    loginUser: login,
  };
};

export const useLogoutUser = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation({
    mutation: userMutations.LOG_OUT,
  });

  async function onLogout(): Promise<{ error?: IError }> {
    const { error } = await mutation();
    if (error) return { error: getError('SYSTEM_ERROR') };

    return {};
  }

  return {
    loading,
    onLogout,
  };
};

export const useOperationCreateUser = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<IOperationCreateUserInput, { operationCreateUser: IUser }>({
    mutation: userMutations.CREATE,
  });

  async function onCreateUser(input: IOperationCreateUserInput): Promise<{ error?: IError; user?: IUser }> {
    const { data, error } = await mutation(input);

    if (error || !data?.operationCreateUser) {
      switch (error?.code) {
        case ErrorCodes.ERROR_CODE_ITEM_EXIST:
          return { error: getError('EMAIL_IS_EXIST') };
        default:
          return { error: getError('SYSTEM_ERROR') };
      }
    }
    return { user: data?.operationCreateUser };
  }

  return {
    loading,
    onCreateUser,
  };
};

export const useOperationUpdateUser = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<IOperationUpdateUserInput, IUser>({
    mutation: userMutations.UPDATE,
  });

  async function onUpdateUser(input: IOperationUpdateUserInput): Promise<{ error?: IError; user?: IUser }> {
    const { data, error } = await mutation(input);
    if (error) return { error: getError('USER_UPDATE_ERROR') };

    return { user: data };
  }

  return {
    loading,
    onUpdateUser,
  };
};

export const useOperationResetUserPassword = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<IOperationResetUserPasswordInput, IUser>({
    mutation: userMutations.RESET_PASS,
  });

  async function onResetUserPassword(
    input: IOperationResetUserPasswordInput,
  ): Promise<{ error?: IError; user?: IUser }> {
    const { data, error } = await mutation(input);
    if (error) return { error: getError('USER_RESET_PASS_ERROR') };

    return { user: data };
  }

  return {
    loading,
    onResetUserPassword,
  };
};

export const useOperationDeleteUser = () => {
  const { getError } = useTranslationText();

  const { mutation, loading } = useBaseMutation<IOperationDeleteUserInput, IUser>({
    mutation: userMutations.DELETE,
  });

  async function onDeleteUser(input: IOperationDeleteUserInput): Promise<{ error?: IError; User?: IUser }> {
    const { data, error } = await mutation(input);
    if (error) return { error: getError('User_DELETE_ERROR') };

    return { User: data };
  }

  return {
    loading,
    onDeleteUser,
  };
};

export const useOperationGetUser = () => {
  const { getError } = useTranslationText();

  const result = useBaseGetQuery<IOperationGetUserInput, IUser>({
    fetchPolicy: 'cache-and-network',
    query: userQueries.GET,
  });

  const errorHanle = useMemo(() => {
    if (!result.loading && result.error) {
      switch (result.error.code) {
        case ErrorCodes.ERROR_CODE_ITEM_NOT_EXIST:
          return { error: getError('User_IS_NOT_EXIST') };
        default:
          return { error: getError('SYSTEM_ERROR') };
      }
    }
    return null;
  }, [result.error]);

  return {
    loading: result.loading,
    error: errorHanle,
    user: result.item,
    onGetUser: result.getQuery,
  };
};

export const useOperationSearchUsers = () => {
  const query = useBaseSearchQuery<IOperationSearchUsersInput, IUser>({
    defaulInput: {
      page: 1,
      sortDirection: ISortDirection.Asc,
      limit: 30,
    } as IOperationSearchUsersInput,
    fetchPolicy: 'cache-and-network',
    query: userQueries.SEARCH_USER,
  });

  /**
   * Call api
   */
  useEffect(() => {
    query.onSearch(query.input);
  }, []);

  return {
    loading: query.loading,
    error: query.error,
    query: query.input,
    page: query.page,
    hasMore: query.hasMore,
    totalItems: query.totalItems,
    totalPages: query.totalPages,
    limit: query.limit,
    users: query.items,
    onChangePage: query.onChangePage,
    onChangeLimit: query.onChangeLimit,
    onSearchUsers: query.onSearch,
    onLoadMoreUsers: query.onLoadMore,
    onDeleteUserInList: query.onDeleteItem,
  };
};
