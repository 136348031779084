import gql from 'graphql-tag';

export const GET = gql`
  query getCompany {
    getCompany {
      name
      logo
      description
      foundation
      representativeName
      tax
      postalCode
      stateOrCityName
      streetAddress
      buildingNameAndRoomName
      telephone
      businessContent
      locationMapUrl
    }
  }
`;

export const GETPRIVACY = gql`
  query getCompany {
    getCompany {
      privacyPolicy
    }
  }
`;

export const GET_FOOTER = gql`
  query getCompany {
    getCompany {
      name
      description
      telephone
    }
  }
`;
