import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { Div, HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { useTranslationText } from 'locale';
import { IOptionType } from 'models/IOptionType';
import { Text } from 'modules/common/vendors/Text';
import { useAppSetting } from 'helpers/useAppSetting';
import { AppColors } from 'helpers';
import { dateFormat, isHoliday } from 'helpers/date.utils';

import { IConType, SVGIcon } from 'modules/common/vendors/Icon';

dayjs.extend(weekday);
dayjs().weekday(1);
const monday = dayjs().weekday(1).toDate();

const Hover = styled.div<{
  active?: boolean;
  isPicked?: boolean;
  invalid?: boolean;
  isMobile?: boolean;
}>`
  width: 100%;
  height: 100%;
  opacity: 0.6;
  cursor: ${(props) => (props.isPicked || props.invalid || !props.active ? 'auto' : 'pointer')};

  ${(props) => {
    if (props.isPicked) {
      return `
        background: ${AppColors.ORANGE};
      `;
    }
    if (props.invalid) {
      return `
        background: repeating-linear-gradient(45deg, ${AppColors.GRAY_LIGHT}, ${AppColors.GRAY_LIGHT} 10px, ${AppColors.GRAY_LIGHT_F2} 10px, ${AppColors.GRAY_LIGHT_F2} 20px);
      `;
    }
    return `background: ${AppColors.BODY_BACKGROUND};`;
  }}

  :hover {
    ${(props) => {
      if (props.active) {
        return `
          opacity: 1;
          background: ${AppColors.GRASSY_GREEN};
          `;
      }
      return '';
    }}
  }
`;

const Arrow = styled.div<{ disable?: boolean }>`
  width: 100%;
  height: 100%;
  :hover {
    ${(props) => {
      if (!props.disable) {
        return `
          opacity: 1;
          background: ${AppColors.GRASSY_GREEN};
          color:white;
          `;
      }
      return '';
    }}
  }
`;

const ArrowButton = (props: { icon: IConType; disabled?: boolean; onClick: () => void }) => {
  return (
    <Div width={'30px'} height={'30px'} borderRadius={'15px'} overflow={'hidden'}>
      <Arrow disable={props.disabled}>
        <Div fullWidthHeight alignContent={'center'} onClick={props.onClick} opacity={props.disabled ? 0.3 : 1}>
          <SVGIcon size={'20px'} name={props.icon} />
        </Div>
      </Arrow>
    </Div>
  );
};

export type ScheduleEvent = {
  id?: string;
  name?: string;
  datetime?: string;
};

export type SchedulePickerProps = {
  events?: ScheduleEvent[];
  disable?: boolean;
  isPreview?: boolean;
  onPicker?: (value: string, event?: ScheduleEvent) => void;
};

export const SchedulePicker = (props: SchedulePickerProps) => {
  const { getTypes } = useTranslationText();
  const { lang, appLayout, appSize } = useAppSetting();
  const [startDay, setStartDay] = React.useState(monday);
  const [isNextWeek, setNextWeek] = React.useState(false);
  const [timePicker, setTimePicker] = React.useState<string>('');

  function daysOfWeek(monday: Date) {
    const days: Date[] = [];
    days.push(monday);
    for (let i = 1; i < 7; i++) {
      days.push(dayjs(monday).add(i, 'day').toDate());
    }
    return days;
  }

  const days: IOptionType[] = getTypes('daysOfWeek');

  const workingTimes = [
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
  ];

  const allDaysOfWeek = daysOfWeek(startDay);

  const validPicker = (date: Date, time: string) => {
    const currentDate = new Date();
    const datetime = dayjs(`${dateFormat(date.toISOString(), 'YYYY/MM/DD')} ${time}`).toDate();

    if (datetime.getTime() >= currentDate.getTime()) {
      return true;
    }
    return false;
  };

  const isHasEvent = (date: string, time: string) => {
    const found =
      props.events &&
      props.events?.find((event) => {
        return `${date} ${time}` === event.datetime;
      });
    return found;
  };

  const isSelected = (date: string, time: string) => {
    return `${date} ${time}` === timePicker;
  };

  const onPressArrowLeft = () => {
    const lastMonday = dayjs(startDay).subtract(7, 'day');
    if (isNextWeek || props.isPreview) {
      setNextWeek(false);
      setStartDay(lastMonday.toDate());
    }
  };

  const onPressArrowRight = () => {
    if (!isNextWeek || props.isPreview) {
      const nextMonday = dayjs(startDay).add(7, 'day');
      setNextWeek(true);
      setStartDay(nextMonday.toDate());
    }
  };

  const getTitleCalendar = () => {
    const startDate = allDaysOfWeek[0];
    const startDayVal = dateFormat(startDate.toISOString(), 'MM');
    const endDate = allDaysOfWeek[allDaysOfWeek.length - 1];
    const endDayVal = dateFormat(endDate.toISOString(), 'MM');

    if (startDayVal !== endDayVal) {
      return `${dateFormat(startDate.toISOString(), 'MM/DD')} - ${dateFormat(endDate.toISOString(), 'MM/DD')}`;
    }

    return dateFormat(startDate.toISOString(), lang === 'ja' ? 'YYYY MMMM D' : 'MMMM D, YYYY');
  };

  return (
    <VerticalView fullWidth alignContent={'topCenter'}>
      <HorizontalView marginBottom={'20px'}>
        <Div marginRight={'10px'}>
          <ArrowButton
            disabled={!props.isPreview && (!isNextWeek || props.disable)}
            icon={'arrow-left'}
            onClick={onPressArrowLeft}
          />
        </Div>

        <Text fontWeight={700} fontSize={appSize.h2}>
          {getTitleCalendar()}
        </Text>
        <Div marginLeft={'10px'}>
          <ArrowButton
            disabled={!props.isPreview && (isNextWeek || props.disable)}
            icon={'arrow-right'}
            onClick={onPressArrowRight}
          />
        </Div>
      </HorizontalView>

      <HorizontalView fullWidth>
        <Div width={`${100 / 8}%`}>
          <VerticalView fullWidth alignContent="center">
            <Div fullWidth height={'55px'} padding={'1px'}>
              <Div fullWidthHeight background={AppColors.tableTime.bg}>
                <Text fontWeight={500} fontSize={appSize.h3}></Text>
              </Div>
            </Div>
            {workingTimes.map((time, index) => {
              return (
                <Div fullWidth height={'35px'} key={index} padding={'1px'}>
                  <Div fullWidthHeight background={AppColors.tableTime.bg} alignContent="center">
                    <Text
                      fontWeight={400}
                      fontSize={appLayout.isMobile ? appSize.xs : appSize.h4}
                      color={AppColors.WHITE}
                    >
                      {time}
                    </Text>
                  </Div>
                </Div>
              );
            })}
          </VerticalView>
        </Div>
        {days.map((item, i) => {
          const holiday = isHoliday(allDaysOfWeek[i].toISOString());
          const dateColumnVal = dateFormat(allDaysOfWeek[i].toISOString(), 'YYYY/MM/DD');
          let isDiffMonth = false;
          if (i > 0) {
            const prevVal = dateFormat(allDaysOfWeek[i - 1].toISOString(), 'MM');
            const curVal = dateFormat(allDaysOfWeek[i].toISOString(), 'MM');
            if (prevVal !== curVal) {
              isDiffMonth = true;
            }
          }
          return (
            <Div width={`${100 / 8}%`} key={i}>
              <VerticalView fullWidth>
                <Div fullWidth key={i} height={'55px'} padding={'1px'}>
                  <Div fullWidthHeight alignContent="center" background={AppColors.tableTime.bg}>
                    <VerticalView>
                      <Text fontWeight={500} fontSize={appSize.h4} color={holiday ? AppColors.RED : AppColors.WHITE}>
                        {item.label}
                      </Text>
                      <Text fontWeight={500} fontSize={appSize.xs} color={holiday ? AppColors.RED : AppColors.WHITE}>
                        ({` ${dateFormat(allDaysOfWeek[i].toISOString(), isDiffMonth ? 'MM/DD' : 'DD')} `})
                      </Text>
                    </VerticalView>
                  </Div>
                </Div>
                {workingTimes.map((time, index) => {
                  const selected = Boolean(isSelected(dateColumnVal, time) && !props.isPreview);
                  const isValid = validPicker(allDaysOfWeek[i], time);
                  const event = isHasEvent(dateColumnVal, time);
                  const isActive = props.isPreview || (!(holiday || Boolean(event) || !isValid) && !props.disable);
                  return (
                    <Div fullWidth height={'35px'} key={index} padding={'1px'}>
                      <Hover
                        active={props.isPreview ? isActive && Boolean(event) : isActive}
                        invalid={props.isPreview ? false : !isValid || Boolean(event) || holiday}
                        isMobile={appLayout.isMobile}
                      >
                        <Div
                          alignContent="center"
                          fullWidthHeight
                          background={selected ? AppColors.GRASSY_GREEN : AppColors.TRANSPARENT}
                          onClick={() => {
                            if (props.isPreview && event) {
                              props.onPicker && props.onPicker(`${dateColumnVal} ${time}`, event);
                            } else if (isValid && !props.disable && !holiday) {
                              setTimePicker(`${dateColumnVal} ${time}`);
                              props.onPicker && props.onPicker(`${dateColumnVal} ${time}`, event);
                            }
                          }}
                        >
                          {selected && !props.isPreview && <SVGIcon name={'check-circle'} color={AppColors.WHITE} />}
                          {!selected && !props.isPreview && <>{isValid && !event && !holiday ? '◯' : '✖️'}</>}
                          {props.isPreview && Boolean(event) && (
                            <Div fullWidthHeight background={AppColors.GRASSY_GREEN}>
                              <Text fontSize={'9px'} color={AppColors.WHITE}>
                                {event?.name}
                              </Text>
                            </Div>
                          )}
                        </Div>
                      </Hover>
                    </Div>
                  );
                })}
              </VerticalView>
            </Div>
          );
        })}
      </HorizontalView>
    </VerticalView>
  );
};
