import React from 'react';
import { Button } from 'modules/common/vendors/Button';
import { useAppSetting } from 'helpers/useAppSetting';
import { Div } from 'modules/common/vendors/Wrapper';
import { Image } from 'modules/common/vendors/Image';

type BackButtonProps = {
  onClick?: () => void;
};
export const BackButton = (props: BackButtonProps) => {
  const { appTheme } = useAppSetting();
  const [color, setColor] = React.useState(appTheme.base.base);
  const [bg, setBg] = React.useState('/images/back_button.png');

  return (
    <Div position={'relative'} paddingLeft={'10px'}>
      <Div position={'absolute'} top={'0'} left={'0'} transition={'children 0.3s'}>
        <Image width={'80px'} src={bg} />
      </Div>
      <Button
        title={'back'}
        fontSize={'16px'}
        preixIcon={'arrow-left'}
        borderRadius={'5px'}
        height={'40px'}
        color={color}
        onClick={props.onClick}
        onMouseEnter={() => {
          setBg('/images/back_button_primary.png');
          setColor(appTheme.base.white);
        }}
        onMouseLeave={() => {
          setBg('/images/back_button.png');
          setColor(appTheme.base.base);
        }}
      />
    </Div>
  );
};
