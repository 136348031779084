import React from 'react';
import NewsComponent from 'modules/user-interface/article/components/ArticleListPage';
import { useSearchArticles } from 'api/article/useArticleApi';

export const ArticleListPage = () => {
  const { loading, articles, onLoadMoreArticles, hasMore, onSearchArticles } = useSearchArticles();

  return (
    <NewsComponent
      loading={loading}
      hasLoadMore={hasMore}
      articles={articles}
      onLoadMore={() => {
        onLoadMoreArticles();
      }}
      onChangeCategory={(cate) => {
        onSearchArticles({
          page: 1,
          limit: 10,
          categories: [cate],
        });
      }}
    />
  );
};
