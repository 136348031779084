import React from 'react';
import { Div, HorizontalView } from 'modules/common/vendors/Wrapper';
import { AppColors } from 'helpers';
import { IArticle } from 'models/Article';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useResize } from 'modules/common/vendors/Hooks/useResize';
import { useAppSetting } from 'helpers/useAppSetting';
import { Carousel } from 'react-responsive-carousel';
import { PromotionCard } from './PromotionCard';
import { useNavigate } from 'react-router';

type TopBannerProps = {
  lang: string;
  services: IArticle[];
};

export const PromotionPreview = (props: TopBannerProps) => {
  const boxRef = React.useRef<HTMLDivElement | null>(null);
  const { appLayout } = useAppSetting();
  const [height, setHeight] = React.useState(350);
  const { width } = useResize(boxRef);

  React.useEffect(() => {
    width > 100 && setHeight(width / (appLayout.isMobile ? 3.0 : 3.0));
  }, [width, appLayout.isMobile]);

  return (
    <HorizontalView
      fullWidth
      ref={boxRef}
      position={'relative'}
      height={`${height}px`}
      flexWrap={'nowrap'}
      background={AppColors.TRANSPARENT}
      alignContent={'centerLeft'}
    >
      <Div width={'100%'} height="100%" alignContent="center">
        <Carousel
          width="100vw"
          showStatus={false}
          showArrows={false}
          showThumbs={false}
          autoFocus
          autoPlay
          infiniteLoop
          showIndicators={props.services ? props.services.length > 1 : false}
        >
          {props.services?.map((item, index) => {
            return (
              <Div
                width="100vw"
                height={`${height}px`}
                key={index}
                flex={'1'}
                position="relative"
                // onClick={() => {
                //   navigate(`/services/${item.id}`);
                // }}
              >
                <PromotionCard cardHeight={height} featurePost={item} />
              </Div>
            );
          })}
        </Carousel>
      </Div>
    </HorizontalView>
  );
};
