import React from 'react';
import styled from 'styled-components';
import { HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { AppColors } from 'helpers';
import { IConType, SVGIcon } from 'modules/common/vendors/Icon';
import { Text } from 'modules/common/vendors/Text';
import { useTranslationText } from 'locale';
import { IUser } from 'models/User';
import { DropdownProps, ItemProps } from 'modules/common/vendors/NaviDropdown';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from 'modules/common/components/Modals/ConfirmModal';

const SideBarWrapper = styled.div<{ open: boolean; left: number; width: number }>`
  position: absolute;
  left: ${(props) => -props.left}px;
  width: ${(props) => props.width}px;
  height: 100vh;
  transition: 0.2s;
  background: ${AppColors.sidebar.bg};
  z-index: 25;
`;

const SidebarContentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
`;

const SidebarMenuWrapper = styled.div<{ background?: string }>`
  width: 100%;
  background: ${(props) => (props.background ? props.background : AppColors.sidebar.menu)};
  cursor: pointer;
`;
const MenuWrapper = styled.button`
  width: 100%;
  padding-left:10px;
  padding-right:10px;
  outline: none;
  border: none;
  border-style:none:
  cursor:pointer;
  cursor: pointer;
  background-color:transparent;
`;
const SubMenuWrapper = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const SubItemWrapper = styled.div`
  width: 100%;
  height: 50px;
  padding-left: 20px;
  background: ${AppColors.sidebar.menu};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const ItemIcon = styled.div<{ left?: number; right?: number }>`
  margin-left: ${(props) => (props.left ? props.left : 0)}px;
  margin-right: ${(props) => (props.right ? props.right : 0)}px;
  color: ${AppColors.WHITE};
  cursor: pointer;
`;

const ItemTitle = styled.div`
  color: ${AppColors.sidebar.text};
`;

const Dot = styled.div`
  margin-right: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: white;
`;

export const Menu = (props: {
  status: { [key: string]: any };
  item: DropdownProps;
  onTab: (item: DropdownProps) => void;
  onTabSubMenu: (subItem: ItemProps) => void;
}) => {
  const { item, status, onTab, onTabSubMenu } = props;

  const color = status[item.url || 'no'] ? 'orange' : AppColors.sidebar.text;
  return (
    <VerticalView fullWidth alignContent={'centerLeft'}>
      <MenuWrapper
        onClick={(e) => {
          e.preventDefault();
          onTab(item);
        }}
      >
        <HorizontalView fullWidth height={'50px'} padding={'10px'} alignContent={'centerBetween'}>
          <HorizontalView alignContent={'centerLeft'}>
            <ItemIcon right={30}>
              <SVGIcon name={item.prefixIcon as IConType} color={color} />
            </ItemIcon>

            <ItemTitle>
              <Text color={color}>{item.label}</Text>
            </ItemTitle>
          </HorizontalView>

          <ItemIcon style={{ margin: '0px' }}>
            {item.subMenus?.length && <SVGIcon name={status[item.label!] ? 'arrow-up' : 'arrow-down'} color={color} />}
          </ItemIcon>
        </HorizontalView>
      </MenuWrapper>

      {item.subMenus && status[item.label!] && (
        <SubMenuWrapper>
          {item.subMenus.map((subItem, subIndex) => {
            return (
              <SubItemWrapper
                key={subIndex}
                onClick={async (e) => {
                  e.preventDefault();
                  onTabSubMenu(subItem);
                }}
              >
                <HorizontalView fullWidthHeight padding={'15px'} alignContent={'centerLeft'}>
                  <Dot />
                  <ItemTitle>
                    <Text color={color}>{subItem.label}</Text>
                  </ItemTitle>
                </HorizontalView>
              </SubItemWrapper>
            );
          })}
        </SubMenuWrapper>
      )}
    </VerticalView>
  );
};

type HeaderSearchBarProps = {
  auth?: IUser;
  open: boolean;
  onClose: () => void;
};

export const MobileCmsSideBar = (props: HeaderSearchBarProps) => {
  const { getTypes } = useTranslationText();
  const [status, setStatus] = React.useState<{ [key: string]: any }>({});
  const [active, setActive] = React.useState(false);
  const [confirmLogout, setConfirmLogout] = React.useState(false);
  const navisItems: DropdownProps[] = getTypes('cmsSidebarMenus');
  const navigate = useNavigate();
  // const {
  //   logout: { logoutUser },
  // } = useUserApi();

  React.useEffect(() => {
    setActive(props.open);
  }, [props.open]);

  async function onClose() {
    setActive(false);
    props.onClose();
  }

  const onTab = (item: DropdownProps) => {
    if (item.url?.includes('logout')) {
      setConfirmLogout(true);
    } else {
      if (!item.subMenus?.length) {
        item.url && navigate(item.url);
        setStatus({ url: item.url } as any);
        onClose();
      } else {
        const st = { ...status };
        st[item.label!] = !st[item.label!];
        setStatus(st);
      }
    }
  };

  const onTabSubMenu = async (subItem: ItemProps) => {
    if (!subItem.url) {
      return;
    }
    await onClose();
    subItem.url && navigate(subItem.url);
  };

  return (
    <VerticalView
      width={'100vw'}
      height={'100vh'}
      zIndex={20}
      left={'0px'}
      top={'0px'}
      position={'fixed'}
      display={props.open ? 'flex' : 'none'}
    >
      <VerticalView fullWidthHeight position={'relative'}>
        <VerticalView
          width={'100vw'}
          height={'100vh'}
          zIndex={20}
          left={'0px'}
          top={'0px'}
          background={AppColors.BLACK}
          opacity={0.4}
          position={'absolute'}
          onClick={async () => {
            onClose();
          }}
          display={active ? 'flex' : 'none'}
          transition={'ease-in'}
        />
        <SideBarWrapper open={props.open} width={250} left={active ? 0 : 250}>
          <SidebarContentWrapper>
            <VerticalView fullWidth paddingTop={'20px'} alignContent={'centerLeft'} overflowY={'scroll'}>
              {navisItems.map((item, index) => {
                return (
                  <SidebarMenuWrapper key={index} background={AppColors.sidebar.bg}>
                    <Menu status={status} item={item} onTab={onTab} onTabSubMenu={onTabSubMenu} />
                  </SidebarMenuWrapper>
                );
              })}
            </VerticalView>
          </SidebarContentWrapper>
        </SideBarWrapper>
      </VerticalView>
      <ConfirmModal
        open={confirmLogout}
        title={'modals.confirmLogout.title'}
        cancelTitle={'modals.confirmLogout.close'}
        confirmTitle={'modals.confirmLogout.confirm'}
        onClose={() => setConfirmLogout(false)}
        onConfirm={async () => {
          setConfirmLogout(false);
          // await logoutUser();
          onClose();
          navigate('/');
        }}
      />
    </VerticalView>
  );
};
