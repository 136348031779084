import React from 'react';
import { CMSPrivacyComponent } from '../../components/company-privacy';
import { useGetCompanyPrivacy, useUpdateCompany } from 'api/company/useCompanyApi';
import { IUpdateCompanyInput } from 'models/Company';
import { openNotification } from 'modules/common/vendors/Notification';
import { useTranslationText } from 'locale';

export const CMSPrivacyContainer = () => {
  const { loading, company, onGetCompanyPrivacy } = useGetCompanyPrivacy();
  const { translation } = useTranslationText();
  const { onUpdateCompany, loading: updateLoading } = useUpdateCompany();

  React.useEffect(() => {
    onGetCompanyPrivacy();
  }, []);

  const update = async (data: string) => {
    const update = { privacyPolicy: data } as IUpdateCompanyInput;
    const result = await onUpdateCompany(update);
    if (!result.error) {
      openNotification({
        type: 'success',
        message: translation('message.update'),
        description: translation('message.success'),
      });
    } else {
      openNotification({
        type: 'error',
        message: translation('message.update'),
        description: translation('message.error'),
      });
    }
  };
  return (
    <CMSPrivacyComponent
      loading={loading}
      updateLoading={updateLoading}
      companyPrivacy={company ? (company.privacyPolicy as string) : ''}
      onSave={(data) => update(data)}
    />
  );
};

export default CMSPrivacyContainer;
