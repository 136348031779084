import React from 'react';
import Table from 'modules/common/components/Table';
import { Confirm } from 'modules/common/components/Modals/ConfirmModal';
import { useTranslationText } from 'locale';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AppColors } from 'helpers';
import { Div, HorizontalView, VerticalView } from 'modules/common/vendors/Wrapper';
import { Button } from 'modules/common/vendors/Button';
import { Text } from 'modules/common/vendors/Text';
import { Image } from 'modules/common/vendors/Image';
import { IArticle } from 'models/Article';
import { Label } from 'modules/common/vendors/Label';
import { dateFormat } from 'helpers/date.utils';
import { Tag } from 'modules/common/components/Tag';
import { articleCategories } from 'helpers/const';
import { SVGIcon } from 'modules/common/vendors/Icon';

export function columns(
  translation: (path: string) => string,
  onDelete: (id: string) => void,
  onDetails: (id: string) => void,
) {
  const tableColumns: Array<ColumnDescription<IArticle>> = [
    {
      dataField: 'thumbnail',
      text: translation('searchArticles.thumbnail'),
      headerStyle: () => {
        return { width: '10%', textAlign: 'center', whiteSpace: 'unset', padding: '10px' };
      },
      formatter: (_, row: IArticle) => {
        return (
          <HorizontalView fullWidth alignContent={'center'} padding={'10px'}>
            <Image
              width={'70px'}
              height={'70px'}
              borderRadius={'5px'}
              objectFit={'cover'}
              src={row.thumbnail?.includes('http') ? row.thumbnail : '/images/default-thumbnail.jpeg'}
            />
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: () => {
        return { width: '25%', textAlign: 'left', whiteSpace: 'unset', padding: '10px' };
      },
      formatter: (_, row: IArticle) => {
        return (
          <a
            href={`/cms/users/${row.id}`}
            color={AppColors.PRIMARY}
            onClick={(event) => {
              event.preventDefault();
              onDetails(row.id);
            }}
          >
            <HorizontalView fullWidth alignContent={'centerBetween'} padding={'10px'} flexWrap={'nowrap'}>
              {row.id}
              <Div width={'30px'}>
                <SVGIcon size={'20px'} name={'arrow-right'} color={AppColors.PRIMARY} />
              </Div>
            </HorizontalView>
          </a>
        );
      },
    },
    {
      dataField: 'title',
      text: translation('searchArticles.title'),
      headerStyle: () => {
        return { width: '40%', textAlign: 'left', padding: '10px' };
      },
    },
    {
      dataField: 'category',
      text: translation('searchArticles.category'),
      headerStyle: () => {
        return { width: '15%', textAlign: 'center', minWidth: '140px', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IArticle) => {
        return (
          <HorizontalView fullWidth alignContent={'center'} minWidth={'100px'}>
            <Tag value={row.category} label={articleCategories.find((ca) => ca.value === row.category)?.label || ''} />
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'public',
      text: translation('searchArticles.public'),
      headerStyle: () => {
        return { width: '10%', textAlign: 'center', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IArticle) => {
        return (
          <HorizontalView fullWidth alignContent={'center'}>
            <Label
              color={row.isPublic ? AppColors.GRASSY_GREEN : AppColors.RED}
              label={row.isPublic ? 'statusCollection.public' : 'statusCollection.unpublic'}
            />
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'createdTime',
      text: translation('searchArticles.date'),
      headerStyle: () => {
        return { width: '20%', textAlign: 'center', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row: IArticle) => {
        return (
          <HorizontalView fullWidth alignContent={'center'}>
            <Text color={AppColors.DARK}>{dateFormat(row.updatedTime || '', 'MM/DD HH:mm')}</Text>
          </HorizontalView>
        );
      },
    },
    {
      dataField: 'menu',
      csvExport: false,
      text: '',
      headerStyle: () => {
        return { width: '10%', maxWidth: '80px', textAlign: 'center', padding: '10px' };
      },
      // eslint-disable-next-line react/display-name
      formatter: (_, row) => {
        return (
          <HorizontalView fullWidth alignContent={'centerAround'} padding={'5px'}>
            <Confirm
              title={'searchArticles.alertDelete.message'}
              leftText={'searchArticles.alertDelete.close'}
              rightText={'searchArticles.alertDelete.delete'}
              onConfirm={() => {
                onDelete(row.id);
              }}
            >
              <Button
                width={'35px'}
                height={'35px'}
                preixIcon={'trash'}
                borderRadius={'4px'}
                color={AppColors.WHITE}
                background={AppColors.RED}
              />
            </Confirm>
          </HorizontalView>
        );
      },
    },
  ];

  return tableColumns;
}

interface TableProps {
  loading: boolean;
  articles: IArticle[];
  onCreate?: () => void;
  onDetails: (id: string) => void;
  onDelete: (id: string) => void;
}

export const WebTableView = (props: TableProps) => {
  const { translation } = useTranslationText();

  return (
    <VerticalView fullWidth minWidth={'1200px'}>
      <Table
        loading={props.loading}
        keyField={'id'}
        data={props.articles ? props.articles : []}
        bordered={false}
        striped={true}
        hover={true}
        condensed={true}
        columns={columns(translation, props.onDelete, props.onDetails)}
      />
    </VerticalView>
  );
};
