import { useGetCompany } from 'api/company/useCompanyApi';
import React from 'react';
import { AboutComponent } from '../components';

export const AboutContainer = () => {
  const { loading, company, onGetCompany } = useGetCompany();

  React.useEffect(() => {
    onGetCompany();
  }, []);

  return <AboutComponent loading={loading} company={company} />;
};

export default AboutContainer;
