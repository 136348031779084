export const typeList = {
  languages: [
    {
      label: '日本語',
      value: 'ja',
    },
    {
      label: 'ベトナム語',
      value: 'vi',
    },
  ],
  test: {
    name: 'asd',
  },
  questionTypes: [
    {
      label: 'グループ',
      value: 'GROUP',
    },
    {
      label: '単一',
      value: 'SINGLE',
    },
  ],
  contactList: [
    {
      label: 'ビジネスマッチングサービスについてのお問合わせ',
      value: 'ビジネスマッチングサービスについてのお問合わせ',
    },
    {
      label: 'TOKUMAプラットフォームについてのお問合わせ',
      value: 'TOKUMAプラットフォームについてのお問合わせ',
    },
    {
      label: '健康食品・化粧品販売事業についてのお問合わせ',
      value: '健康食品・化粧品販売事業についてのお問合わせ',
    },
    {
      label: 'その他のお問合わせ',
      value: 'その他のお問合わせ',
    },
  ],
  articleCategories: [
    {
      label: 'ニュース',
      value: 'News',
    },
    {
      label: '情報',
      value: 'Information',
    },
    {
      label: '通知',
      value: 'Notification',
    },
  ],
  serviceTypes: [
    {
      label: 'ニュース',
      value: 'NEWS',
    },
    {
      label: 'サービス',
      value: 'SERVICE',
    },
  ],

  footerMenu: [
    [
      {
        type: 'header',
        title: '運営会社',
      },
      {
        type: 'link',
        title: '企業情報',
        url: '/about',
      },
      {
        type: 'link',
        title: '使用条件',
        url: '/privacy',
      },
      {
        type: 'link',
        title: 'お問い合わせ',
        url: '/contact',
      },
    ],
    [
      {
        type: 'header',
        title: 'メニュー',
      },
      {
        type: 'link',
        title: 'サービス',
        url: '/services',
      },
      {
        type: 'link',
        title: 'ニュース',
        url: '/articles',
      },
    ],
  ],
  genders: [
    {
      label: '女性',
      value: 'FEMALE',
    },
    {
      label: '男性',
      value: 'MALE',
    },
  ],
  userDetailsTabs: [
    {
      label: '情報',
      value: 'UserInformation',
    },
    {
      label: 'コース',
      value: 'UserCourse',
    },
  ],
  adminDetailsTabs: [
    {
      label: '情報',
      value: 'UserInformation',
    },
    {
      label: 'コース',
      value: 'UserCourse',
    },
    {
      label: '権限データ',
      value: 'UserIdentity',
    },
  ],
  yesNoTypes: [
    {
      label: 'いいえ',
      value: false,
    },
    {
      label: 'はい',
      value: true,
    },
  ],
  status: [
    {
      label: 'アクティブ',
      value: true,
    },
    {
      label: '非アクティブ',
      value: false,
    },
  ],
  examSegmentItems: [
    {
      label: 'シングル',
      value: 'single',
    },
    {
      label: 'すべて',
      value: 'all',
    },
  ],
  cmsSidebarMenus: [
    {
      label: 'ユーザー管理',
      prefixIcon: 'user',
      url: '/cms/users',
    },
    {
      prefixIcon: 'file',
      label: '投稿',
      url: '/cms/articles',
    },
    {
      prefixIcon: 'case',
      label: 'サービス',
      url: '/cms/services',
    },
    {
      prefixIcon: 'media',
      label: 'メディアセンター',
      url: '/cms/medias',
    },
    {
      prefixIcon: 'email',
      label: 'コンタクト',
      url: '/cms/contacts',
    },

    {
      prefixIcon: 'setting',
      label: '会社',
      url: '/cms/setting',
      subMenus: [
        {
          label: '使用条件',
          url: '/cms/company/company-privacy',
        },
        {
          label: '運営会社',
          url: '/cms/company/company-profile',
        },
      ],
    },
    {
      prefixIcon: 'logout',
      label: 'ログアウト',
      url: 'logout',
    },
  ],

  userRoles: [
    {
      label: '管理者',
      value: 'ADMIN',
    },
    {
      label: 'メンバー',
      value: 'USER',
    },
    {
      label: '著者',
      value: 'AUTHOR',
    },
  ],
  segmentPublic: [
    {
      label: '公開',
      value: true,
    },
    {
      label: '非公開',
      value: false,
    },
  ],
  mediaGroup: [
    {
      label: '画像',
      value: 'image',
    },
    {
      label: 'ビデオ',
      value: 'video',
    },
    {
      label: 'オーディオ',
      value: 'audio',
    },
  ],
  daysOfWeek: [
    {
      label: '月',
      value: 'monday',
    },
    {
      label: '火',
      value: 'tuesday',
    },
    {
      label: '水',
      value: 'wednesday',
    },
    {
      label: '木',
      value: 'thursday',
    },
    {
      label: '金',
      value: 'friday',
    },
    {
      label: '土',
      value: 'saturday',
    },
    {
      label: '日',
      value: 'sunday',
    },
  ],
  limitOptions: [
    {
      label: '30件',
      value: 30,
    },
    {
      label: '50件',
      value: 50,
    },
    {
      label: '100件',
      value: 100,
    },
  ],
};
