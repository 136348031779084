import React from 'react';
import { VerticalView } from 'modules/common/vendors/Wrapper';
import { AppColors } from 'helpers';
import { PromotionPreview } from 'modules/user-interface/home/components/parts/Promotion/PromotionView';
import { ContentLoading } from 'modules/common/vendors/Loading';
import { IArticle } from 'models/Article';
import { HomeNewsPreview } from './parts/HomeNewsPreview';
import { HomeServicesPreview } from './parts/HomeServicesPreview';
import { HomeContactUs } from './parts/HomeContactUs';
import { ContactComponent } from 'modules/user-interface/contact/components';
import { ScheduleEvent } from 'modules/common/components/SchedulePicker';
import { ISendContactInput } from 'models/Contact';
import { Text } from 'modules/common/vendors/Text';
import { useAppSetting } from 'helpers/useAppSetting';

interface Props {
  loading: boolean;
  services: IArticle[];
  news: IArticle[];
  lang: string;
  success: boolean;
  isServicePage: boolean;
  loadingContact?: boolean;
  sendLoading?: boolean;
  scheduleList: ScheduleEvent[];
  onSend: (value: ISendContactInput) => void;
}

export const HomePageComponent = (props: Props) => {
  const { loading, lang } = props;
  const { appLayout } = useAppSetting();
  const isMobile = appLayout.type === 'mobile' || appLayout.type === 'tablet';

  return (
    <VerticalView width={'100vw'} alignContent={'topCenter'} minHeight={'70vh'} overflow={'hidden'}>
      {!loading && (
        <VerticalView fullWidth alignContent={'topCenter'}>
          <PromotionPreview lang={lang} services={props.services} />
          <HomeNewsPreview news={props.news} />
          <HomeServicesPreview isLoading={props.loading} serviceList={props.services} />
          {/* <HomeContactUs /> */}
          <Text fontSize={isMobile ? '30px' : '45px'} fontWeight="bold" color={AppColors.BLUE} marginTop="20px">
            {'CONTACT US'}
          </Text>
          <ContactComponent
            success={props.success}
            isServicePage={props.isServicePage}
            scheduleList={props.scheduleList}
            onSend={props.onSend}
          />
        </VerticalView>
      )}
      {loading && (
        <VerticalView fullWidth minHeight={'70vh'} alignContent={'center'}>
          <ContentLoading loading height={'100px'} color={AppColors.RED_PRIMARY} />
        </VerticalView>
      )}
    </VerticalView>
  );
};
