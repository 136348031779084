import React from 'react';
import styled from 'styled-components';
import { HorizontalView } from 'modules/common/vendors/Wrapper';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { PaginationBar } from 'modules/common/components/Pagination/PaginationBar';
import { VerticalView } from 'modules/common/vendors/CommonWrappers';
import { CmsSearchArticleBar } from './parts/CmsSearchArticleBar';
import { useAppSetting } from 'helpers/useAppSetting';
import { IArticle } from 'models/Article';
import { TableWrapper } from 'modules/common/vendors/CommonParts';
import { MobileTableView } from './parts/MobileTableView';
import { WebTableView } from './parts/WebTableView';
import { PageTitle } from 'modules/common/components/PageTitle';

export const ContentWrapper = styled.div``;

interface CourseListProps {
  loading: boolean;
  isServicePage: boolean;
  createLoading: boolean;
  deleteLoading: boolean;
  articles: IArticle[];
  currentPage: number;
  totalPages: number;
  totalItems: number;
  onCreate: () => void;
  onDetails: (id: string) => void;
  onDelete: (id: string) => void;
  onSearch: (keyword: string) => void;
  onChangeLimit: (limit: number) => void;
  onChangePage: (page: number) => void;
  onSearchBy: (tag: string[], type: string) => void;
}

export const CMSArticleSearchComponent = (props: CourseListProps) => {
  const { appLayout } = useAppSetting();

  return (
    <BaseComponent>
      <>
        <VerticalView fullWidth maxWidth={'100%'} alignContent={'centerLeft'}>
          <PageTitle title={props.isServicePage ? 'searchArticles.headerService' : 'searchArticles.header'} />
          <CmsSearchArticleBar
            isServicePage={props.isServicePage}
            loading={props.createLoading}
            totalItems={props.totalItems}
            onSelectTags={props.onSearchBy}
            onSearch={(keyword) => props.onSearch(keyword)}
            onCreate={() => props.onCreate()}
            onChangeLimit={props.onChangeLimit}
          />
          <TableWrapper>
            {appLayout.isMobile && (
              <MobileTableView
                loading={props.loading}
                articles={props.articles}
                onDelete={props.onDelete}
                onDetails={props.onDetails}
                onCreate={() => {
                  props.onCreate();
                }}
              />
            )}
            {!appLayout.isMobile && (
              <WebTableView
                loading={props.loading}
                articles={props.articles}
                onDelete={props.onDelete}
                onDetails={props.onDetails}
                onCreate={() => {
                  props.onCreate();
                }}
              />
            )}
          </TableWrapper>
        </VerticalView>

        {props.totalPages && !props.loading && (
          <HorizontalView fullWidth alignContent={'centerRight'}>
            <PaginationBar
              totalPages={props.totalPages}
              currentPage={props.currentPage}
              onChangePage={props.onChangePage}
            />
          </HorizontalView>
        )}
      </>
    </BaseComponent>
  );
};
