import React from 'react';
import { Div, VerticalView } from 'modules/common/vendors/Wrapper';
import { BaseComponent } from 'modules/common/components/BaseComponent';
import { Input } from 'modules/common/vendors/Input';
import { TextAreaInput } from 'modules/common/vendors/TextAreaInput';
import { ICompany } from 'models/Company';
import { Formik, FormikProps } from 'formik';
import { PageTitle } from 'modules/common/components/PageTitle';
import { PrimaryButton } from 'modules/common/components/Button/PrimaryButton';

interface CompanyProfileProps {
  loading: boolean;
  saving?: boolean;
  company?: ICompany;
  onUpdate: (values: ICompany) => void;
}

export const CMSConpanyProfileComponent = (props: CompanyProfileProps) => {
  const ref = React.useRef<FormikProps<ICompany>>(null);

  React.useEffect(() => {
    if (props.company) {
      if (ref && ref.current) {
        ref.current.setValues(JSON.parse(JSON.stringify(props.company)));
      }
    }
  }, [props.company]);

  const onSave = () => {
    if (ref && ref.current) {
      ref.current.submitForm();
    }
  };

  return (
    <BaseComponent>
      <>
        {props.company && (
          <Div fullWidth>
            <PageTitle title={'company.header'} />
            <Formik
              innerRef={ref}
              initialValues={props.company as ICompany}
              onSubmit={(values: ICompany) => {
                props.onUpdate(values);
              }}
            >
              {({ values, handleChange }) => {
                return (
                  <VerticalView fullWidthHeight>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        name={'name'}
                        label={'company.companyName'}
                        fontSize={'15px'}
                        placeholder={'company.companyName'}
                        value={values?.name}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        name={'foundation'}
                        label={'company.foundation'}
                        fontSize={'15px'}
                        placeholder={'company.foundation'}
                        value={values?.foundation}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        name={'representativeName'}
                        label={'company.representatorName'}
                        fontSize={'15px'}
                        placeholder={'company.representatorName'}
                        value={values?.representativeName}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        name={'tax'}
                        label={'company.tax'}
                        fontSize={'15px'}
                        placeholder={'company.tax'}
                        value={values?.tax}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        name={'postalCode'}
                        label={'company.postalCode'}
                        fontSize={'15px'}
                        placeholder={'company.postalCode'}
                        value={values?.postalCode}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        name={'stateOrCityName'}
                        label={'company.city'}
                        fontSize={'15px'}
                        placeholder={'company.city'}
                        value={values?.stateOrCityName}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        name={'streetAddress'}
                        label={'company.streetAddress'}
                        fontSize={'15px'}
                        placeholder={'company.streetAddress'}
                        value={values?.streetAddress}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        name={'buildingNameAndRoomName'}
                        label={'company.building'}
                        fontSize={'15px'}
                        placeholder={'company.building'}
                        value={values?.buildingNameAndRoomName}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        name={'telephone'}
                        label={'company.mail'}
                        fontSize={'15px'}
                        placeholder={'company.mail'}
                        value={values?.telephone}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <Input
                        name={'locationMapUrl'}
                        label={'company.mapUrl'}
                        fontSize={'15px'}
                        placeholder={'company.mapUrl'}
                        value={values?.locationMapUrl}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <TextAreaInput
                        name={'businessContent'}
                        label={'company.bussinessContent'}
                        size={'15px'}
                        placeholder={'company.bussinessContent'}
                        value={values?.businessContent}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'15px'}>
                      <TextAreaInput
                        name={'description'}
                        label={'company.description'}
                        size={'15px'}
                        placeholder={'company.description'}
                        value={values?.description}
                        background={'transparent'}
                        onChange={handleChange}
                      />
                    </Div>
                    <Div fullWidth marginTop={'20px'} alignContent={'centerRight'}>
                      <PrimaryButton loading={props.saving} icon={'save'} title={'company.save'} onClick={onSave} />
                    </Div>
                  </VerticalView>
                );
              }}
            </Formik>
          </Div>
        )}
      </>
    </BaseComponent>
  );
};
